// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_update_container{
    height:100vh;
    width:100%;
}
.profile_update_container p{
    padding: 0.2em;
}

.profile_container.btn.btn-primary.form_input_element.view-edit-btn{
    background: #486b7d;
    padding: 6px 18px;
    width: auto;
    border: solid 1px #486b7d;
    font-weight: 500;
    color: #fff;
    font-size: 14px;
}
.profile_container.btn.btn-primary.form_input_element.view-edit-btn:hover{
    background: transparent;
    color: #22526e;
}`, "",{"version":3,"sources":["webpack://./src/components/ProfileUpdate/update.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;AACd;AACA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB;AACA;IACI,uBAAuB;IACvB,cAAc;AAClB","sourcesContent":[".profile_update_container{\n    height:100vh;\n    width:100%;\n}\n.profile_update_container p{\n    padding: 0.2em;\n}\n\n.profile_container.btn.btn-primary.form_input_element.view-edit-btn{\n    background: #486b7d;\n    padding: 6px 18px;\n    width: auto;\n    border: solid 1px #486b7d;\n    font-weight: 500;\n    color: #fff;\n    font-size: 14px;\n}\n.profile_container.btn.btn-primary.form_input_element.view-edit-btn:hover{\n    background: transparent;\n    color: #22526e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
