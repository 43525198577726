import {useEffect, useState,} from 'react';
import { useParams} from 'react-router-dom';
import AddExamForm from '../AddExamForm/AddExamForm';
import axios from 'axios';
import SpinnerLoader from '../../helpers/spinner-loader';

export default function UpdateExam(){
    const [examId, setExamId] = useState('');
    const [updateExamFormLoader, setUpdateExamFormLoader] = useState(true);
    const [selectedExam, setSelectedExam] = useState({});
    const params = useParams();

    useEffect(() => {
        getExamId();
    }, []);

    useEffect(() => {
        if (examId.length > 0) {
            getExamDetails(examId);
        }
    }, [examId]);

    useEffect(() => {
        if(Object.keys(selectedExam).length > 0) {
            setUpdateExamFormLoader(false);
        }
    }, [selectedExam]);

    const getExamId = () => {
        let examId = params.id;
        if (examId.length > 0) {
            setExamId(examId);
        }
    }

    const getExamDetails = async(examId) => {
        try{
            let exam = await axios.get(`${process.env.REACT_APP_API_URL}/exam/read/${examId}`);
            exam = exam.data;
            let selectedExamPattern = await axios.get(`${process.env.REACT_APP_API_URL}/examPattern/read/${exam.selectedExamPattern}`);
            exam.selectedExamPattern = {...selectedExamPattern.data};
            setSelectedExam({...exam});
        }
        catch(e) {
            console.log(e)
        }
    }

    return (
        <div className=' w-100 d-flex flex-column justify-content-start align-items-stretch'>
            {!updateExamFormLoader && <AddExamForm selectedExam={selectedExam}/>}
            {/* {updateExamFormLoader && <div className='h-100'>Loading...</div>} */}
            {updateExamFormLoader && <div className="ht80vh w-100 d-flex justify-content-center align-items-center">
                        <SpinnerLoader />
                    </div>
            }
        </div>
    )
}