import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';

// Custom Tooltip for better clarity
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { section_name, total_questions, correct_answers_avg, attempted_student_cnt } = payload[0].payload;
    return (
      <div className="custom-tooltip">
        <span className="label">{`Section: ${section_name}`}</span><br />
        <span className="intro">{`Total Questions: ${total_questions}`}</span><br />
        <span className="intro">{`Average Marks: ${correct_answers_avg}`}</span>
      </div>
    );
  }
  return null;
};

const SectionWisePerformanceChart = ({ sectionWisePerformanceData }) => {
  return (
    <div>
      <h3>Section Wise Performance</h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={sectionWisePerformanceData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="section_name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="total_questions" fill="#8884d8" name="Total Questions" />
          <Bar dataKey="correct_answers_avg" fill="#82ca9d" name="Average Marks" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SectionWisePerformanceChart;
