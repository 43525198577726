import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Context from '../../user_details';

const complexityLabels = {
  1: 'Easy',
  2: 'Medium',
  3: 'Hard',
};

const questionTypeLabels = {
  1: 'Multiple Choice',
  2: 'True / False',
  3: 'Check Boxes',
  4: 'Comprehensive',
  5: 'File Upload',
};

// Define colors for each question type
const questionTypeColors = {
  1: '#8884d8',  // Multiple Choice
  2: '#82ca9d',  // True / False
  3: '#ff7300',  // Check Boxes
  4: '#d8a1b2',  // Comprehensive
  5: '#a4de6c',  // File Upload
};

const QuestionAnalysis = () => {
  const { user } = useContext(Context);
  const [data, setData] = useState([]);
  const [selectedComplexity, setSelectedComplexity] = useState(null);
  const [calculatedHeight, setCalculatedHeight] = useState(320);

  const calculateHeightWidths = () => {
    const pageContainer = document.querySelector('.page-container');
    const divElement = document.querySelector('.chart-container');
    const divWidth = divElement.clientWidth;
    const scatterHeight = divWidth/2;
    const pageContHeight = (pageContainer.clientHeight) - 215;
    const availableHeight = pageContHeight - scatterHeight;

    let newHeight = availableHeight > 0 ? availableHeight : 320;
    if(newHeight > divWidth) {
      newHeight = divWidth;
    }
    setCalculatedHeight(newHeight);
  };

  useEffect(() => {
    calculateHeightWidths();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const questionResponse = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/questionAnalysis/${user.firm_id}`);
        
        // Format the data for grouped bars
        const formattedData = questionResponse.data.questionsByTypeAndDifficulty.map(item => ({
          complexity: item.complexity,
          ...item.questionTypes.reduce((acc, qt) => ({
            ...acc,
            [`questionType_${qt.question_type}`]: qt.count
          }), {})
        }));
        
        setData(formattedData);
      } catch (error) {
        console.error('Error fetching question analysis:', error);
      }
    };

    fetchData();
  }, [user.firm_id]);

  const handleBarClick = (data) => {
    if (!data || !data.activePayload) return;
    const { complexity } = data.activePayload[0].payload;
    setSelectedComplexity(complexity);
  };

  const formatComplexity = (value) => complexityLabels[value] || value;

  const formatQuestionType = (value) => questionTypeLabels[value] || value;

  return (
    <div class="chart-sub-container">
      <h3>Question Bank Analysis</h3>
      <ResponsiveContainer width="100%" height={calculatedHeight}>
        <BarChart data={data} onClick={(event) => handleBarClick(event)}>
          <XAxis dataKey="complexity" tickFormatter={formatComplexity} />
          <YAxis />
          <Tooltip 
            formatter={(value) => `${value}`}
            labelFormatter={(label) => `Complexity: ${formatComplexity(label)}`} 
          />
          <Legend />
          {Object.keys(questionTypeLabels).map(questionType => (
            <Bar
              key={`questionType-${questionType}`}
              dataKey={`questionType_${questionType}`}
              fill={questionTypeColors[questionType]}
              name={formatQuestionType(questionType)}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default QuestionAnalysis;
