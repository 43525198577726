import {useEffect, useState} from "react";
import {generateDefaultPermissionsDetails} from "../../helpers/permissionsHelper";
import SpinnerLoader from '../../helpers/spinner-loader';
import './allPermissionsTable.scss'

const PermissionsTable = ({all_permissions, selectedPermissions, setSelectedPermissions}) => {

  const [allPermissions, setAllPermissions] = useState(all_permissions);
  const [selectedPermissionsInTable, setSelectedPermissionsInTable] = useState(selectedPermissions);
  const [isAllPermissionsAvailable, setAllPermissionsAvailability] = useState(false);
  const [entities, setEntities] = useState({});
  useEffect(() => {
    console.table({all_permissions, selectedPermissionsInTable, setSelectedPermissions})
    const getAllPermissionsDetails = async() => {
        console.log("calling all permissionbs", selectedPermissionsInTable)
        const all_permissions = await generateDefaultPermissionsDetails();
        setAllPermissions(all_permissions);
    }
    console.log(all_permissions, selectedPermissionsInTable, "all123")
    if (allPermissions == null) {
        setAllPermissionsAvailability(false);
        getAllPermissionsDetails();
    } else {
        setAllPermissionsAvailability(true);
        organizePermissions(allPermissions);
    }
  }, [allPermissions]);

  useEffect(() => {
    console.log("selectedPermissionsInTable are", selectedPermissionsInTable)
    setSelectedPermissions(selectedPermissionsInTable);
  }, [selectedPermissionsInTable]);

  const organizePermissions = async(permissions) => {
    if (!permissions) {
        return;
    }
    const entities = {};
    permissions.forEach((permission) => {
        const [action, entity] = permission.permission_type.match(/(view|create|edit|delete)([A-Z]\w+)/i).slice(1);
        
        if (!entities[entity]) {
            entities[entity] = { view: '', create: '', edit: '', delete: '' };
        }
        
        entities[entity][action] = permission._id;
    });
    
    setEntities(entities);
  };

  const updatePermissions = (event) => {
    console.log("calledd upddation")
    const permission_id = event.target.value;
    var selected_permissions = selectedPermissionsInTable;
    if (event.target.checked) {
        selected_permissions.push(permission_id);
    } else {
        selected_permissions.splice(selected_permissions.indexOf(permission_id),1);
    }
    console.log(selected_permissions)
    setSelectedPermissionsInTable([...selected_permissions]);
  }

  const isPermissionChecked = (permission_id) => {
    const isSelected = selectedPermissionsInTable.some(selectedPermission => permission_id == selectedPermission);
    return isSelected;
  }

 if (isAllPermissionsAvailable) {
    return (
        <table className="w-100 allPermissionsTable">
          <thead className=" ">
            <tr >
              <th>Permission Entity</th>
              <th>View</th>
              <th>Create</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(entities).map((entity) => (
              <tr key={entity}>
                <td>{entity}</td>
                <td>
                  {entities[entity].view ? (
                    <input
                        type="checkbox"
                        value={entities[entity].view}
                        onChange={updatePermissions}
                        checked={isPermissionChecked(entities[entity].view)}
                    />
                  ): "N/A"}
                </td>
                <td>
                  {entities[entity].create ? (
                    <input
                      type="checkbox"
                      value={entities[entity].create}
                      onChange={updatePermissions}
                      checked={isPermissionChecked(entities[entity].create)}
                    />
                  ): "N/A"}
                </td>
                <td>
                  {entities[entity].edit ? (
                    <input
                      type="checkbox"
                      value={entities[entity].edit}
                      onChange={updatePermissions}
                      checked={isPermissionChecked(entities[entity].edit)}
                    />
                  ): "N/A"}
                </td>
                <td>
                  {entities[entity].delete ? (
                    <input
                      type="checkbox"
                      value={entities[entity].delete}
                      onChange={updatePermissions}
                      checked={isPermissionChecked(entities[entity].delete)}
                    />
                  ): "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
 } else {
    return (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <SpinnerLoader />
        </div>
    )
 }
};

export default PermissionsTable;
