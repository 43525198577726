import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import QuestionForm from '../QuestionForm/questionForm';
import './questions.css';
import Context from '../../user_details';
import Pagination from '../../helpers/pagination';
import QuestionSearchForm from './searchForm';

export default function Questions() {
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [selectedQuestion,setSelectedQuestion] = useState();
  const context = useContext(Context);
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [searchParams, setSearchParams] = useState({
      question: '',
      complexity: '',
      marks: '',
      question_type:'',
      client_id: context.user.user_type === 'superAdmin'? '' : context.user.firm_id,
      course_id: '',
      subject_id: '',
      topic_id: '',
      ai_generated: ''
  });

  const toggleSearchForm = () => {
    setShowSearchForm(!showSearchForm);
  };

  useEffect(() => {
    console.log("these are the searchParams", searchParams);
  }, [searchParams])

  useEffect(() => {
    fetchQuestions();
  }, [page])

  const fetchQuestions = async (selectivePage) => {
    try {
      console.log("these are the search params before calls", searchParams)
      if (!page) return;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/question/list?page=${selectivePage || page}&client_id=${context.user.firm_id}&searchParams=${JSON.stringify(searchParams)}`);
      const { questions, totalPages } = response.data;
      setQuestions(questions);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  }

  // useEffect(() => {
  //   fetchQuestions();
  // }, [fetchQuestions]);

  const handleQuestionAdded = () => {
    setShowForm(false);
    fetchQuestions();
  };

  // const getQuestionsListBySearch = async() => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_API_URL}/question/list?page=${page}&client_id=${context.user.firm_id}`);
  //     const { questions, totalPages } = response.data;
  //     setQuestions(questions);
  //     setTotalPages(totalPages);
  //   }
  //   catch(e) {
  //     console.error("Error in fetching questions list:",e)
  //   }
  // }

  const getComplexityLabel = (complexity) => {
    switch (complexity) {
      case 1:
        return 'Easy';
      case 2:
        return 'Medium';
      case 3:
        return 'Difficult';
      default:
        return '';
    }
  };

  const getQuestionTypeLabel = (questionType) => {
    switch (questionType) {
      case 1:
        return 'Multiple Choice';
      case 2:
        return 'True / False';
      case 3:
        return 'Check Boxes';
      case 4:
        return 'Comprehensive';
      case 5:
        return 'File Upload';
      default:
        return '';
    }
  };

  const cancelBtnHandler = () => {
    setShowForm(false);
  };

  const addBtnHandler = () => {
    if ( context.user.permissionTypes.includes('createQuestions') ) {
      setShowForm(true);
      setSelectedQuestion();
    }
    
  };

  const editBtnHandler = (question) => {
    if (context.user.permissionTypes.includes("editQuestions")) {
      setShowForm(true);
      setSelectedQuestion(question);
    }
     
  };
 
const deleteHandler = async (question) => {
  if(window.confirm('Are you sure to delete this Question?') && context.user.permissionTypes.includes('deleteQuestions'))  {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/question/delete/${question._id}`
      ).then(d => {
          fetchQuestions();
      });
    } catch (error) {
      console.error("Error Update Deleted:", error);
    }
  }
};

const handleSearchInputChange = (e) => {
  const { name, value } = e.target;
  setSearchParams(prevState => ({ ...prevState, [name]: value }));
};

const resetSearch = () => {
  setSearchParams({
      question: '',
      complexity: '',
      question_type:'',
      client_id: context.user.user_type === 'superAdmin'? '' : context.user.firm_id,
      course_id: '',
      subject_id: '',
      topic_id: '',
      ai_generated: ''
  });
  // setTimeout(() => {
  //   fetchQuestions(1);
  // }, 2000)// Fetch the original questions list after resetting the search params
  window.location.href="/questions"
};


  return (
    <div class="questions-container">
      {/* <h2>Questions</h2>
      <button onClick={() => setShowForm(!showForm)}>Add Question</button>
      {showForm && <QuestionForm onQuestionAdded={handleQuestionAdded} />} */}

      {/* =============================================== */}
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-8">
            <div class="page-header-container">
              <h2 class="page-main-header">Questions</h2>
              {context.user.permissionTypes.includes('createQuestions') && 
                <button onClick={addBtnHandler} class="add-form-btn">
                <span class="icon-placeholder">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-question" viewBox="0 0 16 16">
                    <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745"/>
                    <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
                    <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0"/>
                  </svg>
                </span>
                Add Questions
              </button>
              }
            </div>
          </div>
          <div class="col-sm-4 search-icon-div"> 
            <svg onClick={toggleSearchForm} xmlns="http://www.w3.org/2000/svg" width="25" height="40" fill="currentColor" className="bi bi-search float-end" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
          </div>
        </div>
        <div>
            {/* Search form */}
            {showSearchForm && searchParams &&  (
                <QuestionSearchForm searchParams={searchParams} setPage={setPage} resetSearch={resetSearch} handleSearchInputChange={handleSearchInputChange} fetchQuestions={fetchQuestions}/>
            )}
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p class="mb-0">&nbsp;</p>
            {showForm && <QuestionForm onQuestionAdded={handleQuestionAdded} cancelBtnHandler={cancelBtnHandler} SelectedQuestion={selectedQuestion} />}
          </div>
        </div>
      </div>
      {!showForm && (

        <>
          <div className="row row-cols-1 row-cols-md-2 g-4">
            {questions.filter(d => d.status === 1).map((question, index) => (
              <div key={index} className="page-added-item col">
                <div class="added-items-list card h-100">
                  <div class="card-body">

                    <div class="card-text questions-info-container">
                      <div class="questions-info-header align-self-stretch">
                        { (question.ai_generated) && (<>
                          <div className='ai-generated' title='AI Generated Question'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-magic" viewBox="0 0 16 16">
                              <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707zM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1zM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707zM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0z"/>
                            </svg>
                          </div>
                        </>)}
                        Question:
                      </div>
                      <div class="questions-info-result">
                        
                        <div dangerouslySetInnerHTML={{ __html: question.question }} />
                      </div>
                    </div>

                    <div class="card-text questions-info-container">
                      <div class="questions-info-header align-self-stretch">Answers:</div>
                      <div class="questions-info-result">
                        <ul>
                          {question.answers.map((answer, answerIndex) => (
                            <li key={answerIndex}><span style={{ backgroundColor: answer.is_correct ? '#b9e5b9' : 'transparent' }}>{answer.answer}</span></li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  {  context.user.user_type === "superAdmin" &&
                    <div class="card-text questions-info-container">
                      <div class="questions-info-header align-self-stretch">Client:</div>
                      <div class="questions-info-result">
                          {question.client_id && <div>{question.client_name}</div>}
                      </div>
                    </div>
                  }
                    <div class="card-text questions-info-container">
                      <div class="questions-info-header align-self-stretch">Course:</div>
                      <div class="questions-info-result">
                          {question.course_id && <div>{question.course_id.course_name}</div>}
                      </div>
                    </div>

                    <div class="card-text questions-info-container">
                      <div class="questions-info-header align-self-stretch">Subject:</div>
                      <div class="questions-info-result">
                          {question.subject_id && <div>{question.subject_id.subject_name}</div>}
                      </div>
                    </div>

                    <div class="card-text questions-info-container">
                      <div class="questions-info-header align-self-stretch">Topic:</div>
                      <div class="questions-info-result">
                          {question.topic_id && <div>{question.topic_id.topic_name}</div>}
                      </div>
                    </div>

                    

                    <div class="card-text questions-info-container">
                      <div class="questions-info-header align-self-stretch">Complexity:</div>
                      <div class="questions-info-result min-width-30">
                      {getComplexityLabel(question.complexity)}
                      </div>
                      <div class="questions-info-header align-self-stretch min-width-22">Marks:</div>
                      <div class="questions-info-result">
                        {question.marks}
                      </div>
                    </div>

                    <div class="card-text questions-info-container">
                      <div class="questions-info-header align-self-stretch">Question Type:</div>
                      <div class="questions-info-result min-width-30">
                        {getQuestionTypeLabel(question.question_type)}
                      </div>
                      <div class="questions-info-header align-self-stretch min-width-22">Status:</div>
                      <div class="questions-info-result">
                        {question.status == 1 ? 'Active' : question.status == 2 ?'Deleted' : 'InActive'}
                      </div>
                    </div>

                    {/*<div class="card-text questions-info-container">
                      <div class="questions-info-header align-self-stretch">Is Verified:</div>
                      <div class="questions-info-result">
                        {question.is_verified ? 'Yes' : 'No'}
                      </div>
                    </div>*/}
                    

                  </div>

                  
                  {context.user.permissionTypes.includes("editQuestions") && 
                    <div class="card-footer text-center">
                      <button class="delete_item" id="editQuestion" onClick={() => editBtnHandler(question)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                        </svg> &nbsp; Edit Question
                      </button>
                    </div>
                  }
                  { context.user.permissionTypes.includes('deleteQuestions') && 
                    <div class="card-footer text-center">
                    <button class="delete_item" id="deleteQuestion" onClick={() => {deleteHandler(question)}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                    </svg> Delete Question</button>
                  </div>
                  }
                

                </div>
              </div>
            ))}
          </div>
          
          <Pagination totalPages={totalPages} page={page} setPage={setPage} />
        </>
      )}

      
    </div>
  );
}
