import React, { useState, useEffect, useContext } from 'react';
import { Scatter } from 'react-chartjs-2';
import axios from 'axios';
import Context from '../../user_details';

const ToppersScatterPlot = () => {
    const { user } = useContext(Context);
    const [numberOfExams, setNumberOfExams] = useState(20);
    const [examData, setExamData] = useState([]);

    useEffect(() => {
        fetchData(numberOfExams);
    }, [numberOfExams]);

    const fetchData = async (numExams) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/getToppersOfLastExams/${user.firm_id}?numberOfExams=${numExams}`);
            setExamData(response.data);
        } catch (error) {
            console.error('Error fetching exam data:', error);
        }
    };

    const handleDropdownChange = (e) => {
        setNumberOfExams(parseInt(e.target.value, 10));
    };

    //rgba(54, 162, 235, ${0.6 - (index * 0.05)})
    const chartData = {
        datasets: examData.map((item, index) => ({
            label: item.examName,
            data: item.name.map((name, i) => ({ x: index, y: item.topScore, r: 5 + (i * 2) })), // Adjust radius for multiple toppers
            backgroundColor: `rgba(155, ${255 - index * 20}, 192, 0.9)`,
            borderColor: `rgba(${75 + index * 30}, ${192 - index * 20}, 192, 1)`,
            borderWidth: 1,
            pointStyle: 'circle',
            pointRadius: 8,
            pointHoverRadius: 10,
        })),
    };

    const options = {
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    display: true,
                    text: 'Exam Index',
                    color: '#555',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                },
                grid: {
                    color: '#e5e5e5',
                    lineWidth: 1,
                },
                ticks: {
                    display: false, // Hide x-axis labels
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Top Score',
                    color: '#555',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                },
                grid: {
                    color: '#e5e5e5',
                    lineWidth: 1,
                },
            },
        },
        plugins: {
            tooltip: {
                backgroundColor: '#ffffff',
                titleColor: '#333',
                bodyColor: '#333',
                borderColor: '#ddd',
                borderWidth: 1,
                callbacks: {
                    label: (context) => {
                        const examIndex = context.dataIndex;
                        const examName = examData[examIndex]?.examName || 'N/A';
                        const topperNames = examData[examIndex]?.name.join(', ') || '';
                        return `Exam: ${examName}, Score: ${context.raw.y}, Toppers: ${topperNames}`;
                    },
                },
            },
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                borderWidth: 2,
                hoverBackgroundColor: 'rgba(75,192,192,0.8)',
                hoverBorderWidth: 3,
            },
        },
    };

    return (
        <div class="chart-sub-container">
            <h3>Exam Vs Toppers</h3>
            <div className='form-elements-container row float-end'>
                <div className='form-info-entry-area'>
                    <select id="numberOfExams" value={numberOfExams} onChange={handleDropdownChange} className='form-select'>
                        <option value={5}>Last 5 Exams</option>
                        <option value={10}>Last 10 Exams</option>
                        <option value={20}>Last 20 Exams</option>
                        <option value={40}>Last 40 Exams</option>
                        <option value={100}>Last 100 Exams</option>
                    </select>
                </div>
            </div>
            <div className="chart-sub-container">
                <Scatter data={chartData} options={options} />
            </div>
        </div>
    );
};

export default ToppersScatterPlot;
