import "./users.css";
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import edit_icon from '../../assets/edit_icon.svg';
import delete_icon from '../../assets/delete_icon.svg';
import cancel_icon from '../../assets/close_icon.svg';
import Context from '../../user_details';
import SpinnerLoader from '../../helpers/spinner-loader';
import no_data_image from '../../assets/no-data.jpg';

export default function User(props) {
    const [users, setUsers] = useState([]);
    const [users_list, setUsersList] = useState(undefined);
    const [deleted_users, setDeletedUsers] = useState(new Set());
    const userDetails = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [allUsersList, setAllUsersList] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const navigate = useNavigate();
    console.log(userDetails, "this is userDetials");

    useEffect(() => {
        get_users_list();
    }, []);

    useEffect(() => {
        setUsersList([...allUsersList]);
    }, [allUsersList])

    // useEffect(() => {
    //  if (users_list) {
    //     setLoading(false);
    //     setIsSearching(false);
    //  }
    // }, [users_list])

    useEffect(() => {
        console.log(props, "this is initial deleted users", deleted_users)
        return console.log("this is initial deleted users", deleted_users)
    }, [deleted_users])

    const get_users_list = async () => {
        try {
            setLoading(true);
        const usersList = await fetch(process.env.REACT_APP_API_URL + "/ums/admin/list/?type=" + userDetails.user.user_type + "&firm_id=" + userDetails.user.firm_id)
            .then(async res => {
                let result = await res.json();
                setAllUsersList(result.resultsPromise);
            })
            .catch(err => {
                console.log(err, "setting loading false");
                setLoading(false);
            })
        console.log("Response is ==========", usersList)
        } catch(e) {
            setAllUsersList([]);
            alert("something went wrong in fetching users list")
        } finally {
            setLoading(false);
        }

    }

    const CreateProfile = () => {
        if (!userDetails.user.permissionTypes.includes("createUsers")) return;
        navigate("create-profile")
    }
    const UpdateProfile = (userId, firmId) => {
        if (!userDetails.user.permissionTypes.includes("editUsers")) return;
        navigate(`update-profile?userId=${userId}&firmId=${firmId}`)
    }
    const Search_user = async(event) => {
        let key = event.target.value.toLowerCase();
        setLoading(true);
        let filteredList = await Promise.all(
            allUsersList.filter(element => { 
                const {first_name, last_name} = element.user;
                const username = `${first_name} ${last_name}`;
                return username.toLowerCase().includes(key);
            })
        );

        setLoading(() => {
            setUsersList([...filteredList]);
            return false;
        })
    }

    const deleteProfile = async (event) => {
        if (!userDetails.user.permissionTypes.includes("deleteUsers")) return;
        let del_users = deleted_users;
        var selected_user = event.target;
        var delete_user_id = event.target.parentNode.getAttribute("value");
        if (selected_user.tagName !== 'IMG') {
            selected_user = selected_user.childNodes[0];
            delete_user_id = event.target.getAttribute("value");
        }
        if (selected_user.className === "delete_icon") {
            selected_user.src = cancel_icon;
            selected_user.className = "cancel_icon";
            del_users.add(delete_user_id);

        }
        else {
            selected_user.src = delete_icon;
            selected_user.className = "delete_icon";
            if (del_users.has(delete_user_id)) del_users.delete(delete_user_id)
        }
        setDeletedUsers(del_users);
        navigate("/users");
        console.log("this is deleted users,", deleted_users)
    }

    const delete_users = async () => {
        for (let user of deleted_users) {
            var result = await fetch(process.env.REACT_APP_API_URL + "/ums/admin/delete/" + user, {
                method: "DELETE"
            })
                .then(async res => {
                    return await res.json()
                })
            console.log(result);

            setDeletedUsers(new Set());
            await get_users_list();
            navigate("/users")
        }
    }
    if (true) {
        return (
            userDetails && userDetails != null && userDetails != undefined ?
                <div className="user_page p-3">
                     {/* ========= Header - STARTS here ========== */}
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="page-header-container">
                                    <h2 class="page-main-header">Users</h2>
                                    {deleted_users.size > 0 &&  userDetails.user.permissionTypes.includes("deleteUsers") && 
                                        <div onClick={delete_users} className="btn btn-danger">Delete</div>
                                    }
                                    {
                                        userDetails.user.permissionTypes.includes("createUsers") && 
                                        <button className="btn btn-primary add-form-btn" onClick={CreateProfile}>
                                        <span class="icon-placeholder">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                                <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"/>
                                            </svg>
                                        </span>
                                            Add User
                                        </button>
                                    }
                                    
                                </div>
                            </div>
                            <div class="col-sm-4"> 
                                <input className="px-3 top-right-search-element rounded" type="search" placeholder="search user by name" onChange={Search_user}/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12"> <p class="mb-0">&nbsp;</p> </div>
                        </div>
                    </div>

                    {/* ========= Header and Search container - Ends here ========== */}
                    <div className="user_cards_container ">
                        <div className="">
                            <div className="user_detail_card_headings  d-flex">
                                <div className={" " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Name</div>
                                {userDetails?.user?.user_type === 'superAdmin' ? <span className={" " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Firm</span> : ""}
                                <div className={"role_item " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Role</div>
                                <div className={" " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Batches</div>
                                <div className={" " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>Email</div>
                                <div className={" " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")} style={{width: '7.5%'}}>Status</div>
                                <div style={{width: '11%'}}> &nbsp; </div>
                            </div>

                            <div className="user_details_table">
                                {
                                    loading?
                                        <div className="h-100 w-100 d-flex justify-content-center bg-white align-items-center">
                                            <div className="text-center text-primary fw-bolder fs-2">
                                                <div className="spinner-border" role="status"></div>
                                            </div>
                                        </div>
                                        : 
                                            isSearching?
                                            // <div>Loading... </div>
                                            <div className="ht60vh w-100 d-flex justify-content-center align-items-center">
                                                <SpinnerLoader />
                                            </div>
                                            :
                                                users_list.length === 0 && users_list ?
                                                <div style={{ height: "90%" }} className="no_user bg-white d-flex flex-column justify-content-center align-items-center">
                                                    {/* <img height="50%" src={require("../../assets/user_not_found.svg").default} alt="user not found" />
                                                    <p className="fw-bold">No Users Found</p> */}
                                                    
                                                    <img className="img-fluid" src={no_data_image} alt='No Data Found' />
                                                </div>
                                                :
                                                <div className="table_elements_container custom-scrollbar-styles" >
                                                    {users_list.length == 0 ? 'No Users Found' : users_list.map((user, index) => {
                                                        return (
                                                            <div key={index} className="user_detail_card d-flex align-items-center">
                                                                <div className={" " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>{user?.user?.first_name} {user?.user?.last_name}</div>
                                                                {userDetails && userDetails?.user?.user_type === 'superAdmin' ? <div className={"fw-bold " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>{user.firmDetails?.firm_name} </div> : ""}
                                                                <div className={"role_item " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>{
                                                                    user?.roles?.map((role, index) => {
                                                                        return <span key={index}>{role.role_name}<br /></span>
                                                                    })
                                                                }

                                                                </div>
                                                                <div className={" " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>
                                                                    {
                                                                        user?.user?.groups_assigned?.map((group, index) => {
                                                                            return <span key={index}>{group.name}<br /></span>
                                                                        })
                                                                    }

                                                                </div>
                                                                <div className={" " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")}>{user?.user?.email}</div>
                                                                <div className={" " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")} style={{width: '7.5%'}}>{user?.user?.status}</div>
                                                                <div className={" d-flex gap-1 justify-content-center align-items-center " + (userDetails?.user?.user_type == 'superAdmin' ? "user_detail_field_superAdmin" : "user_detail_field")} style={{ height: "100%", width: '11%' }}>
                                                                    {
                                                                        userDetails.user.permissionTypes.includes("editUsers") &&
                                                                        <div className=" update_button" onClick={() => { UpdateProfile(user?.user._id, user?.user.firm_id) }}>
                                                                            <img className="edit_icon" src={edit_icon} height="18" />
                                                                        </div>
                                                                    }
                                                                    {  userDetails.user.permissionTypes.includes("deleteUsers") && 
                                                                        <div className=" delete_button" value={user?.user?._id} id={user?.user?._id} onClick={deleteProfile}>
                                                                            <img className="delete_icon" src={delete_icon} height="18" />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                }

                            </div>
                        </div>





                    </div>
                </div> : <div className="h-100 w-100 d-flex justify-content-center bg-white align-items-center">
                    <div className="text-center text-primary fw-bolder fs-2">
                        <div className="spinner-border" role="status"></div>
                    </div>
                </div>
        )
    }
    else {
        <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
            <div className="text-center text-primary fw-bolder fs-2">
                <div className="spinner-border" role="status"></div>
            </div>
        </div>
    }
}
{/* <div className={"fw-bold d-flex gap-1 justify-content-center align-items-center"+ (userDetails.user.user_type == 'superAdmin'?"user_detail_field_superAdmin":"user_detail_field")}style={{height:"100%"}}>
<div className="btn btn-primary update_button" onClick={()=>{ UpdateProfile(user.user._id)}}>
    <img className="edit_icon" src={edit_icon} height="20"/>
</div>
<div className="btn btn-danger" value={user.user._id} id={user.user._id} onClick={deleteProfile}>
    <img className="delete_icon" src={delete_icon} height="20"/>
</div>
</div> */}

//--------------input from here---
{/* <div className={"d-flex align-items-center fw-light text-primary user_search_input_container bg-warning"+ (userDetails.user.user_type == 'superAdmin'?"user_detail_field_superAdmin":"user_detail_field")}>
<img  height="60%" style={{position:"absolute",right:'2px',zIndex:1,}} src={require("../../assets/search_icon.svg").default}/>
<input className=" fw-light text-primary" style={{textAlign:"center",fontSize:'0.7rem',width:'100%',paddingRight:'15px'}} type="" placeholder="Search User" onChange={Search_user}/>
</div> */}