// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppAuthSaveSuccess {
  border: 1px solid rgb(14, 92, 14);
  color: rgb(14, 92, 14);
}
.AppAuthSaveFail {
  border: 1px solid red;
  color: red;
}

.offcanvas {
  height: -moz-fit-content;
  height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/components/SMTPSettings/smtpSettings.scss"],"names":[],"mappings":"AACI;EACI,iCAAA;EACA,sBAAA;AAAR;AAGI;EACI,qBAAA;EACA,UAAA;AADR;;AAKA;EACI,wBAAA;EAAA,mBAAA;AAFJ","sourcesContent":[".AppAuthSave{\n    &Success{\n        border: 1px solid rgb(14, 92, 14);\n        color: rgb(14, 92, 14)\n    }\n\n    &Fail {\n        border: 1px solid red;\n        color: red;\n    }\n}\n\n.offcanvas {\n    height: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
