import React, { useEffect, useState, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import Context from '../../user_details';
import SpinnerLoader from '../../helpers/spinner-loader';

const ExamsOverTimeChart = () => {
  const { user } = useContext(Context);
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('Last One Year');

  const timeRanges = [
    'Last One Week',
    'Last One Month',
    'Last One Year',
    'All Till Date'
  ];

  useEffect(() => {
    const fetchExamData = async () => {
      setLoading(true);
      setError(null);
      try {
        const examsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/overview/${user.firm_id}`, {
          params: { timeRange }
        });
        const exams = examsResponse.data;

        const dates = exams.map(exam => new Date(exam.startDate).toLocaleDateString());
        const examCounts = dates.reduce((acc, date) => {
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        }, {});

        const sortedDates = Object.keys(examCounts).sort((a, b) => new Date(a) - new Date(b));
        const counts = sortedDates.map(date => examCounts[date]);

        setChartData({
          labels: sortedDates,
          datasets: [
            {
              label: 'Number of Exams',
              data: counts,
              backgroundColor: 'rgba(75, 192, 192, 0.5)', // More opacity for a softer look
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 2,
              tension: 0.4,
              pointBackgroundColor: 'rgba(75, 192, 192, 1)',
              pointBorderColor: '#3a4f96',
              pointBorderWidth: 2,
              pointRadius: 6,
              pointHoverRadius: 8,
              fill: 'origin',
              shadowOffsetX: 5, // Simulating 3D effect
              shadowOffsetY: 5,
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.3)',
            }
          ]
        });
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchExamData();
  }, [timeRange]);

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  // if (loading) return <p>Loading...</p>;
  if (loading) return <div className="ht60vh w-100 d-flex justify-content-center align-items-center">
                        <SpinnerLoader />
                      </div>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="chart-sub-container">
      <h3>Exams Over Time</h3>
      <div className='form-elements-container row float-end'>
        <div className='form-info-entry-area'>
            <select id="timeRange" value={timeRange} onChange={handleTimeRangeChange} className='form-select'>
            {timeRanges.map(range => (
                <option key={range} value={range}>{range}</option>
            ))}
            </select>
        </div>
      </div>
      
      <Line 
        data={chartData} 
        options={{
          scales: {
            x: {
              display: false 
            }
          }
        }}
      />
    </div>
  );
};

export default ExamsOverTimeChart;
