// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smtp_settings_container{
    padding: 25px;
}
.smtp_settings_elements form > div > label {
    width: 20%;
}
.smtp_settings_elements form > div > label strong,
.smtp_settings_elements form .level2_elemnts label strong{
    font-weight: 500;
}
.smtp_settings_elements form .level2_elemnts label{
    font-weight: normal;
    display: inline-block;
    width: 20%;
}

.smtp_settings_elements form .level2_elemnts input.input_field{
    width: 75%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.smtp_settings_elements button.add-form-btn{
    margin-left: 20%;
    min-width: 140px;
    font-size: 17px;
}
.smtp_settings_container span.download_smtp_info_file {
    display: block;
}
.smtp_settings_container span.download_smtp_info_file svg{
    color:#3a4f96;
}
.smtp_settings_container span.download_smtp_info_file a{
    margin-left: 5px;
    color: #3a4f96;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
}
.smtp_settings_container .download_smtp_file_container:hover span.download_smtp_info_file svg,
.smtp_settings_container .download_smtp_file_container:hover a{
    color: #00a3e5;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/SMTPSettings/smtpSettings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,UAAU;AACd;AACA;;IAEI,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,cAAc;AAClB;AACA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,gBAAgB;AACpB;AACA;;IAEI,cAAc;IACd,0BAA0B;AAC9B","sourcesContent":[".smtp_settings_container{\n    padding: 25px;\n}\n.smtp_settings_elements form > div > label {\n    width: 20%;\n}\n.smtp_settings_elements form > div > label strong,\n.smtp_settings_elements form .level2_elemnts label strong{\n    font-weight: 500;\n}\n.smtp_settings_elements form .level2_elemnts label{\n    font-weight: normal;\n    display: inline-block;\n    width: 20%;\n}\n\n.smtp_settings_elements form .level2_elemnts input.input_field{\n    width: 75%;\n    padding: 8px;\n    margin-bottom: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n.smtp_settings_elements button.add-form-btn{\n    margin-left: 20%;\n    min-width: 140px;\n    font-size: 17px;\n}\n.smtp_settings_container span.download_smtp_info_file {\n    display: block;\n}\n.smtp_settings_container span.download_smtp_info_file svg{\n    color:#3a4f96;\n}\n.smtp_settings_container span.download_smtp_info_file a{\n    margin-left: 5px;\n    color: #3a4f96;\n    text-decoration: none;\n    font-size: 15px;\n    font-weight: 500;\n}\n.smtp_settings_container .download_smtp_file_container:hover span.download_smtp_info_file svg,\n.smtp_settings_container .download_smtp_file_container:hover a{\n    color: #00a3e5;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
