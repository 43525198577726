import React from 'react';
import clickSound from '../assets/sounds/click-button-131479.mp3'; // Import your sound file

const SoundButton = ({ onClick, children, className = '', ...props }) => {
  const handleClick = (e) => {
    // Play the sound
    new Audio(clickSound).play();

    // Call the passed onClick handler
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button onClick={handleClick} className={`sound-button ${className}`} {...props}>
      {children}
    </button>
  );
};

export default SoundButton;
