import './spinner-loader.css';

export default function SpinnerLoader(props) {
    return (
        <div className={'loader-full-screen d-flex align-items-center ' + props.x}>
            <div className='container-fluid'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-md-4'>
                        <div class="w-100 m-auto">
                            <div class="bg-image"></div>
                            <div className='bg-text'>
                                {/* ========= code here ========= */}
                                <div className="text-center position-relative" >
                                    <div className='spinner-position-container'>
                                        <div className="spinner-grow text-info" role="status"> </div>
                                        <div className="spinner-grow text-info" role="status"> </div>
                                        <div className="spinner-grow text-info" role="status"> </div>
                                    </div>
                                    <div className="spinner-border" role="status" ></div>
                                </div>
                                 {/* ========= code here ========= */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="circles">
                <li></li> <li></li> <li></li>
                <li></li> <li></li> <li></li>
                <li></li> <li></li> <li></li>
                <li></li> <li></li> <li></li>
            </ul>
    </div>
    )
}