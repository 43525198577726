import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import './questionsGenerator.css';
import Context from '../../user_details';
import UploadDocument from './uploadDocument';
import SoundButton from '../../helpers/soundButton';
import { playSound } from '../../helpers/otherSounds';
import ai_generated_questions_img from '../../assets/ai-generated-questions.jpg';

export default function QuestionsGenerator() {
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const context = useContext(Context);
  const user = context.user;
  const Complexities = {'easy':'Easy', 'Easy':'Easy', 'medium':'Medium', 'Medium':'Medium', 'hard':'Hard', 'Hard':'Hard'}; 
  const QuestionTypes = {'multiple_choice':'Multiple Choice', 'true_false': 'True or False', 'true_or_false' : 'True or False'};

  const fetchGeneratedQuestions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/question/generated-questions-list/${user.firm_id}?page=${page}`
      );
      const { questions, totalPages } = response.data;
      setQuestions(questions);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching generated questions:', error);
    }
  };

  useEffect(() => {
    fetchGeneratedQuestions();
  }, [user.firm_id, page]);

  const editQuestion = async () => {};

  const moveToTrash = async (qid) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to move this question to trash?');

      if (!confirmDelete) { return; }

      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/generated-question/delete/${qid}`);
      if (response.status === 200) {
        playSound('SUCCESS');
        alert('Question successfully moved to trash!');
        fetchGeneratedQuestions();
      }
    } catch (error) {
      console.error('Error moving question to trash:', error.response ? error.response.data : error.message);
      alert('Failed to move question to trash. Please try again.');
    }
  };

  const moveToQuestionBank = async (questionId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generated-question/moveToQuestionBank/${questionId}`, { userId:user._id });
      if (response.status === 200) {
        playSound('SUCCESS');
        alert('Question moved to the Question Bank!');
        fetchGeneratedQuestions();
      }
    } catch (error) {
      console.error('Error moving question to question bank:', error);
    }
  };

  const handleQuestionAdded = () => {
    setShowForm(false);
    fetchGeneratedQuestions();
  };

  const cancelBtnHandler = () => {
    setShowForm(false);
  };

  const addBtnHandler = async() => {
    const hasPermission = await user.permissionTypes.includes("createQuestions");
    if ( hasPermission) {
     setShowForm(true);
    }
  };

  return (
    <div className="questions-container">
      
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-8">
            { (questions.length > 0) && (<>
            
              <div class="page-header-container">
                <h2 class="page-main-header">AI Generated Questions</h2>
                {user.permissionTypes.includes("createQuestions") && 
                <button onClick={addBtnHandler} class="add-form-btn">
                  <span class="icon-placeholder">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-question" viewBox="0 0 16 16">
                      <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745"/>
                      <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
                      <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0"/>
                    </svg>
                  </span>
                  Generate Questions
                </button>
                } 
              </div>
            
            </>) }
            
          </div>
          <div class="col-sm-4"> &nbsp; </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p class="mb-0">&nbsp;</p>
            {showForm && <UploadDocument onUploadSuccess={handleQuestionAdded} cancelBtnHandler={cancelBtnHandler}  />}
          </div>
        </div>
      </div>

      {!showForm && (
        <>
          {!questions.length ? (
            <div className='row align-center align-items-center height70Vh'>
              <div className='col-md-6'>
                <p className='mb-0 text-center'>
                  <img className="img-fluid opacity08" src={ai_generated_questions_img} alt='AI Generated Images' />
                  </p>
              </div>
              <div className='col-md-6'>
                <p className='mb-2 text-center fs-3' style={{color:'#3a4f96'}}>
                    Welcome to AI Questions Generator
                </p>
                <p className='mb-2 text-center'>
                  Click on &nbsp;
                  {user.permissionTypes.includes("createQuestions") && 
                  <button onClick={addBtnHandler} class="add-form-btn generate-questions-button">
                    <span class="icon-placeholder">
                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-question" viewBox="0 0 16 16">
                        <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745"/>
                        <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
                        <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0"/>
                      </svg>
                    </span>
                    &nbsp;Generate Questions
                  </button>
                  } 
                  &nbsp; to generate questions.
                </p>
                
              </div>
            </div>
            
          ) : (
            <>
              <div className="row row-cols-1 row-cols-md-2 g-4">
                {questions.map((question, index) => (
                  <div key={index} className="page-added-item col">
                    <div className="added-items-list card h-100">
                      <div className="card-body">

                        <div className="card-text questions-info-container">
                          <div className="questions-info-header align-self-stretch">Question:</div>
                          <div className="questions-info-result">{question.question}</div>
                        </div>

                        <div className="card-text questions-info-container">
                          <div className="questions-info-header align-self-stretch">Options:</div>
                          <div className="questions-info-result">
                            <ul>
                              {question.options.map((option, optionIndex) => (
                                <li key={optionIndex}>
                                  <span style={{ backgroundColor: question.correct_answer === option ? '#b9e5b9' : 'transparent' }}>{option}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        <div className="card-text questions-info-container">
                          <div className="questions-info-header">Course:</div>
                          <div className="questions-info-result">{question.course_name}</div>
                        </div>

                        <div className="card-text questions-info-container">
                          <div className="questions-info-header">Subject:</div>
                          <div className="questions-info-result">{question.subject_name}</div>
                        </div>

                        <div className="card-text questions-info-container">
                          <div className="questions-info-header">Topic:</div>
                          <div className="questions-info-result">{question.topic_name}</div>
                        </div>

                        <div className="card-text questions-info-container">
                          <div className="questions-info-header">Question Type:</div>
                          <div className="questions-info-result">{question.question_type}</div>
                        </div>

                        <div className="card-text questions-info-container">
                          <div className="questions-info-header">Complexity:</div>
                          <div className="questions-info-result">{Complexities[question.complexity]}</div>
                        </div>

                        {(question.is_verified === undefined || question.is_verified === false || question.is_verified === 0) ? (
                          <>
                            <div className="card-footer text-center">
                              <button className="delete_item" onClick={() => moveToTrash(question._id)}>
                                Delete
                              </button>&nbsp;
                              <button className="delete_item" onClick={() => moveToQuestionBank(question._id)}>
                                Move to Question Bank
                              </button>
                            </div>
                            {/*<div className="card-footer text-center">
                              <button className="delete_item" onClick={() => editQuestion(question._id)}>
                                Edit & Move to Question Bank
                              </button>
                            </div>*/}
                          </>
                        ) : (
                          <div className="card-footer text-center">Moved to Question Bank</div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <nav>
                <ul className="pagination">
                  {[...Array(totalPages).keys()].map((pageNumber) => (
                    <li
                      key={pageNumber}
                      className={`page-item ${page === pageNumber + 1 ? 'active' : ''}`}
                    >
                      <SoundButton className="page-link" onClick={() => setPage(pageNumber + 1)}>
                        {pageNumber + 1}
                      </SoundButton>
                    </li>
                  ))}
                </ul>
              </nav>
            </>
          )}
        </>
      )}
    </div>
  );
}
