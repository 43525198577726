import React, { useState, useEffect } from 'react';

// Array of time zones with one option per country
const timeZones = [
  { value: 'Europe/London', label: 'London, UK' },
  { value: 'America/New_York', label: 'New York, USA' },
  { value: 'Asia/Tokyo', label: 'Tokyo, Japan' },
  { value: 'Australia/Sydney', label: 'Sydney, Australia' },
  { value: 'Asia/Kolkata', label: 'Delhi, India' },
  { value: 'Europe/Berlin', label: 'Berlin, Germany' },
  { value: 'America/Los_Angeles', label: 'Los Angeles, USA' },
  { value: 'Asia/Shanghai', label: 'Shanghai, China' },
  { value: 'Europe/Paris', label: 'Paris, France' },
  { value: 'Africa/Johannesburg', label: 'Johannesburg, South Africa' },
  { value: 'America/Sao_Paulo', label: 'Sao Paulo, Brazil' },
  { value: 'Asia/Singapore', label: 'Singapore' },
];

const TimeZoneDropdown = (props) => {
    console.log(props,"props in timezone")
  const [selectedTimeZone, setSelectedTimeZone] = useState(props.selectedExamTimeZone);
  const [utcOffset, setUtcOffset] = useState(props.examUtcOffset);

  useEffect(() => {
    props.setselectedExamTimeZone(() => {
        console.log("setting utcoffset to ", utcOffset)
        props.setExamUtcOffset(utcOffset);
        return selectedTimeZone;
    });
   
  }, [selectedTimeZone])

  const handleChange = (event) => {
    const selectedZone = event.target.value;
    console.log("called", selectedZone)
    // Calculate the UTC offset in minutes
    const currentDate = new Date();
    const options = { timeZone: selectedZone };
    const utcFormatter = new Intl.DateTimeFormat('en-US', {
      ...options,
      timeZoneName: 'shortOffset',
    });
    const parts = utcFormatter.formatToParts(currentDate);

    // Extract the UTC offset from the formatted parts
    let offset = parts.find((part) => part.type === 'timeZoneName')?.value;
    
    if (offset) {
      // Normalize the format to ±hh:mm
      offset = offset.replace(/^UTC/, '');// Remove 'UTC' prefix
      console.log("called offset name type", offset)
      if (!offset.includes(':')) {
        // Add ':00' if minutes are not present
        offset += ':00';
      }
      offset=offset.substring(offset.indexOf("GMT")+3);
      setSelectedTimeZone(() => {
        setUtcOffset(offset);
        return selectedZone;
      });
    } else {
      offset = 'N/A';
      setSelectedTimeZone(() => {
        setUtcOffset("");
        return "";
      });
      alert("unable to fetch timezone ");
      window.location.href="/";
    }
   
   
  };

  return (
    <div>
      <select value={selectedTimeZone} onChange={handleChange} required>
        {timeZones.map((zone) => (
          <option key={zone.value} value={zone.value}>
            {zone.value}
          </option>
        ))}
      </select>
      {/* {selectedTimeZone && (
        <div>
          <p>Selected Time Zone: {selectedTimeZone}</p>
          <p>UTC Offset: {utcOffset}</p>
        </div>
      )} */}
    </div>
  );
};

export default TimeZoneDropdown;
