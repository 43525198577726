import React from 'react';
import { useContext,useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/header';
import Footer from '../../components/Footer/footer';
import Sidebar from '../../components/Sidebar/sidebar';
import Context from '../../user_details';
import './home.css'; // Import CSS file
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import axios from 'axios';
import SMTPSettings from '../../components/SMTPSettings/smtpSettings';

export default function Home() {
  const {user} = useContext(Context);
  const [loader, setLoader] = useState(true); 
  const [isSMTPAvailable, setSmtpAvailability] = useState(undefined);
  const navigate = useNavigate();
  console.log("user details in home page",user);

  useEffect(() => {
    if(!isSMTPAvailable) getSMTPInfo();
  }, []);

  useEffect(() => {
    if (isSMTPAvailable !== undefined) {
      console.log("smtp is dedfinedd");
      if (!isSMTPAvailable) navigate("/SMTP-Settings");
      setLoader(false);
    }
  }, [isSMTPAvailable])

  const getSMTPInfo = async() => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/ums/getCompanySMTPAvailability/${user.firm_id}`)
    if(result.data.success) {
      setSmtpAvailability(true)
    }
    else {
      setSmtpAvailability(false);
    }
  }

  return (
    <div className="d-flex flex-column" style={{ height: '100vh' }}>
      <Header/>

      {/* <div className="d-flex align-items-stretch" style={{height: '85vh'}}>
        <Sidebar/>
        <Outlet/>
      </div>
      <Footer/> */}

      <div className="flex-grow-1 d-flex main-left-sidebar">
        <div className="col-lg-2">
          <Sidebar isSMTPAvailable={isSMTPAvailable}/>
          <Footer />
        </div>
        <div className="col-lg-10">
          <div className="app-main-content">
            <div className='page-container'>
              {isSMTPAvailable && <Outlet />}
              {isSMTPAvailable === false && <SMTPSettings/>}
              {isSMTPAvailable === undefined &&
                <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                  <div className="text-center text-primary fw-bolder fs-2">
                    <div className="spinner-border" role="status"></div>
                  </div>
                </div>
              }
            </div>
          </div>
          
        </div>
      </div>

    </div>
  );
}

