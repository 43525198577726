// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exams-container{
    height:100%;
    width: 100%;
}
.exam-card{
    height:15rem;
    width: 15rem;
    border:1px solid black;
    border-radius: 12px;
    cursor: pointer;
}

.card-body {
    cursor: pointer;
}

.exam-card:hover {
    background-color: rgb(229, 229, 238);
}

.exam-search-input {
    border-radius: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    outline: none;
    font-family: cursive;
}

#examEditModal {
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
}

.exams-list .page-added-item .added-items-list.card .card-footer .exams-list-delete_item{
    width: auto;
    border-radius: 50px;
    padding: 5px 20px;
    font-size: 14px;
    background-color: #00b3f4;
    border: solid 1px #00b3f4;
    color: #ffffff;
    margin: 0 7px;
}
.exams-list .page-added-item .added-items-list.card .card-body .exam-reports-container{
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Exams/exams.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,qBAAqB;IACrB,oBAAoB;IACpB,qBAAqB;IACrB,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,kCAA0B;YAA1B,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,yBAAyB;IACzB,yBAAyB;IACzB,cAAc;IACd,aAAa;AACjB;AACA;IACI,eAAe;AACnB","sourcesContent":[".exams-container{\n    height:100%;\n    width: 100%;\n}\n.exam-card{\n    height:15rem;\n    width: 15rem;\n    border:1px solid black;\n    border-radius: 12px;\n    cursor: pointer;\n}\n\n.card-body {\n    cursor: pointer;\n}\n\n.exam-card:hover {\n    background-color: rgb(229, 229, 238);\n}\n\n.exam-search-input {\n    border-radius: 1.5rem;\n    padding-left: 0.5rem;\n    padding-right: 0.5rem;\n    outline: none;\n    font-family: cursive;\n}\n\n#examEditModal {\n    backdrop-filter: blur(1px);\n}\n\n.exams-list .page-added-item .added-items-list.card .card-footer .exams-list-delete_item{\n    width: auto;\n    border-radius: 50px;\n    padding: 5px 20px;\n    font-size: 14px;\n    background-color: #00b3f4;\n    border: solid 1px #00b3f4;\n    color: #ffffff;\n    margin: 0 7px;\n}\n.exams-list .page-added-item .added-items-list.card .card-body .exam-reports-container{\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
