import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

const SectionWisePerformanceChart = ({ sectionDetails }) => {

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip">
              <span className="label">{`Section: ${label}`}</span><br />
              <span className="intro">{`Correct Answers: ${payload[0].value}`}</span><br />
              <span className="intro">{`# Of Questions: ${payload[1].value}`}</span>
            </div>
          );
        }
        return null;
    };

  return (
    <div className='report-container'>
      <h3>SectionWise Performance</h3>
      <div style={{ height: '350px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={sectionDetails}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="section_name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />}/>
            <Bar dataKey="totalCorrectAnswers" fill="#8884d8" />
            <Bar dataKey="totalAssignedQuestions" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SectionWisePerformanceChart;
