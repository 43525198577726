import React, { useState, useContext } from "react";
import axios from "axios";
import Context from '../../user_details';
import 'react-quill/dist/quill.snow.css';

export default function QuestionGeneratorForm({ onQuestionGenerated }) {
  const context = useContext(Context);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setErrorMessage("Please select a file to upload.");
      return;
    }
    var formData = new FormData();
    formData.append('pdfFile', selectedFile);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/question/question-generator`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("File uploaded successfully:", response.data);
      setSelectedFile(null);
      setErrorMessage("");
      onQuestionGenerated();
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorMessage("Failed to upload file. Please try again.");
    }
  };

  return (
    <div className="question-generator-form">
      <h3>Question Generator</h3>
      <div className="file-upload-container">
        <label htmlFor="file-upload" className="btn btn-primary">
          Upload File
        </label>
        <input
          id="file-upload"
          type="file"
          accept=".pdf"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        {selectedFile && (
          <button className="btn btn-secondary" onClick={handleFileUpload}>
            Submit File
          </button>
        )}
        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
}
