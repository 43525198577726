import React, { useState } from 'react';

const StudentsList = ({ analysisData, studentsDetails, ShowGraphs }) => {
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });

  const sortData = (data, config) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a[config.key] < b[config.key]) {
        return config.direction === 'ascending' ? -1 : 1;
      }
      if (a[config.key] > b[config.key]) {
        return config.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  };

  const sortedStudentsDetails = sortData(studentsDetails, sortConfig);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return (
        <span >
          {sortConfig.direction === 'ascending' ? '\u2191' : '\u2193'}
        </span>
      );
    }
    return '';
  };

  return (
    <div className='col-md-4 exam-component-students-list rounded'>
      <div className='h-100 students-list-table' id="reportMarks">
        <div className='row g-0 text-center students-list-headers'>
          <div className={analysisData.exam_negative_marks > 0 ? 'col-md-6 students-list-header-info' : 'col-md-9 students-list-header-info'} onClick={() => requestSort('name')}>
            <span title='Sort By Name'>Name {getArrow('name')}</span>
          </div>
          <div className='col-md-3 students-list-header-info' onClick={() => requestSort('marks')}>
          <span title='Sort By Marks'>Scored Marks {getArrow('marks')}</span>
          </div>
          {analysisData.exam_negative_marks > 0 && (
            <div className='col-md-3 students-list-header-info' onClick={() => requestSort('negative_marks')}>
              <span title='Sort By Negative Marks'>(-)Ve Marks {getArrow('negative_marks')}</span>
            </div>
          )}
        </div>
        <div className='students-exam-info custom-scrollbar-styles' style={{ overflowY: 'auto' }}>
          {sortedStudentsDetails.map((detail, index) => (
            <div key={index} onClick={() => { ShowGraphs(detail.student_id) }} className='row g-0 text-center students-exam-info-container'>
              <div className={analysisData.exam_negative_marks > 0 ? 'col-md-6 students-list-exam-info' : 'col-md-9 students-list-exam-info'}>{detail.name}</div>
              <div className='col-md-3 students-list-exam-info'>{detail.marks}</div>
              {analysisData.exam_negative_marks > 0 && (
                <div className='col-md-3 students-list-header-info'>{detail.negative_marks}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudentsList;
