import React, { useState, useEffect, useContext } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios'; // Import CSS file
import './examPatterns.scss';
import './examPatterns.css';
import Context from '../../user_details';
import AddExamPatternForm from '../AddExamPatternForm/AddExamPatternForm'; // Import the AddExamPatternForm component
import SpinnerLoader from '../../helpers/spinner-loader';
import no_data_image from '../../assets/no-data.jpg';


export default function ExamPatterns() {
  const [examPatterns, setExamPatterns] = useState([]);
  const [showForm, setShowForm] = useState(false); // State variable to manage form visibility
  const [deletePatternId,setDeletePatternId] = useState();
  const [updatepatternValue, setUpdatePatternValue] = useState();
  const [updateLoader, setUpdateLoader] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [allPatternsList, setAllPatternsList] = useState([]);
  const {user} = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    setExamPatterns(allPatternsList);
  }, [allPatternsList]);

  useEffect(() => {
    fetchExamPatterns();
  },[]);

  useEffect(() => {
    if(true) {
      setIsSearching(false);
    }
    console.log(examPatterns, isSearching, updateLoader)
  }, [examPatterns])

  useEffect(() => {
    setUpdateLoader(false);
  } ,[updatepatternValue]);

  const fetchExamPatterns = async () => {
    try {
      setUpdateLoader(true);
      const response = await axios.get(process.env.REACT_APP_API_URL+`/examPattern/list/${user.firm_id}?type=${user.user_type}`);
      
      setUpdateLoader(() => {
        setAllPatternsList(response.data.examPatterns);
        return false;
      });

    } catch (error) {
      console.error('Error fetching exam patterns:', error);
    }
  };

  const examPatternDelete = async() => {
    const hasPermission = await user.permissionTypes.includes("createCourses");
    if ( hasPermission) {
    try {
      const response = await axios.patch(process.env.REACT_APP_API_URL+`/examPattern/delete/${deletePatternId}`);
      fetchExamPatterns();
      navigate("/examPatterns");

    } catch (error) {
      console.error('Error fetching exam patterns:', error);
    }}
  };
  
  const toggleUpdateLoader = () => {
    navigate("/examPatterns");
    fetchExamPatterns();
  }

  const navigateToCreateExamPattern= async()=> {
    const hasPermission = await user.permissionTypes.includes("createCourses");
    if ( hasPermission) {
    navigate("/examPatterns/create")}
  }
  const handleExamPatternAdded = () => {
    // Close the form and refresh the exam pattern list
    setShowForm(false);
    fetchExamPatterns();
  };

  const ModalClose = () => {
    fetchExamPatterns();
  }

  const setDetailsOnUpdate = async (exam_pattern) => {
    var isPatternUsed = await axios.get(`${process.env.REACT_APP_API_URL}/examPattern/isUsed/${exam_pattern._id}`)
    isPatternUsed = isPatternUsed.data;
    if (isPatternUsed.success) {
      alert(`${isPatternUsed.message}`)
    }
    else {
      document.getElementById("examPatternFormToggler").click();
      setUpdateLoader(true);
      setUpdatePatternValue(exam_pattern);
      fetchExamPatterns();
    }
    
  }
  console.log(`updated Loader ${updateLoader}`)

  const filterPatternsList = async(event) => {
    const key = event.target.value.trim();
    setIsSearching(true);
    let filteredList = await Promise.all(allPatternsList.filter(exam => exam.exam_pattern_name.includes(key)));
    setExamPatterns(filteredList);
  }

  return (
    <div className="exam-patterns-container w-100 overflow-auto">
      {/* --------------View and Edit modal--------------------- */}
      <div className="modal fade update-modal" id="viewExamPattern" tabindex="-1" role="dialog" aria-labelledby="viewExamPatternLabel" aria-hidden="true">
        <div className="modal-dialog d-flex align-items-stretch p-0 position-absolute m-0 w-100 update-modal-dialog" role="document">
          <div className="modal-content flex-grow-3"  >
            <div className="modal-header border p-0 ps-3">
              <h5 className="modal-title fs-5" style={{color:'#3a4fb1', fontWeight:'500'}}  id="viewExamPatternLabel">Edit Exam Pattern</h5>
              <div className="close btn btn-white m-0 ms-auto align-items-center fs-2 p-0 px-1 fw-bold" data-bs-dismiss="modal" onClick={ModalClose} aria-label="Close">
                <span aria-hidden="true" className='p-0 px-1 text-danger'>&times;</span>
              </div>
            </div>
            <div className="modal-body border update-modal-body">
                {!updateLoader &&<AddExamPatternForm onAddExamPattern={updatepatternValue} toggleUpdateLoader={toggleUpdateLoader}/>}
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------*/}
      {/* --------------------exam pattern delete modal------------------------------------ */}
              <div className="modal fade align-item-center justify-content-center" id="deleteExamPattern" tabindex="-1" role="dialog" aria-labelledby="deleteExamPatternTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog " role="document">
                    <div className="modal-content ">
                      <div className="modal-header bg-danger p-0">
                          <p className='text-white fs-5 my-auto ms-3'>Warning</p>
                          <button type="button" className="close bg-danger text-white border-0 fs-3 p-0 m-0 me-2 ms-auto" data-bs-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div className="modal-body">
                        <p>This Exam pattern will be no longer available to any exams. Do you wish to proceed? </p>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" onClick={()=>{fetchExamPatterns();navigate("/examPatterns")}}>Cancel</button>
                        <button type="button" className="btn btn-outline-danger" onClick={examPatternDelete} data-bs-dismiss="modal" >Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
      {/* --------------------------------------------------------------------------- */}

      {/* ========= Header and Search container - STARTS here ========== */}
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-8">
            <div class="page-header-container">
              <h2 class="page-main-header">Exam Patterns</h2>
         { user.permissionTypes.includes("createExamPatterns")  &&  <div className='btn btn-primary exam-patterns-search-btn p-2' onClick={navigateToCreateExamPattern}>
                + New Exam Pattern
            </div>}
            </div>
          </div>
          <div class="col-sm-4"> 
          <input type="search" className='px-3 exam-patterns-search-element rounded'  
            style={{width:'100%'}} placeholder='Search pattern name'
            onChange={filterPatternsList}
            />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12"> <p class="mb-0">&nbsp;</p> </div>
        </div>
      </div>

      {/* ========= Header and Search container - Ends here ========== */}


      <table className=' w-100 exam_pattern_table'>

        <tr className='exam_pattern_table-header bg-primary'>
          <th className=''>Name</th>
          {user.user_type=='superAdmin' && <th className=''> Firm</th>}
          <th className=''> Number Of Sections</th>
          <th className=''>Choose your Action</th> 
        </tr> 
        {!updateLoader && !isSearching ? 
          examPatterns.length?
          examPatterns.map((exam_pattern,index)=>{
          return(
              <tr className='exam_pattern_table-content '  key={index}>
                    <div className='d-none' data-bs-toggle="modal" data-bs-target="#viewExamPattern" id="examPatternFormToggler"></div>
                    <td className='exam_pattern-name-sections-info'>{exam_pattern.exam_pattern_name}</td>
                    {user.user_type=='superAdmin' &&<td className='exam_pattern-name-sections-info'>{exam_pattern.firm}</td>}
                    <td className='exam_pattern-name-sections-info'>{exam_pattern.no_of_sections}</td>
                    <td className=''>
                      {user.permissionTypes.includes("viewExamPatterns")  && <div className='btn btn-primary exam_pattern_view' 
                        onClick={()=>{setDetailsOnUpdate(exam_pattern)}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                          </svg> View & Edit
                      </div>}
                    
                      {user.permissionTypes.includes("deleteExamPatterns")  && <div className='btn btn-danger exam_pattern_delete' 
                        data-bs-toggle="modal" data-bs-target="#deleteExamPattern" 
                        onClick={()=>{setDeletePatternId(exam_pattern._id)}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                          </svg> Delete
                      </div>}
                    </td>
              </tr>
              )
        }) : 
        <tr>
          {/* No Patterns Found.. */}
          <td colSpan={'3'} className='text-center'>
            <img className="img-fluid" src={no_data_image} alt='No Data Found' />
          </td>
        </tr>: 
        // <div>Loading....</div>
        <tr>
          <td colSpan={3}>
            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                <SpinnerLoader />
            </div>
          </td>
        </tr>

        }
      
      </table>
    </div>
  );
}
