// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assign-modal {
  --bs-modal-width: 70vw;
  top: 3%;
  left: 20%;
  z-index: 999999;
}
.assign-modal-dialog {
  height: 90%;
  --bs-modal-width: 55vw;
}
.assign-modal-body {
  height: 75vh;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/AddExamForm/AddExamForm.scss"],"names":[],"mappings":"AACI;EACI,sBAAA;EACA,OAAA;EACA,SAAA;EACA,eAAA;AAAR;AAGI;EACQ,WAAA;EACA,sBAAA;AADZ;AAII;EACQ,YAAA;EAEA,gBAAA;AAHZ","sourcesContent":[".assign{\n    &-modal {\n        --bs-modal-width: 70vw;\n        top:3%;\n        left:20%;\n        z-index:999999;\n    }\n\n    &-modal-dialog {\n            height:90%;\n            --bs-modal-width: 55vw;\n        }\n        \n    &-modal-body {\n            height:75vh;\n            \n            overflow-y: auto;\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
