import axios from 'axios';
import { playSound } from '../../helpers/otherSounds';

// Define the handleSubmit function
export const handleSubmitHelper = async (event, {
  isSubmitting, setIsSubmitting, selectedExamPattern, selectedUserGroup, 
  selectedCourse, user, sectionValues, examName, negativeMarks, questionsPerNegative,
  passMarks, selectedSubject, navigate, setActionLoader, props, examUtcOffset, selectedExamTimeZone,selectedFirm
}) => {
  event.preventDefault();

  if (isSubmitting) return;
  setIsSubmitting(true);

  let key, value;
  if (selectedExamPattern.login_required) {
    key = 'user_group';
    value = selectedUserGroup;
  } else {
    key = 'examinee_email';
    value = document.getElementById("examineeEmails").value.split(",").map(email => email.trim());
  }

  let newExam = {
    examName,
    selectedExamPattern: selectedExamPattern._id,
    selectedCourse: selectedCourse._id,
    client_id: selectedFirm,
    sections: sectionValues,
    created: { by: user._id },
    status: 1,
    total_marks: selectedExamPattern.total_marks,
    [key]: value,
    negativeMarks: negativeMarks,
    questionsPerNegative: questionsPerNegative,
    pass_marks: passMarks,
    timeZoneOffset: {zone: selectedExamTimeZone, offset: examUtcOffset}
  };

  if (!selectedExamPattern.section_wise_timings) {
    let startDate = document.getElementById('startdatetime').value;
    let endDate = document.getElementById('enddatetime').value;
    newExam = { ...newExam, startDate, endDate };
  }

  if (selectedExamPattern.single_subject_exam) {
    newExam = { ...newExam, selectedSubject };
  }

  try {
    let result;
    if (!Object.keys(props).includes("selectedExam")) {
      setActionLoader(true);
      result = await axios.post(`${process.env.REACT_APP_API_URL}/exam/create`, newExam);
    } else {
      let { updated } = props.selectedExam;
      updated.push({ by: user._id });
      newExam = { ...newExam, updated };
      setActionLoader(true);
      result = await axios.patch(`${process.env.REACT_APP_API_URL}/exam/update/${props.selectedExam._id}`, newExam);
    }

    if (result.data.success) {
      document.getElementById("examCreateSuccessMsgToggler")?.click();
      setIsSubmitting(false);
      setTimeout(() => {
        document.getElementById("examCreateSuccessClose")?.click();
        navigate('/exams');
      }, 3000);
      playSound('SUCCESS');
    } else {
      setIsSubmitting(false);
      playSound('ERROR');
      alert("Unable to create / update exam. Please try later");
    }
    setActionLoader(false);
  } catch (e) {
    console.error(e);
    setTimeout(() => {
      setActionLoader(false);
    }, 3000);
    setIsSubmitting(false);
    playSound('ERROR');
    alert("Creation error: exam");
  }
};
