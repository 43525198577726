import './exams.css'
import {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../../user_details';
import AddExamForm from '../AddExamForm/AddExamForm';
import CloneExam from './cloneExam';
import axios from 'axios';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SpinnerLoader from '../../helpers/spinner-loader';
import { setDatetoReadableFormat } from '../../helpers/examHelper';
import no_data_image from '../../assets/no-data.jpg';

export default function Exams(){
    const [examsList, setExamList] = useState([]);
    const navigate = useNavigate();
    const {user} = useContext(Context);
    const [selectedExam, setSelectedExam] = useState({});
    const [selectedExamToDelete, setSelectedExamtoDelete] = useState('');
    const [examListLoader, setExamListLoader] = useState(true);
    const [isExamSelected, setIsExamSeleted] = useState(false);
    const [show, setShow] = useState(false);
    const [examsLandingPageLoader,setExamsLandingPageLoader] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [allExamsList, setAllExamsList] = useState([]);
    
    useEffect(() => {
        getExamsList();
    } ,[]);

    useEffect(() => {
        console.log("exam is selected", isExamSelected)
    }, [isExamSelected])

    useEffect(() => {
        if(Object.keys(examsList).length > 0) {
            setExamListLoader(false);
        }
        if(allExamsList.length) {
            setIsSearching(false);
        }
    }, [examsList]);

    useEffect(() => {
        console.log("selected exam is ", selectedExam)
        if (Object.keys(selectedExam).length > 0) {
            setIsExamSeleted(true);
            document.getElementById("examEditModalToggler").click();
        }
    }, [selectedExam]);

    const examNavigator = async(examRoute) => {
        // setExamsLandingPageLoader(true);
        // const result = await axios.get(`${process.env.REACT_APP_API_URL}/ums/getCompanySMTPAvailability/${user.firm_id}`);
        // if(result.data.success === true) {
        //     setExamsLandingPageLoader(false);
        //     navigate(`/exams/${examRoute}`);
        // }
        // else {
        //     setExamsLandingPageLoader(false);
        //     setShow(true);
        //     const closeErrorPopUp = setTimeout(() => {
        //         setShow(false);
        //         clearInterval(closeErrorPopUp);
        //     }, 1500)
        // }
        const hasPermission = await user.permissionTypes.includes("createCourses");
        if ( hasPermission) {
        setExamsLandingPageLoader(false);
        navigate(`/exams/${examRoute}`);}

    }
    
    const editQuestion = (event,exam) => {
       getExamDetails(exam._id);
    }

    const getExamDetails = async(examId) => {
        try{
            let exam = await axios.get(`${process.env.REACT_APP_API_URL}/exam/read/${examId}`);
            exam = exam.data
            let selectedExamPattern = await axios.get(`${process.env.REACT_APP_API_URL}/examPattern/read/${exam.selectedExamPattern}`);
            exam.selectedExamPattern = {...selectedExamPattern.data};
            setSelectedExam({...exam});
        }
        catch(e) {
            console.log(e)
        }
    }

    const getExamsList = async() => {
        setIsSearching(true)
        try {
            const result = await axios(`${process.env.REACT_APP_API_URL}/exam/list?firm_id=${user.firm_id}&type=${user.user_type}`);
            setExamList(result.data.exams);
            setAllExamsList(result.data.exams)
        }
        catch(e) {
            console.error(e);
        }
        finally {
            setIsSearching(false)
        }
    }

    const updateModalClosed = async() => {
        const hasPermission = await user.permissionTypes.includes("createCourses");
        if ( hasPermission) {
        setIsExamSeleted(false);}
    }

    const deleteExam = async() => {
        const hasPermission = await user.permissionTypes.includes("createCourses");
        if ( hasPermission) {

        try {
            const result = await axios.patch(`${process.env.REACT_APP_API_URL}/exam/delete/${selectedExamToDelete}`);
            console.log(result.data)
            if(result.data.success === true) {
                getExamsList();
                document.getElementById("examDeleteSuccessMsgToggler").click();
                setTimeout(() => {
                    document.getElementById("examDeleteSuccessClose").click()
                }, 1000)
            }
        }
        catch(e) {
            alert("axios response error")
        }}
    }

    const navigateToUpdateForm = (examId) => {
        navigate(`/exams/update/${examId}`);
    }

    const verifyExamConduct = async(examId) => {
       try {
        
        const hasPermission = await user.permissionTypes.includes("editExams");
        if ( hasPermission) {
            let isExamConducted = await axios.get(`${process.env.REACT_APP_API_URL}/exam/isConducted/${examId}`);
            isExamConducted = isExamConducted.data;
            if(isExamConducted.success) {
                if (!isExamConducted.result) {
                    navigateToUpdateForm(examId);
                } else{
                    alert("Cannot Edit the Exam That has been conducted already!")
                }
            }
        }
        else {
            throw new Error("Authentication revoked");
            
        }
       } catch (e) {
        alert(e)
       }
    }

    const filterExamsList = async(event) => {
        const key = event.target.value.trim().toLowerCase();
        setIsSearching(true);
        let filteredList = await Promise.all(allExamsList.filter(exam => exam.examName.trim().toLowerCase().includes(key)));
        setExamList(filteredList);
    }

    if (!examsLandingPageLoader) {
        return (
            <div className="exams-container exam-patterns-container p-3">
                
                {/* ========= Header and Search container - STARTS here ========== */}
                <div class="">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="page-header-container">
                                <h2 class="page-main-header">Exams</h2>
                                
                                {user.permissionTypes.includes("createExams") &&  
                                    <div className='btn btn-primary exam-patterns-search-btn p-2' 
                                    onClick={()=>{examNavigator("create")}}>
                                        &nbsp;
                                        <span className="left-menu-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hourglass-split" viewBox="0 0 16 16">
                                                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                                            </svg>
                                        </span>&nbsp;Add New Exam&nbsp;</div>}
                            </div>
                        </div>
                        <div class="col-md-4"> 
                            <input type="search" className='px-3 exam-patterns-search-element rounded' 
                                style={{width:'100%'}} placeholder='search exam'
                                onChange={filterExamsList}
                            />
                        </div>
                    </div>
    
                    <div class="row">
                    <div class="col-sm-12"> <p class="mb-0">&nbsp;</p> </div>
                    </div>
                </div>
    
                {/* ========= Header and Search container - Ends here ========== */}
                
               
               <div className="exams-list row row-cols-1 row-cols-md-3 g-3">
                {!isSearching ?
                    examsList.length > 0 ?examsList.map((exam,index)=>{
                        return (
                        <div key={index} className='exam-card000 page-added-item col'>
                            <div class="added-items-list card h-100">
                                <div class="card-body">
                                    <div onClick={()=>{examNavigator(exam._id)}} >
                                        <h5 class="card-title min-ht-25">{exam.examName}</h5>
                                        {!examListLoader && 
                                        <div className='exam-reports-container' >
                                            {user.user_type == 'superAdmin' && <p className='card-text mb-1'>
                                                <strong>Firm:</strong>
                                                <span className='report-value' >{exam.firm}</span>  
                                            </p>}
                                            <p className='card-text mb-1'>
                                                <strong>Course:</strong>
                                                <span className='report-value' >{exam.selectedCourse.course_name}</span>  
                                            </p>
                                            <p className='card-text mb-1'>
                                                <strong>Exam Pattern: </strong> 
                                                <span className='report-value' >{exam.selectedExamPattern.exam_pattern_name}</span>
                                            </p>
                                            <p className='card-text mb-1'>
                                                <strong>Sections:</strong> 
                                                <span className='report-value' >{exam.selectedExamPattern.no_of_sections}</span>
                                            </p>
                                            <p className='card-text mb-1'>
                                                <strong>Start Date:</strong>  
                                                <span className='report-value' >{setDatetoReadableFormat(exam.startDate)}</span>
                                            </p>
                                            <p className='card-text mb-1'>
                                                <strong>End Date:</strong> 
                                                <span className='report-value' >{setDatetoReadableFormat(exam.endDate)}</span>
                                            </p>
                                            <p className='card-text mb-1'>
                                                <strong>Timezone:</strong> 
                                                <span className='report-value' >{exam.timezone}</span>
                                            </p>
                                        </div>}
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <div className='text-center'>
                                {user.permissionTypes.includes("editExams") &&  
                                        <div className='btn exams-list-delete_item'  
                                            onClick={() => {verifyExamConduct(exam._id)}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                                </svg> &nbsp; Edit
                                        </div>}
                                {user.permissionTypes.includes("deleteExams") &&  
                                        <div className='btn exams-list-delete_item' 
                                            data-bs-toggle="modal" data-bs-target="#examDeleteModal" 
                                            onClick={() => setSelectedExamtoDelete(exam._id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                                </svg> &nbsp; Delete
                                        </div>}
                                        {/*<CloneExam examId={exam._id} getExamsList={getExamsList} />*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    }):
                     <div className='d-flex justify-content-center align-items-stretch h-100 w-100 p-3 border border-3 border-muted'>
                         <img className="img-fluid" src={no_data_image} alt='No Data Found' />
                     </div>                    
                    : 
                    // <div>Loading.......</div>
                    <div className="ht60vh w-100 d-flex justify-content-center align-items-center">
                        <SpinnerLoader />
                    </div>
                    }
               </div>
                {/* ========================================================= */}
    
               {/* ---------------------------Exam Edit modal ---------------------------- */}
               <button className='d-none' data-bs-toggle="modal" data-bs-target="#examEditModal" id="examEditModalToggler"/>
                <div className="modal"  data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" id="examEditModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header text-secondary">
                                
                            </div>
                            <div className="modal-body">
                                {isExamSelected? Object.keys(selectedExam).length > 0 && <AddExamForm selectedExam={selectedExam}/>:"Loading.. please wait..."}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={updateModalClosed}>Close</button>
                                <button type="button" className="btn btn-outline-success" data-bs-dismiss="modal" onClick={updateModalClosed}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ----------------------------------------------------------------------- */}
                {/* ----------delete Exam modal warning ------------------------------------ */}
                <div className="modal" tabindex="-1" id="examDeleteModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-danger">Are you sure?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Deleting this exam will result into no longer availability of this exam. Do you really wish to proceed to delete the exam?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">cancel</button>
                            <button type="button" className="btn btn-danger" onClick={deleteExam}>Confirm</button>
                        </div>
                        </div>
                    </div>
                </div>
                {/* ------------------------------------------------------------------------- */}
                {/*--------------------------- Successful exam delete modal message -----------------------------*/}
                <button type="button" className="btn btn-primary d-none" id="examDeleteSuccessMsgToggler" data-bs-toggle="modal" data-bs-target="#examDeleteMsg"></button>
                <div className="modal fade" id="examDeleteMsg" tabIndex="-1" role="dialog" aria-labelledby="examDeleteMsgLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{left:'100%'}}>
                        <div className="modal-body d-flex align-items-center bg-white text-success justify-content-between">
                        <div>Exam deleted successfully!</div>
                        <div className='btn p-0 px-1' data-bs-dismiss="modal" id="examDeleteSuccessClose" aria-label="Close"> &times;</div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* ----------------------------------------------------------------------------------------*/}
                <Offcanvas show={show} onHide={() => { setShow(false) }} placement='end' style={{ height: 'fit-content' }}>
                    <Offcanvas.Body className='text-danger'>
                        The organisation did not provide the neccessary details for smtp services. please wait until the smtp service is set up by the organisation.
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        )
    }
    else {
       return <div className='h-100 w-100 d-flex justify-content-center align-items-stretch'>
             <div className="text-center text-primary fw-bolder fs-2">
                <div className="spinner-border" role="status"></div>
            </div>
        </div>
    }
}