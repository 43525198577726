// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer.app-main-footer {
  background-color: transparent;
  color: #fff;
  height: auto;
  position: absolute;
  bottom: 5px;
  margin-left: 15px;
}
footer.app-main-footer h4{
  font-size: 11px;
  font-weight: normal;
  padding: 5px;
  margin-bottom: 0;
}
  `, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["footer.app-main-footer {\n  background-color: transparent;\n  color: #fff;\n  height: auto;\n  position: absolute;\n  bottom: 5px;\n  margin-left: 15px;\n}\nfooter.app-main-footer h4{\n  font-size: 11px;\n  font-weight: normal;\n  padding: 5px;\n  margin-bottom: 0;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
