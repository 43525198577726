import { useState, useContext } from 'react';
import axios from 'axios';
import Context from '../../user_details';

const CloneExam = ({examId, getExamsList}) => {
    const { user } = useContext(Context);
    const [showPopup, setShowPopup] = useState(false);
    const [newExamName, setNewExamName] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const handleCloneExam = async () => {
        setIsSaving(true);
        try {
            const result = await axios.post(`${process.env.REACT_APP_API_URL}/exam/clone-exam/${user._id}`, { exam_id: examId, new_exam_name: newExamName });
            if (result.data.success) {
                setShowPopup(false);
                getExamsList();
            }
        } catch (error) {
            console.error("Error cloning exam:", error);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            <button className='btn exams-list-clone_item' onClick={() => setShowPopup(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files" viewBox="0 0 16 16">
                    <path d="M13 1H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zM5 2h8a1 1 0 0 1 1 1v4H4V3a1 1 0 0 1 1-1zm0 1v4h8V3H5zm0 6v5a1 1 0 0 1-1 1H3V7h2v1zM3 9h2v5H4a1 1 0 0 1-1-1V9zm4 0h6v1H7V9zm0 2h6v1H7v-1zm0 2h6v1H7v-1z" />
                </svg> &nbsp; Clone
            </button>

            {showPopup && (
                <div className="modal show d-block" tabindex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Clone Exam</h5>
                                <button type="button" className="btn-close" onClick={() => setShowPopup(false)} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter new exam name"
                                    value={newExamName}
                                    onChange={(e) => setNewExamName(e.target.value)}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-danger" onClick={() => setShowPopup(false)}>Close</button>
                                <button
                                    type="button"
                                    className="btn btn-outline-success"
                                    onClick={handleCloneExam}
                                    disabled={!newExamName || isSaving}
                                >
                                    {isSaving ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CloneExam;
