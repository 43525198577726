import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './subjects.css'; // Import CSS file
import AddSubjectForm from '../AddSubjectForm/addSubjectForm'; // Import the AddSubjectForm component
import Context from "../../user_details";
import SoundButton from '../../helpers/soundButton';

export default function Subjects() {
  const context = useContext(Context);
  const user = context.user;
  const [subjects, setSubjects] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showForm, setShowForm] = useState(false); // State variable to manage form visibility
  const [selectedSubject, setSelectedSubject] = useState();
 // const [clientsums, setClientsUms] = useState([]);

  
  useEffect(() => {
    fetchSubjects();
    //fetchClients();
  }, [page]); // Fetch subjects whenever page changes

  const fetchSubjects = async () => {
    try {
      // const response = await axios.get(`http://localhost:8891/api/subjects?page=${page}`);
      // const { subjects, totalPages } = response.data;
      console.log(user);
      let url = `${process.env.REACT_APP_API_URL}/qb/subjects?page=${page}&client_id=${user.firm_id}`;
      if(user.user_type === "superAdmin") {
        url = `${process.env.REACT_APP_API_URL}/qb/subjects?page=${page}`;
      }
      const response = await axios.get(url);
      const { subjects, totalPages} = response.data;          
      setSubjects(subjects);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  // const fetchClients = async () => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
  //     if(response && response.data && response.data.success == true) {         
  //       setClientsUms(response.data.results || []);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching clients:', error);
  //   }
  // };

  const handleSubjectAdded = () => {
    // Close the form and refresh the subject list
    // setShowForm(false);
    setTimeout(function(){setShowForm(false);}, 2000)
    fetchSubjects();
  };

  const addBtnHandler = async() => {
    const hasPermission = await user.permissionTypes.includes("createSubjects");
    if ( hasPermission) {
    setShowForm(true);
    setSelectedSubject();
    }
 };


  const editBtnHandler = async(subject) => {
    const hasPermission = await user.permissionTypes.includes("editSubjects");
    if(hasPermission) {
    setShowForm(true);
    setSelectedSubject(subject);
    }
};

const cancelBtnHandler = () => {
  setShowForm(false);
};

const deleteHandler = async (subject_id) => {
  try {
    const hasPermission = await user.permissionTypes.includes("deleteSubjects");
    if(hasPermission) {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/qb/subjects/${subject_id}`
    ).then(d => {
        fetchSubjects();
    
    });
  }
  } catch (error) {
    console.error("Error Subject Deleted:", error);
  }
};


  return (
    <div className="subjects-container">
      {/* <h2>Subjects</h2>
      <button onClick={() => setShowForm(!showForm)}>Add Subject</button>
      {showForm && <AddSubjectForm onAddSubject={handleSubjectAdded} />} */}

      {/* =============================================== */}
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-8">
            <div class="page-header-container">
              <h2 class="page-main-header">Subjects</h2>
              {user.permissionTypes.includes("createSubjects") && 
              <button onClick={addBtnHandler} class="add-form-btn">
                <span class="icon-placeholder">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                    <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5"/>
                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
                  </svg>
                </span>
                Add Subject
              </button>
          }
            </div>
          </div>
          <div class="col-sm-4"> &nbsp; </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p class="mb-0">&nbsp;</p>
            {showForm && <AddSubjectForm onAddSubject={handleSubjectAdded}  cancelBtnHandler={cancelBtnHandler} selectedSubject={selectedSubject}/>}
          </div>
        </div>
      </div>
      {/* ================================================ */}

      {/* <div className="subject-list">
        {subjects.map((subject, index) => (
          <div key={index} className="subject-item">
            <div className="subject-details">
              <div className="subject-name">{subject.subject_name}</div>
              <div className="created-date">Created: {new Date(subject.created.date).toLocaleDateString()}</div>
            </div>
            <div className="course-names">
              <h4>Course Names:</h4>
              <ul>
                {subject.course_id.map((courseId, idx) => (
                  <li key={idx}>{courseId.course_name}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div> */}

      {/* ================================================ */}
      <div className="subject-list row row-cols-1 row-cols-md-4 g-4">
        {subjects.map((subject, index) => (
          <div key={index} className="page-added-item col">
            <div class="added-items-list card h-100">
              <div class="card-body">
                <h5 class="card-title min-ht-25">{subject.subject_name}</h5>
                <p class="card-text">
                  <strong>Course Names:</strong><br />
                  <ul class="custom-list">
                    {subject.course_id.map((courseId, idx) => (
                      <li key={idx}>{courseId.course_name}</li>
                    ))}
                  </ul>
                </p>
                {context.user.user_type == "superAdmin" && (
                <p class="card-text">
                  <strong>Client Name:</strong><br />
                  <ul class="custom-list">                  
                  {subject.client_name ? subject.client_name : 'N/A'}
                  </ul>
                </p>
                )}
              </div>
              <div class="card-footer">
              {user.permissionTypes.includes("editSubjects") && 
              <button className="delete_item" id="editQuestion" onClick={() => editBtnHandler(subject)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                  </svg> &nbsp; Edit  
                </button>
              }
          {user.permissionTypes.includes("deleteSubjects") && 
                <button className="delete_item" id="editQuestion" onClick={() => { if(window.confirm('Are you sure to delete this Subject?')) deleteHandler(subject._id)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                </svg> Delete </button>
            }
                <p class="text-body-secondary mb-0">
                  <strong>Created:</strong> {new Date(subject.created.date).toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* ================================================ */}

      <nav>
        <ul className="pagination">
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${page === pageNumber + 1 ? 'active' : ''}`}>
              <SoundButton className="page-link" onClick={() => setPage(pageNumber + 1)}>
                {pageNumber + 1}
              </SoundButton>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
