import React from 'react';
import './footer.css'; // Import CSS file

export default function Footer() {
  return (
    <footer className='app-main-footer'>
      <h4>Copyright © 2024 NexusIQ Solutions.</h4>
    </footer>
  );
}
