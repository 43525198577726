// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exam_pattern_table {
  border: 2px solid black !important;
}
.exam_pattern_header {
  border: 2px solid black !important;
}
.exam_pattern_view {
  display: flex;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
}
.exam_pattern_container {
  border-bottom: 1px solid black;
}
.exam_pattern_delete {
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: red;
  float: left;
}
.exam_pattern_name {
  padding-left: 3rem;
  width: 40%;
}

.exam-patterns-container .update-modal {
  --bs-modal-width: 75%;
  top: 3%;
  left: 12.5%;
  z-index: 99999;
}
.exam-patterns-container .update-modal-dialog {
  max-height: 90%;
}
.exam-patterns-container .update-modal-body {
  overflow-y: auto;
  padding: 0 15px;
}
.exam-patterns-search-element {
  border: 2px solid rgb(19, 19, 62);
}
.exam-patterns-search-element:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ExamPatterns/examPatterns.scss"],"names":[],"mappings":"AACI;EACI,kCAAA;AAAR;AAGG;EACK,kCAAA;AADR;AAII;EACI,aAAA;EACA,kBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,YAAA;AAFR;AAKG;EACK,8BAAA;AAHR;AAMI;EACI,kBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,qBAAA;EACA,WAAA;AAJR;AAOI;EACI,kBAAA;EACA,UAAA;AALR;;AAYY;EACI,qBAAA;EACA,OAAA;EACA,WAAA;EACA,cAAA;AAThB;AAYY;EACQ,eAAA;AAVpB;AAaY;EACQ,gBAAA;EACA,eAAA;AAXpB;AAgBI;EACI,iCAAA;AAdR;AAgBQ;EACI,aAAA;AAdZ","sourcesContent":[".exam_pattern {\n    &_table{\n        border: 2px solid black !important;\n    }\n\n   &_header{\n        border: 2px solid black !important;\n    }\n\n    &_view{\n        display: flex;    \n        border-radius:5px;\n        width: fit-content;\n        float: right;\n    }\n\n   &_container{\n        border-bottom: 1px solid black;\n    }\n\n    &_delete{   \n        border-radius: 5px; \n        width: fit-content; \n        background-color: red; \n        float: left;\n    }\n\n    &_name{\n        padding-left: 3rem;    \n        width: 40%;\n    }\n}\n\n.exam-patterns {\n    &-container {\n        .update{\n            &-modal {\n                --bs-modal-width: 75%;\n                top:3%;\n                left:12.5%;\n                z-index: 99999;\n            }\n        \n            &-modal-dialog {\n                    max-height:90%;\n                }\n                \n            &-modal-body {\n                    overflow-y: auto;\n                    padding: 0 15px;\n                }\n        }\n    }\n\n    &-search-element {       \n        border: 2px solid rgb(19, 19, 62);\n        \n        &:focus {\n            outline:none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
