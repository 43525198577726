import axios from 'axios';
import * as XLSX from 'xlsx';

export const assignedQuestionsSchema = [{
  1: {
    1: {
      question_ids: [],
      pick: 0
    },
    2: {
      question_ids: [],
      pick: 0
    },
    3: {
      question_ids: [],
      pick: 0
    }
  },
  2: {
    1: {
      question_ids: [],
      pick: 0
    },
    2: {
      question_ids: [],
      pick: 0
    },
    3: {
      question_ids: [],
      pick: 0
    }
  },
  3: {
    1: {
      question_ids: [],
      pick: 0
    },
    2: {
      question_ids: [],
      pick: 0
    },
    3: {
      question_ids: [],
      pick: 0
    }
  },
  4: [
    {
      comprehensive_id: null,
      question_ids: [],
      pick: 0
    }
  ],
  5: {
    question_ids: [],
    pick: 0
  }
}];

// Helper function to fetch available questions
export const fetchAvailableQuestions = async (types, courseId, subjectId, topicId, complexity, firmId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/question/list?types=${JSON.stringify([types])}&client_id=${firmId}&status=1&course_id=${courseId}&subject_id=${subjectId}&topic_id=${topicId}&complexity=${complexity}`);
  if (response.status === 200) {
    console.log(response,"thisis complexity response")
    return response.data.questions.map(question => question._id);
  }
  return [];
};

// Helper function to process assigned questions
export const processAssignedQuestions = async (autoAssign,assignedQuestions, selectedSection, selectedCourse, sectionValues, setSectionValues, user, selectedFirm) => {
  let temporary = [...sectionValues];
  temporary[selectedSection - 1].autoAssign = autoAssign;
  const complexityContaining_Q_Types = ['1','2','3'];
  for (const [currentQuestionType, questionTypeValue] of Object.entries(assignedQuestions)) {
    if (complexityContaining_Q_Types.includes(currentQuestionType)) {
      for (const [currentComplexity, complexityValue] of Object.entries(questionTypeValue)) {
        if (complexityValue.pick > 0) {
          const questionIds = await fetchAvailableQuestions(
            currentQuestionType,
            selectedCourse._id,
            temporary[selectedSection - 1].selectedSubject,
            temporary[selectedSection - 1].selectedTopic,
            currentComplexity,
            selectedFirm
          );
          temporary[selectedSection - 1].assignedQuestions[0][currentQuestionType][currentComplexity].question_ids = questionIds;
        }
      }
    }
  }
  setSectionValues(temporary);
};

// Function to process assigned questions based on question type
export const processAssignedQuestionsPick = (element, assignedQuestionsSchema) => {
    let assignedQuestions = JSON.parse(JSON.stringify(assignedQuestionsSchema));
    
    element.question_types.forEach((section) => {
      switch (section.question_type) {
        case 1:
        case 2:
        case 3: {
          assignedQuestions[0][section.question_type]['1'].pick = section.no_of_easy_questions;
          assignedQuestions[0][section.question_type]['2'].pick = section.no_of_medium_questions;
          assignedQuestions[0][section.question_type]['3'].pick = section.no_of_hard_questions;
          break;
        }
        case 4: {
          // Handle case 4 specifics
          break;
        }
        case 5: {
          // Handle case 5 specifics
          break;
        }
      }
    });
  
    return assignedQuestions;
  };

// Helper function to handle file uploads
export const emailsFileUploadHelper = (e, setFileData) => {
    const file = e.target.files[0];
  
    if (!file) return;
  
    const reader = new FileReader();
  
    reader.onload = (event) => {
      const result = event.target.result;
      let data = [];
  
      if (file.name.endsWith('.csv')) {
        // For CSV files
        data = result.split('\n').map(row => row.split(','));
        data = data.filter(email => email.length > 0);
      } else {
        // For Excel files
        const workbook = XLSX.read(result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        data = data.filter(email => email.length > 0);
        console.log(data, "file data");
      }
  
      setFileData(data);
    };
  
    if (file.name.endsWith('.csv')) {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };