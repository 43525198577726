import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import Context from '../../user_details';

const QuestionComplexityTable = ({sectionValues, sectionValueLoader, questionPickLoader, setQuestionsLoader, setCurrentComplexity, selectedSection, selectedCourse, setAvailableQuestions, questionsAvailabilityPerPick, selectedFirm}) => {
    const { user } = useContext(Context);
    const [insufficientPickMessage, setInsufficientPickMessage] = useState("Insufficient number of questions to pick");
    const [pickValidationLoader, setPickValidationLoader] = useState(true);

    useEffect(() => {
      const checkLoader = async() => {
        const isLoaded = Object.keys(questionsAvailabilityPerPick).every(
            key => {
              return questionsAvailabilityPerPick[key] != undefined
            }
          )
        
        setPickValidationLoader(!isLoaded);
      }
      checkLoader();
    }, [questionsAvailabilityPerPick])

    console.log(questionsAvailabilityPerPick,"here==<")
    let complexityContainingQuestionsTypes = [1,2,3];
    const getAssignedQuestionsNumber = (complexity = null) => {
        let questionType = sectionValues[selectedSection - 1].question_types[0].question_type;
        let assignedQuestions = sectionValues[selectedSection - 1].assignedQuestions;
        if (complexity) {
          return assignedQuestions[0][questionType][complexity].question_ids.length
        }
        else {
          return assignedQuestions[0][questionType].question_ids.length
        }
    }

    const verifyAssignedQuestionsNumber = () => {
        const targetSection = sectionValues[selectedSection - 1];
        return targetSection.assignedQuestions && targetSection.selectedSubject.length > 0 && targetSection.selectedTopic.length > 0 && Object.keys(selectedCourse).length > 0;
    }

    const getQuestionsList = async (complexity) => {
        try {
          setQuestionsLoader(true);
          setCurrentComplexity(complexity);
          let types = sectionValues[selectedSection - 1].question_types[0].question_type;
          let courseId = selectedCourse._id;
          let topicId = sectionValues[selectedSection - 1].selectedTopic;
          let subjectId = sectionValues[selectedSection - 1].selectedSubject;
          let allAvailableQuestions;
          if (complexity == '') {
            allAvailableQuestions = await axios.get(`${process.env.REACT_APP_API_URL}/question/list?types=${JSON.stringify([types])}&client_id=${selectedFirm}&status=1&course_id=${courseId}&subject_id=${subjectId}&topic_id=${topicId}`);
          }
          else {
            allAvailableQuestions = await axios.get(`${process.env.REACT_APP_API_URL}/question/list?types=${JSON.stringify([types])}&client_id=${selectedFirm}&status=1&course_id=${courseId}&subject_id=${subjectId}&topic_id=${topicId}&complexity=${complexity}`);
          }
    
          if (allAvailableQuestions.status == 200) {
            setQuestionsLoader(false);
            setAvailableQuestions(allAvailableQuestions.data.questions);
          }
          else {
            setQuestionsLoader(false);
            setAvailableQuestions([]);
          }
        }
        catch (e) {
          console.log(e)
        }
    }

   if (pickValidationLoader) {
    return (
      <div> Loading...</div>
    )
   } else {
    return (
      <table className='question-complexity-table'>
          <tbody>
              <tr>
              <th className=''> {complexityContainingQuestionsTypes.includes(sectionValues[selectedSection - 1].question_types[0].question_type) ? "Question Complexity" : ""}</th>
              <th className=" fw-bold text-white">Pick</th>
              <th className=" fw-bold text-white">Assigned</th>
              </tr>
          </tbody>
          {!sectionValueLoader && 
            !questionPickLoader && 
            complexityContainingQuestionsTypes.includes(sectionValues[selectedSection - 1].question_types[0].question_type)
            &&
              <tbody>
              <tr > 
                {/* Complexity */}
                  <td>Easy</td>

                {/*Pick  */}
                  <td>{sectionValues[selectedSection - 1].question_types[0].no_of_easy_questions}</td>

                {/* Assigned */}
                  <td>{verifyAssignedQuestionsNumber() ? getAssignedQuestionsNumber(1) : 0}
                    {!sectionValues[selectedSection - 1].autoAssign && questionsAvailabilityPerPick.easy.length >= sectionValues[selectedSection - 1].question_types[0].no_of_easy_questions &&
                  
                      <div className="btn btn-primary table-btn" data-bs-toggle="modal" data-bs-target="#assignmodel" onClick={() => getQuestionsList(1)}>assign</div>}
                  
                     {questionsAvailabilityPerPick.easy.length < sectionValues[selectedSection - 1].question_types[0].no_of_easy_questions && <p className='text-danger '>{insufficientPickMessage}</p>}
                  </td>
              </tr>
              <tr className=''>
                {/* Complexity */}
                  <td>Medium</td>

                  {/*Pick  */}
                  <td>{sectionValues[selectedSection - 1].question_types[0].no_of_medium_questions}</td>

                  {/* Assigned */}
                  <td>{sectionValues[selectedSection - 1].assignedQuestions ? getAssignedQuestionsNumber(2) : 0}{!sectionValues[selectedSection - 1].autoAssign && questionsAvailabilityPerPick.medium.length >= sectionValues[selectedSection - 1].question_types[0].no_of_medium_questions &&
                  
                  <div className="btn btn-primary table-btn" data-bs-toggle="modal" data-bs-target="#assignmodel" onClick={() => getQuestionsList(2)} >
                      assign
                  </div>}
                  {questionsAvailabilityPerPick.medium.length < sectionValues[selectedSection - 1].question_types[0].no_of_medium_questions&& <p className='text-danger '>{insufficientPickMessage}</p>}
                  </td>
              </tr>
              <tr className=''>
                  {/* Complexity */}
                  <td>Hard</td>

                  {/* Pick */}
                  <td>{sectionValues[selectedSection - 1].question_types[0].no_of_hard_questions}</td>
                  
                  {/* Assigned */}
                  <td>{sectionValues[selectedSection - 1].assignedQuestions ? getAssignedQuestionsNumber(3) : 0}{!sectionValues[selectedSection - 1].autoAssign && questionsAvailabilityPerPick.hard.length >= sectionValues[selectedSection - 1].question_types[0].no_of_hard_questions&&
                  
                  <div className="btn btn-primary table-btn" data-bs-toggle="modal" data-bs-target="#assignmodel" onClick={() => getQuestionsList(3)} >assign</div>}
                  {questionsAvailabilityPerPick.hard.length < sectionValues[selectedSection - 1].question_types[0].no_of_hard_questions && <p className='text-danger '>{insufficientPickMessage}</p>}
                  </td>
                   
              </tr>
              </tbody>

          }

          {sectionValues[selectedSection - 1].question_types[0].question_type == 5
              &&
              <tbody>
              <tr className=''>
                  <td> Minimum number of questions</td>
                  <td>{sectionValues[selectedSection - 1].question_types[0].no_of_questions}</td>
                  <td>{sectionValues[selectedSection - 1].assignedQuestions && getAssignedQuestionsNumber()}{!sectionValues[selectedSection - 1].autoAssign &&
                  <div className="btn btn-primary table-btn" data-bs-toggle="modal" data-bs-target="#assignmodel" onClick={() => getQuestionsList('')}>assign</div>}</td>
              </tr>
              </tbody>
          }
      </table>
  );
   }
}

export default QuestionComplexityTable;