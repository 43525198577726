import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StudentMarksBarChart = ({ barChartData }) => {

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { score, count } = payload[0].payload;
            return (
            <div className="custom-tooltip">
                <span className="">{`Marks: ${score}`}</span><br />
                <span className="">{`# of Students: ${count}`}</span>
            </div>
            );
        }
        return null;
        };

  return (
    <div >
      <h3>Distribution of Marks</h3>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={barChartData}>
          <XAxis dataKey="score" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StudentMarksBarChart;
