// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
/* width */
/* ::-webkit-scrollbar {
  width: 3px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 12px;
} */
 
/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #080672;
  border-radius: 12px; 
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE;;;;;;qBAMmB;AACrB;;AAEA;EACE;gBACc;AAChB;AACA,UAAU;AACV;;GAEG;;AAEH,UAAU;AACV;;;GAGG;;AAEH,WAAW;AACX;;;GAGG;;AAEH,oBAAoB;AACpB;;GAEG","sourcesContent":["body {\n  /* margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  overflow: hidden; */\n}\n\ncode {\n  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace; */\n}\n/* width */\n/* ::-webkit-scrollbar {\n  width: 3px;\n} */\n\n/* Track */\n/* ::-webkit-scrollbar-track {\n  background: #f1f1f1; \n  border-radius: 12px;\n} */\n \n/* Handle */\n/* ::-webkit-scrollbar-thumb {\n  background: #080672;\n  border-radius: 12px; \n} */\n\n/* Handle on hover */\n/* ::-webkit-scrollbar-thumb:hover {\n  background: #555; \n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
