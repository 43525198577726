exports.generateDefaultPermissionsDetails = async () => {
  try {
    const allPermissions = [
      {
        permission_type: "viewUsers",
        permission_id: 1
      },
      {
        permission_type: "createUsers",
        permission_id: 2
      },
      {
        permission_type: "editUsers",
        permission_id: 3
      },
      {
        permission_type: "deleteUsers",
        permission_id: 4
      },
      {
        permission_type: "viewGroups",
        permission_id: 5
      },
      {
        permission_type: "createGroups",
        permission_id: 6
      },
      {
        permission_type: "editGroups",
        permission_id: 7
      },
      {
        permission_type: "deleteGroups",
        permission_id: 8
      },
      {
        permission_type: "viewQuestions",
        permission_id: 9
      },
      {
        permission_type: "createQuestions",
        permission_id: 10
      },
      {
        permission_type: "editQuestions",
        permission_id: 11
      },
      {
        permission_type: "deleteQuestions",
        permission_id: 12
      },
      {
        permission_type: "viewCourses",
        permission_id: 13
      },
      {
        permission_type: "createCourses",
        permission_id: 14
      },
      {
        permission_type: "editCourses",
        permission_id: 15
      },
      {
        permission_type: "deleteCourses",
        permission_id: 16
      },
      {
        permission_type: "viewLearningOutcomes",
        permission_id: 17
      },
      {
        permission_type: "createLearningOutcomes",
        permission_id: 18
      },
      {
        permission_type: "editLearningOutcomes",
        permission_id: 19
      },
      {
        permission_type: "deleteLearningOutcomes",
        permission_id: 20
      },
      {
        permission_type: "viewTopics",
        permission_id: 21
      },
      {
        permission_type: "createTopics",
        permission_id: 22
      },
      {
        permission_type: "editTopics",
        permission_id: 23
      },
      {
        permission_type: "deleteTopics",
        permission_id: 24
      },
      {
        permission_type: "viewSubjects",
        permission_id: 25
      },
      {
        permission_type: "createSubjects",
        permission_id: 26
      },
      {
        permission_type: "editSubjects",
        permission_id: 27
      },
      {
        permission_type: "deleteSubjects",
        permission_id: 28
      },
      {
        permission_type: "viewExams",
        permission_id: 29
      },
      {
        permission_type: "createExams",
        permission_id: 30
      },
      {
        permission_type: "editExams",
        permission_id: 31
      },
      {
        permission_type: "deleteExams",
        permission_id: 32
      },
      {
        permission_type: "viewExamPatterns",
        permission_id: 33
      },
      {
        permission_type: "createExamPatterns",
        permission_id: 34
      },
      {
        permission_type: "editExamPatterns",
        permission_id: 35
      },
      {
        permission_type: "deleteExamPatterns",
        permission_id: 36
      },
      {
        permission_type: "viewSMTPSettings",
        permission_id: 37
      },
      {
        permission_type: "editSMTPSettings",
        permission_id: 38
      },
      {
        permission_type: "viewEmailTemplates",
        permission_id: 39
      },
      {
        permission_type: "editEmailTemplates",
        permission_id: 40
      },
      {
        permission_type: "viewRoles",
        permission_id: 41
      },
      {
        permission_type: "createRoles",
        permission_id: 42
      },
      {
        permission_type: "editRoles",
        permission_id: 43
      },
      {
        permission_type: "deleteRoles",
        permission_id: 44
      }
    ];

    return allPermissions;
  } catch (e) {
    console.log(e);
    return [];
  }

}

exports.modifyAndReturnPermissionIds = async (roles) => {
  let allPermissionIds = []
  await Promise.all(
    roles.map(async role => {
      await Promise.all(
        role.permissions_ids.map(permission => {
          allPermissionIds.push(permission.permission_id);
        })
      )
    })
  );
  return allPermissionIds;
}

exports.getPermissionIdByPermissionType = async(permissionType) => {
  try {
    if (!permissionType) {
      return null;
    } else {
      const allPermissions = await this.generateDefaultPermissionsDetails();
      const filteredPermission = await Promise.all(
        allPermissions.filter(permissionDetails => permissionDetails.permission_type === permissionType)
      )
      if (filteredPermission.length) {
        return filteredPermission[0].permission_id;
      } else {
        return null;
      }
    }
  } catch(e) {
    console.log(e);
    return null;
  } 
}

exports.getPermissionTypeByPermissionId = async(permissionId) => {
  try {
    if (!permissionId) {
      return null;
    } else {
      const allPermissions = await this.generateDefaultPermissionsDetails();
      const filteredPermission = await Promise.all(
        allPermissions.filter(permissionDetails => permissionDetails.permission_id === permissionId)
      )
      if (filteredPermission.length) {
        return filteredPermission[0].permission_type;
      } else {
        return null;
      }
    }
  } catch(e) {
    console.log(e);
    return null;
  } 
}