import {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom'
import Cookies from 'js-cookie';
import './create.css';
import Context from "../../user_details";
import {Modal} from 'react-bootstrap';
import { playSound } from '../../helpers/otherSounds';

const profile_create_form={
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password:"",
    user_type:"",
    created_by: "",
    firm_id:"",
}
function ProfileCreate(props){
    const [FormData, setFormData] = useState(profile_create_form);
    const [FirmsList, setFirmsList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [showGroupsModel, setGroupsModelVisibility] = useState(false);
    const [showRolesModel, setRolesModelVisibility] = useState(false);
    var {user} = useContext(Context);
    const navigate = useNavigate();

    useEffect(()=>{
       
        if(user.user_type === 'superAdmin'){
            get_firm_details();
        }
        else{
            set_form_data();
        }
    },[]);

    useEffect(()=>{
        console.log("=====================list firm ======================\n", FirmsList)
    },[FirmsList]);

    useEffect(()=>{
        console.log("=============================roles =====================\n", roles)
    },[roles]);

    useEffect(()=>{
        console.log("============================= selected roles =====================\n", selectedRoles)
    },[selectedRoles]);

    useEffect(()=>{
        console.log("============================= selected groups =====================\n", selectedGroups)
    },[selectedGroups]);

    useEffect(()=>{
        console.log("\n----------------form data-------------\n",FormData)
    },[FormData]);

    const set_form_data = async() => {
        setFormData({...FormData, created_by: user?._id, firm_id: user?.firm_id});
        getRolesOfFirm(user.firm_id);
        getGroupsofFirm(user.firm_id);
    }

    const getRolesOfFirm = async(firmId)=>{
        console.log("getting roles of ",firmId)
        const firm = await fetch(process.env.REACT_APP_API_URL+"/ums/roles/list/?firm_id="+firmId+`&type=superAdmin}`)
        .then(async res=>{
            const result = await res.json();
            console.log(result,"this is result")
            setRoles(result)
            return result
        })
        console.log(roles,"firm roles fetchd", firm)
    }

    const getGroupsofFirm = async(firmId) => {
        const firm = await fetch(process.env.REACT_APP_API_URL+"/ums/groups/list/?firm_id="+firmId+`&type=superAdmin}`)
        .then(async res=>{
            const result = await res.json();
            console.log(result,"this is result")
            setGroups(result)
            return result
        })
    }

    const create_profile=async()=>{
        if(FormData.password !== FormData.confirm_password){
            playSound('ERROR');
            alert("password must match")
        } else if(FormData.password.length < 8) {
            playSound('ERROR');
            alert("password must contain minimum of 8")
        }else {
            let input_verifier=true;
            for(let object of Object.keys(FormData)){
                console.log("checking", object, FormData[object], String(FormData[object]).length)
                if(String(FormData[object]).length === 0){
                    input_verifier=false;
                    playSound('ERROR');
                    alert("Please fill all the details to create profile");
                    break;
                }
                else{
                    console.log("passed", FormData[object]);
                }
            }
            if(input_verifier){
                await fetch(process.env.REACT_APP_API_URL+"/ums/create",{
                    method: "POST",
                    headers:{
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({...FormData,roles: selectedRoles, groups_assigned: selectedGroups,loggedin_user_firm_id: user.firm_id})
                })
                .then(async(res)=>{
                    const result = await res.json()
                    if(result.success){
                        playSound('SUCCESS');
                        var message = document.getElementById("success_message_user_create")
                        message.className = "success_message_user_create bg-success text-white p-4";
                        setTimeout(()=>{
                            message.className = "d-none";
                            window.location.href = "/users";
                        },1000)

                    }
                    else{
                        playSound('ERROR');
                        var message = document.getElementById("fail_message_user_create")
                        message.className = "fail_message_user_create bg-danger text-white p-4";
                        setTimeout(()=>{
                            message.className = "d-none";
                        
                        },1000)

                    }
                })
            }
        }
        
    }

    const InputChange=(event)=>{
        if(event.target.value){
            console.log("checking", event.target.name, event.target.value,event.target.name === 'firm_name')
        if ( event.target.name == 'firm_id') {
            getRolesOfFirm(event.target.value);
            getGroupsofFirm(event.target.value);
            setSelectedRoles([]);
        }
        setFormData({...FormData, [event.target.name]: event.target.value})
        }
    }
    const  get_firm_details = async() =>{
        const firmsList = await fetch(process.env.REACT_APP_API_URL+"/ums/firms/list")
        .then(async res=>{
            setFormData({...FormData, created_by: user?._id, firm_id: "",loggedin_user_firm_id: user.firm_id});                                
            const result = await res.json();
            setFirmsList(result.results);
            return result
        })                        
    }

    const changeSelectedRole = (roleId) => {
        console.log("changeing")
        if(selectedRoles.includes(roleId)){
            let selectedRolesArray = selectedRoles.filter(RoleId => RoleId!=roleId);
            setSelectedRoles(selectedRolesArray)
        }
        else{
            setSelectedRoles([...selectedRoles,roleId])
        }
    }
    const changeSelectedGroup = (groupId) => {
        if(selectedGroups.includes(groupId)){
            let selectedGroupsArray = selectedGroups.filter(GroupId => GroupId!=groupId);
            setSelectedGroups(selectedGroupsArray)
        }
        else{
            setSelectedGroups([...selectedGroups,groupId])
        }
    }

    return(
        <Context.Consumer>
            {value => {
                const {user} = value;
                return (
                    <div className="profile_create_container p-4">
                        <div className='general-add-form-container'>
                            <h3>Create User</h3>
                            <div className=''>
                                {/* <div className='d-flex align-items-center justify-content-center w-100 mb-3'>
                                    <img src={require("../../assets/NexusIQ-FinalVersion.jpg")} height="45rem"/>
                                </div> */}

                                <div className='container-fluid mt-3'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='row mb-2'>
                                                <div className='col-md-4'>
                                                    <span className='form_label'>First Name</span>
                                                </div>
                                                <div className='col-md-8'>
                                                    <input  className="input_element form_input_element" type="text" name="first_name" onChange={InputChange}/>
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-md-4'>
                                                    <span className='form_label'>Last Name</span>
                                                </div>
                                                <div className='col-md-8'>
                                                    <input  className="input_element form_input_element" type="text" name="last_name" onChange={InputChange}/>
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-md-4'>
                                                    <span className='form_label'>Email</span>
                                                </div>
                                                <div className='col-md-8'>
                                                    <input  className="input_element form_input_element" type="email" name="email" onChange={InputChange}/>
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-md-4'>
                                                    <span className='form_label'>Password</span>
                                                </div>
                                                <div className='col-md-8'>
                                                    <input  className="input_element form_input_element" type="password" name="password" onChange={InputChange}/>
                                                </div>
                                            </div>
                                            <div className='row mb-2'>
                                                <div className='col-md-4'>
                                                    <span className='form_label'>Confirm Password</span>
                                                </div>
                                                <div className='col-md-8'>
                                                    <input  className="input_element form_input_element" type="password" name="confirm_password" onChange={InputChange}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className='row mb-2'>
                                                <div className='col-md-4'>
                                                    <span className='form_label'>User Type</span>
                                                </div>
                                                <div className='col-md-8'>
                                                    <select className="input_element form_input_element " name="user_type" onChange={InputChange}>
                                                        <option value="" >Select User Type</option>
                                                        <option value="admin">Admin</option>
                                                        <option value="user">User</option>
                                                        <option value="student">Student</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                user?.user_type === 'superAdmin'?
                                                <div className="row mb-2">
                                                    <div className='col-md-4'>
                                                        <span className='form_label'>Firm Name</span>
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <select className="input_element form_input_element p-1" name="firm_id" onChange={InputChange}>
                                                            <option value="" className='text-secondary' >---Select a Firm---</option>
                                                            {FirmsList.map((firm,index)=>{
                                                                return <option value={firm._id} key={index}>{firm.firm_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                            : ""
                                            }

                                            <div className='row mb-2'>
                                                <div className='col-md-4'>
                                                    <p className='form_label mb-0'>Roles</p>
                                                </div>
                                                <div className='col-md-8'>
                                                    <div className='btn btn-primary form_input_element profile_container view-edit-btn' onClick={() => {setRolesModelVisibility(true)}} >View | Edit</div>
                                                    <Modal show={showRolesModel}>
                                                        <Modal.Title className='text-secondary border-bottom border-muted border-1 p-3'>
                                                            Roles
                                                        </Modal.Title>
                                                        <Modal.Body className='border-bottom border-muted border-1 text-secondary'>
                                                            {
                                                                roles.length > 0 ?
                                                                roles.map((role,index)=>{
                                                                    return(
                                                                        <div key={index} className='d-flex justify-content-start '>
                                                                            <input type="checkbox" className='m-0' 
                                                                                style={{width: '15px'}}
                                                                                onChange={()=>{changeSelectedRole(role.role._id)}}
                                                                                checked={selectedRoles.includes(role.role._id)}
                                                                            />
                                                                            <span style={{marginLeft:'5px'}}>{role.role.role_name}</span>
                                                                        </div>
                                                                    )
                                                                }): <div className='py-5 text-center'>No Roles are available</div>
                                                            }
                                                        </Modal.Body>
                                                        <Modal.Footer className='d-flex justify-content-end gap-2'>
                                                            <div className='btn btn-danger' onClick={() => { setSelectedRoles([]); setRolesModelVisibility(false) }}>Cancel</div>
                                                            <div className='btn btn-primary' onClick={() => { setRolesModelVisibility(false) }}>Save</div>
                                                        </Modal.Footer>
                                                    </Modal>
                                                    {}
                                                </div>
                                            </div>

                                            <div className='row mb-3'>
                                                <div className='col-md-4'>
                                                    <p className='form_label'>Groups</p>
                                                </div>
                                                <div className='col-md-8'>
                                                    <div className='btn btn-primary form_input_element profile_container view-edit-btn' onClick={() => {setGroupsModelVisibility(true)}} >View | Edit</div>
                                                    <Modal show={showGroupsModel}>
                                                        <Modal.Title className='text-secondary border-bottom border-muted border-1 p-3'>
                                                            Groups
                                                        </Modal.Title>
                                                        <Modal.Body className='border-bottom border-muted border-1 text-secondary'>
                                                            {
                                                                groups.length > 0? 
                                                                groups.map((group, index) => {
                                                                    return (
                                                                        <div key={index} className='d-flex justify-content-start'>
                                                                            <input type="checkbox" className='m-0'
                                                                                style={{ width: '15px' }}
                                                                                onChange={() => { changeSelectedGroup(group.group._id) }} 
                                                                                checked={selectedGroups.includes(group.group._id)}    
                                                                            />
                                                                            <span style={{ marginLeft: '5px' }}>{group.group.name}</span>
                                                                        </div>
                                                                    )
                                                                }): <div className='py-5 text-center'>No Groups are available</div>
                                                            }
                                                        </Modal.Body>
                                                        <Modal.Footer className='d-flex justify-content-end gap-2'>
                                                            <div className='btn btn-danger' onClick={() => {setSelectedGroups([]); setGroupsModelVisibility(false)}}>Cancel</div>
                                                            <div className='btn btn-primary' onClick={() => {setGroupsModelVisibility(false)}}>Save</div>
                                                        </Modal.Footer>
                                                    </Modal>
                                                    
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <input type="button" className="user_create_button mt-4" value="Create User" onClick={create_profile}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-none' id="success_message_user_create">User Created Successfully !</div>
                        <div className='d-none' id="fail_message_user_create">User Created failed !</div>
                </div>
                )
            }}
        </Context.Consumer>
    )
}
export default ProfileCreate