import { useState, useEffect, useContext } from 'react';
import Context from '../../user_details';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './smtpSettings.scss';
import './smtpSettings.css';
import smtpInfoDocx from '../../assets/smtpInfoDocumentation.docx'
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { playSound } from '../../helpers/otherSounds';


export default function SMTPSettings() {
    const { user } = useContext(Context);
    const [show, setShow] = useState(false);
    const [postSaveMessage, setPostSaveMessage] = useState("");
    const [postSaveMessageClass, setClass] = useState("AppAuthSaveSuccess");
    const [smtpType, setSmtpType] = useState('');
    const [loader, setLoader] = useState(true);
    const cryptgraphySecretKey = process.env.REACT_APP_UMS_SECRET_KEY;
    const [smtpEmail, setSMTPEmail] = useState("");
    const [smtpHost, setSMTPHost] = useState("");
    const [smtpPassword, setSMTPPassword] = useState("");
    const [senderEmail, setSenderEmail] = useState("");
    const [smtpUser, setSMTPUser] = useState("");
    const [smtpAuth, setSMTPAuth] = useState("");
    const hostDependentServices = ["aws"];
    const navigate = useNavigate();

        useEffect(() => {
        getSmtpInfo();
    }, []);

    // useEffect(() => {
    //     if (hostDependentServices.includes(smtpType)) {
    //         setSMTPHost("");
    //     }
    // }, [smtpType])

    useEffect(() => {
        // if (smtpEmail.length > 0 && smtpPassword.length > 0) {
        //     setLoader(false);
        // }
    }, [smtpEmail, smtpPassword])

    useEffect(() => {
        if (postSaveMessage.length > 0) {
            setShow(true);
        }
        else {
            setShow(false);
        }
    }, [postSaveMessage]);

    const getSmtpInfo = async () => {
       try {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/ums/getCompanySMTPAvailability/${user.firm_id}`)
        if (result.data.success) {
            let { SMTP_Email, SMTP_AUTH, SMTP_User, SMTP_domain, SMTP_host } = result.data;
            setSMTPEmail(CryptoJS.AES.decrypt(SMTP_Email, cryptgraphySecretKey).toString(CryptoJS.enc.Utf8));
            setSMTPUser(CryptoJS.AES.decrypt(SMTP_User, cryptgraphySecretKey).toString(CryptoJS.enc.Utf8));
            setSmtpType(CryptoJS.AES.decrypt(SMTP_domain, cryptgraphySecretKey).toString(CryptoJS.enc.Utf8));
            setSMTPHost(CryptoJS.AES.decrypt(SMTP_host, cryptgraphySecretKey).toString(CryptoJS.enc.Utf8));
            setSMTPAuth(CryptoJS.AES.decrypt(SMTP_AUTH, cryptgraphySecretKey).toString(CryptoJS.enc.Utf8));
            setLoader(false); 
        }
        else {
            setLoader(false);
        }
       }
       catch(e) {
        setLoader(false);
        alert("something went wrong!! please try again!!")
       }
    }

    const handleSubmit = async (e) => {
        const hasPermission = await user.permissionTypes.includes("createRoles");
    if ( hasPermission) {
        e.preventDefault();
        let smtpUser = document.getElementById("SMTP-User").value;
        let authPass = document.getElementById("SMTP_PASS_KEY").value;
        smtpUser = CryptoJS.AES.encrypt(smtpUser, cryptgraphySecretKey).toString();
        authPass = CryptoJS.AES.encrypt(authPass, cryptgraphySecretKey).toString();
        if (smtpUser.length > 0 && authPass.length > 0 && smtpType.length > 0) {
            try {
                setLoader(true);
                const result = await axios.post(`${process.env.REACT_APP_API_URL}/ums/updateCompanySMTPDetails/${user.firm_id}`, {
                    SMTP_User: smtpUser,
                    SMTP_AUTH: authPass,
                    SMTP_domain: smtpType,
                    SMTP_host: smtpHost,
                    SMTP_Email: smtpEmail
                })

                if (result.data.success) {
                    playSound('SUCCESS');
                    setClass("AppAuthSaveSuccess");
                    setPostSaveMessage(`✓ ${result.data.message}`);
                    setTimeout(() => {
                        window.location.href = "/"
                        setPostSaveMessage("");
                    }, 1500);
                }
                else {
                    playSound('ERROR');
                    setClass("AppAuthSaveFail");
                    setPostSaveMessage(`✕ ${result.data.message}`);
                    setTimeout(() => {
                        setPostSaveMessage("");
                    }, 1500);
                }
                setLoader(false);
            }
            catch (e) {
                console.log(e);
                playSound('ERROR');
                setClass("AppAuthSaveFail");
                setPostSaveMessage(`✕ Something is wrong`);
                setTimeout(() => {
                    setPostSaveMessage("");
                }, 1500);
                setLoader(false);
            }
        }
        else {
            playSound('ERROR')
            setClass("AppAuthSaveFail");
            setPostSaveMessage(`✕ Something is wrong`);
            setTimeout(() => {
                setPostSaveMessage("");
            }, 1500);
        }}
    }
    if (!loader) {
        return (
        <div className='smtp_settings_container'>
            <div class="row align-items-center download_smtp_file_container">
                <div class="col-sm-4">
                <div class="page-header-container">
                    <h2 class="page-main-header">SMTP Settings</h2>
                </div>
                </div>
                <div class="col-sm-8 text-end"> 
                    <span className='download_smtp_info_file'>
                        <svg xmlns="http://www.w3.org/2000/svg"  
                            width="26" height="26" fill="currentColor" 
                            class="bi bi-file-earmark-text-fill" viewBox="0 0 16 16">
                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z"/>
                        </svg>
                        <a download="smtpInfoCredentials" href={smtpInfoDocx}>
                            How do I find my SMTP authentication details ? 
                        </a>
                    </span>
                </div>
            </div>
            <div className='row'>
                <div class="col-sm-12"> <p> &nbsp; </p> </div>
            </div>

            <div className='smtp_settings_elements general-add-form-container'>
                <h3>SMTP Settings</h3>
                <form onSubmit={handleSubmit} className='h-100'>
                    <div className='form-group'>
                        <label><strong>SMTP username:</strong></label>
                        <input type="text" defaultValue={smtpUser} onChange={(e) => {setSMTPUser(e.target.value)}} id="SMTP-User" required />
                    </div>
                    
                    <div className='form-group'>
                        <label><strong>App Auth Pass Key</strong></label>
                        <input type="password" 
                            placeholder='App Auth Pass Key of respective Email Address Account' 
                            defaultValue={smtpAuth} 
                            onChange={(e) => {setSMTPAuth(e.target.value)}} 
                            id="SMTP_PASS_KEY" required />
                    </div>
                
                    <div className='form-group'>
                        <label><strong>SMTP service host type</strong></label>
                        <select class="form-select" value={smtpType} onChange={(e) => setSmtpType(e.target.value)} required>
                            <option value="">Select SMTP Service</option>
                            <option value="aws">AWS</option>
                            <option value="gmail">Gmail</option>
                            <option value="outlook">Outlook</option>
                            {/* Add other SMTP services as needed */}
                        </select>
                        {hostDependentServices.includes(smtpType) &&
                           
                        <div className='level2_elemnts'>
                            <div>
                                <label> &nbsp; </label>
                                <input type="text"  className='input_field'
                                placeholder='Enter the Host Region Name'
                                    value={smtpHost} 
                                    onChange={(e) => {setSMTPHost(e.target.value) }} />
                              </div>  
                        </div>
                        }
                    </div>

                    <div className='form-group'>
                        {/* <label><strong>Enter the Email address used as sendder adddress: </strong></label> */}
                        <label><strong>Sender's Email Adddress </strong></label>
                        <input 
                            type="email" 
                            placeholder='The "From" email address used to send emails to recipients' 
                            defaultValue={smtpEmail} 
                            onChange={(e) => {setSMTPEmail(e.target.value)}}/>
                    </div>

                    <div className='form-group'>
                       {user.permissionTypes.includes("editSMTPSettings")&& <button className='add-form-btn' type="submit">Save</button>}
                        <Offcanvas show={show} onHide={() => { setShow(false) }} placement='end' style={{ height: "fitContent" }}>
                            <Offcanvas.Body className={postSaveMessageClass}>
                                {postSaveMessage} 
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>

                </form>
            </div>
        </div>
        )
    }
    else {
        return (
            <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
                <div className="text-center text-primary fw-bolder fs-2">
                    <div className="spinner-border" role="status"></div>
                </div>
            </div>
        )
    }

}