import React, { useState, useEffect, useContext , createContext } from "react";
import axios from "axios";
import Context from '../../user_details';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import QuestionGeneratorForm from '../QuestionGeneratorForm/QuestionGeneratorForm';
import AnswerOptions from './AnswerOptions';

export default function QuestionForm({ onQuestionAdded, SelectedQuestion , cancelBtnHandler }) {
  const context = useContext(Context);
  const [questionObj, setQuestionObj] = useState({
    question: "",
    complexity: "",
    marks: "",
    questionType: "",
    answers: [{ answer: "", is_correct: false , is_deleted : false }],
    selectedClient: context.user.user_type === "superAdmin" ? "" : context.user.firm_id,
    selectedCourse: "",
    selectedSubject: "",
    selectedTopic: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [clients, setClients] = useState([]);
  const [formType,setFormType] = useState('Add');
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showFormContent, setShowFormContent] = useState(true);
  const [isPreview, setIsPreview] = useState(false);

  const quillModules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['code'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    }
  };

  const quillFormats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const togglePreview = () => {
    setIsPreview(!isPreview);
  };

  const stripPTags = (html) => {
		let sanitizedHtml = DOMPurify.sanitize(html);
		sanitizedHtml = sanitizedHtml.replace(/<\/?p[^>]*>/g, "");
		return sanitizedHtml;
	};

  useEffect(() => {
    if (SelectedQuestion) {
      setQuestionObj({
        ...SelectedQuestion,
        selectedClient : SelectedQuestion?.client_id?._id,
        selectedCourse: SelectedQuestion?.course_id?._id,
        selectedSubject: SelectedQuestion?.subject_id?._id,
        selectedTopic: SelectedQuestion?.topic_id?._id,
        questionType: SelectedQuestion?.question_type,
      });
      setFormType('Update');
      if(SelectedQuestion?.client_id?._id) fetchClients();
      if (SelectedQuestion?.course_id?._id) fetchSubjects(SelectedQuestion?.course_id?._id);
      if (SelectedQuestion?.subject_id?._id) fetchTopics(SelectedQuestion?.subject_id?._id);
    }
  }, [SelectedQuestion]);

  useEffect(() => {
    if(context.user.user_type === "superAdmin") {
      fetchClients();
    }
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
      if (response.data.success && response.data.results.length) {
        setClients(response.data.results);
      } else {
        setErrorMessage("Clients data not available. You may need to create Clients.");
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/list/${context.user.firm_id}`);
      if(response.data && response.data.result.courses) {
        setCourses(response.data.result.courses);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchSubjects = async (courseId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/subjects/list/${courseId}`);
      setSubjects(response.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const fetchTopics = async (subjectId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/topics/list/${subjectId}`);
      console.log(response);
      console.log(response.data);
      setTopics(response.data.result);
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  };

  const handleClientChange = async (e) => {
		var clientId = e.target.value;
		setQuestionObj((prevState) => {
			return {
				...prevState,
				selectedClient: clientId,
				selectedCourse: "",
				selectedSubject: "",
				selectedTopic: "",
			};
		});
		// Fetch courses based on the selected client
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/clientId/${clientId}`);
			if (response.data && response.data.courses && response.data.courses.length > 0) {
				setCourses(response.data.courses);
				setErrorMessage("");
			} else {
				setErrorMessage("Courses data not available for the selected CLient. You may need to create Courses for the selected Client.");
			}
		} catch (error) {
			console.error("Error fetching courses:", error);
		}
	};
	
	const handleCourseChange = (e) => {
		const courseId = e.target.value;
		setQuestionObj((prevState) => {
			return {
				...prevState,
				selectedCourse: courseId,
				selectedSubject: "",
				selectedTopic: "",
			};
		});
		fetchSubjects(courseId);
	};

	const handleSubjectChange = (e) => {
		const subjectId = e.target.value;
		setQuestionObj((prevState) => {
			return {
				...prevState,
				selectedSubject: subjectId,
				selectedTopic: "",
			};
		});
		fetchTopics(subjectId);
	};

	const handleAnswerChange = (index, newValue, name) => {
		setQuestionObj((prevState) => {
			let updatedAns = [...prevState.answers];
			// if (prevState.questionType == "1"  || prevState.questionType == "2")
			if (name === "is_correct" && (prevState.questionType == "1" || prevState.questionType == "2")){
        		updatedAns = updatedAns.map((d,i) => ({
						...d,
						is_correct: i === index ? newValue : false,
					}));
				
			}
			updatedAns[index][name] = newValue;
			return {
				...prevState,
				answers: updatedAns,
			};
		});
	};

	const addAnswer = () => {
		setQuestionObj((prevState) => {
			return {
				...prevState,
				answers: [...prevState.answers, { answer: "", is_correct: false, is_deleted: false }],
			};
		});
	};

	const removeAnswer = (index) => {
		setQuestionObj((prevState) => {
			let updatedAns = [...prevState.answers];
			updatedAns[index]["is_deleted"] = true;
			return {
				...prevState,
				answers: updatedAns,
			};
		});
	};

	const validateTrueFalseSelection = () => {
		if (questionObj.questionType === "2") {
			const istrueorFalseSelected = questionObj.answers.some(answer => answer.is_correct);
			if (!istrueorFalseSelected) {
				alert("Please select Atleast One Correct Answer.");
				return false;
			}
		}
		return true;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!validateTrueFalseSelection()) return;

    if(questionObj.question == undefined || questionObj.question == "") {
			setErrorMessage('Please enter Question content.');
			return false;
		}
    
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/question/create`, {
				question: stripPTags(questionObj.question),
				complexity: questionObj.complexity,
				marks: questionObj.marks,
				question_type: questionObj.questionType,
				client_id: questionObj.selectedClient,
				course_id: questionObj.selectedCourse,
				subject_id: questionObj.selectedSubject,
				topic_id: questionObj.selectedTopic,
				answers: questionObj.answers.map((answer) => ({
					answer: answer.answer,
					is_correct: answer.is_correct,
					is_deleted: answer.is_deleted,
				})),
			});
			console.log("New question added:", response.data);
			setQuestionObj({
				question: "",
				complexity: "",
				marks: "",
				questionType: "",
				answers: [{ answer: "", is_correct: false, is_deleted: false }],
				selectedCourse: "",
				selectedSubject: "",
				selectedTopic: "",
			
			});
			setErrorMessage("");
			// Notify the parent component that a new question has been added
			onQuestionAdded();
		} catch (error) {
			console.error("Error adding question:", error);
			setErrorMessage("Failed to add question. Please try again.");
		}
	};

	const updateHandler = async (event) => {
    event.preventDefault();
	if (!validateTrueFalseSelection()) return;
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/question/update/${questionObj._id}`, {
        selectedCourse: questionObj.selectedCourse,
        selectedSubject: questionObj.selectedSubject,
        selectedTopic: questionObj.selectedTopic,
        question: stripPTags(questionObj.question),
        complexity: questionObj.complexity,
        marks: questionObj.marks,
        question_type: questionObj.questionType, // This is where the question type is being sent for update
        course_id: questionObj.selectedCourse,
        subject_id: questionObj.selectedSubject,
        topic_id: questionObj.selectedTopic,
        answers: questionObj.answers.map((answer) => ({
          answer: answer.answer,
          is_correct: answer.is_correct,
          question_id: questionObj._id,
          _id: answer._id,
          is_deleted: answer.is_deleted,
        })),
		deletedAnswers : questionObj.deletedAnswers.map(d => d._id)
      });
      console.log("question updated:", response.data);
      // Clear form fields after successful submission
      setQuestionObj({
        question: "",
        complexity: "",
        marks: "",
        questionType: "", // You're resetting questionType here, which might be causing the issue
        answers: [{ answer: "", is_correct: false, is_deleted: false }],
        selectedCourse: "",
        selectedSubject: "",
        selectedTopic: "",
      });
      setErrorMessage("");
      // Notify the parent component that a new question has been added
      onQuestionAdded();
    } catch (error) {
      console.error("Error Update question:", error);
      setErrorMessage("Failed to Updated question. Please try again.");
    }
    };
  
	const handleFileChange = (e) => {   
		setSelectedFile(e.target.files[0]);
	};

	const handleFileUpload = async () => {
		if (!selectedFile) {
			setErrorMessage("Please select a file to upload.");
			return;
		}
		var formData = new FormData();
		formData.append("pdfFile", selectedFile);
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/question/question-generator`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			console.log("File uploaded successfully:", response.data);
			setSelectedFile(null);
			setErrorMessage("");
			onQuestionAdded();
		} catch (error) {
			console.error("Error uploading file:", error);
			setErrorMessage("Failed to upload file. Please try again.");
		}
	};

	const ShowFileUpload = () => {
		setShowFileUpload((prevShowFileUpload) => !prevShowFileUpload);
		setShowFormContent(false);
	};

	const QuestionForm = () => {
		setShowFormContent(!showFormContent);
		setShowFormContent(true);
	};

	const questionTypeChangeHandler = (e) => {
		setQuestionObj((prevState) => {                  
			let prevAnsListToDelete = [];
			let newAnsList = [];
			 if (e.target.value == "2") {			   
			   newAnsList = [
				 { answer: "True", is_correct: false, is_deleted: false },
				 { answer: "False", is_correct: false, is_deleted: false },
			   ];
			 } else {			   
			   newAnsList = [{ answer: "", is_correct: false, is_deleted: false }];
			 }
			 var existingRecords = prevState.answers.filter(a => a.created || a.created != null);
			 if(existingRecords.length > 0){
				prevAnsListToDelete = existingRecords.map(d => { 
					return {
						...d,
                        questionType : prevState.questionType
					}
				});
			 }			 
	         return {
				...prevState,                              
				questionType: e.target.value,
				answers: newAnsList,
				deletedAnswers :  prevState.deletedAnswers?.length > 0 ? prevState.deletedAnswers : prevAnsListToDelete
			};
		})
	};
	const handleQuestionChange = (value) => {
		setQuestionObj((prevState) => {
		  return {
			...prevState,
			question: value,
		  };
		});
	  };

  

  return (
		<div className="add-questions-form general-add-form-container add-question-form-container">
			<button className="ms-3" onClick={QuestionForm}>
				{formType} New Question{" "}
			</button>
			<button className="ms-3" onClick={ShowFileUpload}>
				{formType} Attachment File{" "}
			</button>
			{!showFormContent && (
				<div className="row">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-6">
								<div className="mb-3 mt-3 ms-3 row">
									{/* Client details */}
									<div className="file-upload-container">
										<label htmlFor="file-upload" className="btn btn-primary">
											Upload File
										</label>
										<input id="file-upload" type="file" accept=".pdf" style={{ display: "none" }} onChange={handleFileChange} />
										{selectedFile && (
											<button className="btn btn-secondary" onClick={handleFileUpload}>
												Submit File
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{showFormContent && (
				<div className="row">
					<div className="col-md-12">
						<form onSubmit={handleSubmit}>
							<div className="row">
								<div className="col-md-6">
									{context.user.user_type === "superAdmin" && (
										<div className="mb-3 mt-3 ms-3 row">
											{/* Client details */}
											<div className="col-md-12">
												<label htmlFor="client" className="form-label">
													Select Client:
												</label>
												<select id="client" className="form-select" value={questionObj.selectedClient} onChange={handleClientChange} required>
													<option value="">Select Client</option>
													{clients.map((client) => (
														<option key={client._id} value={client._id}>
															{client.firm_name}
														</option>
													))}
												</select>
											</div>
										</div>
									)}
									<div className="mb-3 ms-3 row">
										{/* Course details */}
										<div className="col-md-6">
											<label htmlFor="course" className="form-label">
												Select Course:
											</label>
											<select id="course" className="form-select" value={questionObj.selectedCourse} onChange={handleCourseChange} required>
												<option value="">Select Course</option>
												{Array.isArray(courses) &&
													courses.map((course) => (
														<option key={course._id} value={course._id}>
															{course.course_name}
														</option>
													))}
											</select>
										</div>

										{/* Subject details */}
										<div className="col-md-6">
											<label htmlFor="subject" className="form-label">
												Select Subject:
											</label>
											<select id="subject" className="form-select" value={questionObj.selectedSubject} onChange={handleSubjectChange} required>
												<option value="">Select Subject</option>
												{Array.isArray(subjects) &&
													subjects.map((subject) => (
														<option key={subject._id} value={subject._id}>
															{subject.subject_name}
														</option>
													))}
											</select>
										</div>
									</div>

									<div className="mb-3 ms-3 row">
										{/* Topic details */}
										<div className="col-md-6">
											<label htmlFor="topic" className="form-label">
												Select Topic:
											</label>
											<select
												id="topic"
												className="form-select"
												value={questionObj.selectedTopic}
												onChange={(e) =>
													setQuestionObj((prevState) => ({
														...prevState,
														selectedTopic: e.target.value,
													}))
												}
												required>
												<option value="">Select Topic</option>
												{Array.isArray(topics) &&
													topics.map((topic) => (
														<option key={topic._id} value={topic._id}>
															{topic.topic_name}
														</option>
													))}
											</select>
										</div>

										{/* Complexity details */}
										<div className="col-md-6">
											<label htmlFor="complexity" className="form-label">
												Complexity:
											</label>
											<select
												id="complexity"
												className="form-select"
												value={questionObj.complexity}
												onChange={(e) => {
													const complexityValue = e.target.value;
													setQuestionObj((prevState) => {
														let marks = 0;
														switch (complexityValue) {
															case "1":
																marks = 1;
																break;
															case "2":
																marks = 2;
																break;
															case "3":
																marks = 4;
																break;
															default:
																marks = 0;
																break;
														}
														return { ...prevState, complexity: complexityValue, marks: marks };
													});
												}}
												required
											>
												<option value="">Select Complexity</option>
												<option value="1">Easy</option>
												<option value="2">Medium</option>
												<option value="3">Difficult</option>
											</select>
										</div>
									</div>
									<div className="mb-3 ms-3 row">
										{/* Marks details */}
										<div className="col-md-6">
											<label htmlFor="marks" className="form-label">
												Marks:
											</label>
											<input
												id="marks"
												type="number"
												className="form-control"
												value={questionObj.marks}
												readOnly
											/>
										</div>
										{/* 'Question Type' details */}
										
										<div className="col-md-6">
											<label htmlFor="questionType" className="form-label">
												Question Type:
											</label>
											<select
												id="questionType"
												className="form-select"
												value={questionObj.questionType}
												onChange={(e) =>  questionTypeChangeHandler(e)}
												required>
												<option value="">Select Question Type</option>
												<option value="1">Multiple Choice</option>
												<option value="2">True / False</option>
												<option value="3">Check Boxes</option>
												<option value="4" disabled>
													Comprehensive
												</option>
												<option value="5" disabled>
													File Upload
												</option>
											</select>
										</div>
									</div>
								</div>
								<div className="col-md-6">
                    				<div className="mb-3 me-3 mt-3 row">
                      					{/* QUESTION details */}
										<div className=" col-md-12">
											<div className="col-md-12">
											<label htmlFor="question" className="form-label">Question:</label>
											<button className="float-end" type="button" onClick={togglePreview}>
												{isPreview ? 'Edit' : 'Preview'}</button>
											</div>         
											<div className=" row h-100">
											{isPreview ? (
												<div
												className="preview"
												dangerouslySetInnerHTML={{ __html: stripPTags(questionObj.question) }}
												/>
											) : (
											<ReactQuill placeholder={'Enter question text...'} 
												theme="snow" value={questionObj.question} 
												onChange={handleQuestionChange} 
												modules={quillModules} 
												formats={quillFormats}
												className=""
											  />
											)}
											</div>                        
										</div>
										{/* IS CORRECT ANSWER details */}
										
									</div>
									
								</div>
								<div className="row">
											{/* =========  IS Correct ANSWER Code Starts here ============= */}
									<div className="col-6"></div>
									<div className="col-6">
										<AnswerOptions 
										questionObj={questionObj}
										handleAnswerChange={handleAnswerChange}
										addAnswer={addAnswer}
										removeAnswer={removeAnswer}
										
										/>
										
									</div>
											{/* =========  IS Correct ANSWER Code Ends here ============= */}
								</div>
								<div className="col-md-12">
									<div className="add-cancel-btn-container">
										{formType === "Add" ? (
											<button type="submit" className="add-form-btn">
												{formType} Question
											</button>
										) : (
											<button onClick={updateHandler} className="add-form-btn">
												{formType} Question
											</button>
										)}

										<button type="button" className="cancel-form-btn" onClick={cancelBtnHandler}>
											Cancel
										</button>
									</div>
								</div>
							</div>
						</form>
						{errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
					</div>
				</div>
			)}
		</div>
		// =========  Edited Code Ends here =============
	);
}
