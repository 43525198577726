import { useEffect, useState, useContext } from 'react';
import Context from '../../user_details';
import axios from 'axios';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './emailTemplate.scss';
import './emailTemplate.css';
import SpinnerLoader from '../../helpers/spinner-loader';

export default function EmailTemplates() {
    const [templateList, setTemplateList] = useState([]);
    const [tempateListLoader, setTemplateListLoader] = useState(true);
    const [edit, setEdit] = useState(false);
    const { user } = useContext(Context);
    const [editLoader, setEditLoader] = useState(false);
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [variables, setVariables] = useState([]);
    const [templateType, setTemplateType] = useState("");
    const [showVariables, setShowVariables] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    
    useEffect(() => {
        if (templateList.length === 0) {
            getTemplateList();
        }
        else {
            console.log(templateList, "list of templates")
            setTemplateListLoader(false);
        }
    }, [templateList]);

    useEffect(() => {
        if(body.length > 0 && variables.length > 0 && subject.length > 0 && templateType.length > 0 && editLoader === true) {
            setEditLoader(false);
        }
    }, [body, subject,variables])

    const getTemplateList = async () => {
        try {
            const list = await axios.get(`${process.env.REACT_APP_API_URL}/ums/getDefaultTemplates`);
            if (list.data.success) {
                setTemplateList(list.data.result);
            }
            else {
                throw new Error("Cant get Templates at this moment");
            }
        }
        catch (e) {
            alert(e.message)
            console.log(e)
        }
    }

    const getSelectedTemplateDetails = async (templateType) => {
        if(user.permissionTypes.includes("viewEmailTemplates")){
        setEditLoader(true);
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/ums/getEmailTemplatesByFirmId/${user.firm_id}?template_type=${templateType}`);
        if (result.data.success === true) {
            setBody(result.data.result[0].body);
            setSubject(result.data.result[0].subject);
            setVariables(result.data.result[0].variables);
            setTemplateType(result.data.result[0].template_type);
        }}
    }

    const resetDefaultTemplate = async () => {
        try {
            const result = await axios.delete(`${process.env.REACT_APP_API_URL}/ums/deleteClientEmailTemplate?template_type=${templateType}&client_id=${user.firm_id}`);
            console.log(result)
        }
        catch(e) {
            console.log(e)
        }
    }

    const updateClientTemplate = async() => {
       try {
        const result = await axios.post(`${process.env.REACT_APP_API_URL}/ums/updateClientTemplateByFirmId/${user.firm_id}`,{body,subject,template_type: templateType})
        console.log(result);
       }
       catch(e) {
        console.log(e)
       }
    }

    if (!tempateListLoader) {
        return (
            <div className='h-100 exam-patterns-container emailTemplatesContainer'>
                <div className='row'>
                    <div class="col-sm-12">
                        <div class="page-header-container">
                            <h2 class="page-main-header">Email Templates</h2>
                        </div>
                        <br />
                    </div>
                </div>
                <table className='w-100 emailTemplates_table' style={{ }}>
                    <thead className='w-100 bg-primary text-white fs-5 text-center'>
                        <tr className='emailTemplates_table-header'>
                            <th className='p-2' colSpan={'2'}>Email Templates List</th>
                        </tr>
                    </thead>
                    <tbody className=' ' style={{overflowY:'auto'}}>
                        {templateList.length === 0 ?
                          <tr></tr>
                            :
                         templateList.map((template,index) => {
                            return (
                               
                                <tr className="emailTemplate_table-content " key={index}>
                                    <td className='template_name-info'>
                                        {template.template_name}
                                    </td>
                                    <td className='template_view_edit text-center'>
                                       { user.permissionTypes.includes("viewEmailTemplates")&&<div className="btn btn-primary emailTemplate_view" 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#emailTemplateEditModal" 
                                            onClick={(e) => {getSelectedTemplateDetails(template.template_type)}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                            </svg>
                                             View | Edit
                                        </div>}
                                    </td>
                                </tr>
                               
                            )
                         })
                        }
                    </tbody>
                </table>
                {/* ----------------Template Edit modal ------------------------- */}
                <div class="modal fade " id="emailTemplateEditModal" zIndex="2000" tabindex="-1" aria-labelledby="emailTemplateEditModalLabel" aria-hidden="true">
                    <div class="modal-dialog w-100" >
                        <div class="modal-content" >
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="emailTemplateEditModalLabel">Edit Email Template</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                               <div className=' d-flex flex-column gap-2'>
                                    <div className='fs-5 fw-bold border-bottom border-2'> Subject </div>
                                    <div> 
                                        <input className="px-3  w-100" type="text" value={subject} onChange={(e) => {setSubject(e.target.value)}}/>
                                    </div>
                                </div>
                               <div className="d-flex flex-column w-100 h-75">
                                    <div className='my-3 p-2 d-flex justify-content-between border-bottom border-2'> 
                                        <div className='p-2 fs-5 fw-bold '>Body</div> 
                                        <div className='d-flex gap-2'>
                                            <button className='btn btn-outline-primary' onClick={() => {setShowVariables(true)}}>View Usable Variables</button>
                                            <button className='btn btn-primary'  onClick={() => {setShowPreview(true)}}>Preview</button>
                                        </div> 
                                    </div>
                                    <div className='w-100 h-75'>
                                        <textarea className='w-100 h-100' autosize value={body} onChange={(e) => {setBody(e.target.value)}}/>
                                    </div>
                               </div>
                            </div>
                            <div class="modal-footer">
                               {user.permissionTypes.includes("editEmailTemplates") &&<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" onClick={resetDefaultTemplate}>Reset to default</button>}
                                <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
                              {user.permissionTypes.includes("editEmailTemplates")&&  <button type="button" class="btn btn-outline-success" data-bs-dismiss="modal" onClick={updateClientTemplate}>Save changes</button>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* ----------------------Variables Modal ---------------------------- */}
                <Offcanvas show={showVariables} onHide={() => { setShowVariables(false) }} placement='end'>
                    <Offcanvas.Header>
                        <div className='ms-auto btn btn-light ' onClick={() => { setShowVariables(false) }}>&#x2715;</div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <table>
                            <thead className="fs-6 fw-bold">
                               <tr>
                                    <td  className=' border border-4 border-dark'> Variable name</td>
                                    <td  className=' border border-4 border-dark'>Description</td>
                                    <td  className=' border border-4 border-dark'>Sample Snippet</td>
                               </tr>
                            </thead>
                            <tbody>
                            {variables.map((variable,index) => {
                                   return (
                                    <tr>
                                        <td>{variable.name}</td>
                                        <td>{variable.description}</td>
                                        <td>&lt;%= {variable.name}%&gt;</td>
                                   </tr>  
                                   )
                                })}
                            </tbody>
                        </table>
                    </Offcanvas.Body>
                </Offcanvas>

                {/* ------------------------Preview Container---------------------------- */}
                <Offcanvas className="w-100" show={showPreview} onHide={() => { setShowPreview(false) }} placement='start'>
                    <Offcanvas.Header>
                        <div className='ms-auto btn btn-light ' onClick={() => { setShowPreview(false) }}>&#x2715;</div>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div dangerouslySetInnerHTML={{ __html:body }} />
                    </Offcanvas.Body>
                </Offcanvas>

            </div>
        )
    }
    else {
        // return <div>Loading.........</div>
        return <div className="ht80vh w-100 d-flex justify-content-center align-items-center">
        <SpinnerLoader />
    </div>
    }
}