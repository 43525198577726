import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Button, Table, Spinner, Alert, Modal, Form, Dropdown, Offcanvas } from 'react-bootstrap';
import Context from '../../user_details';
import edit_icon from '../../assets/edit_icon.svg';
import delete_icon from '../../assets/delete_icon.svg';
import PermissionsTable from '../AllPermissionsTable/allPermissionsTable';
import cancel_icon from '../../assets/close_icon.svg';
import './roles.css'; // Import custom CSS file
import '../Users/users.css'; // Import custom CSS file
import { playSound } from '../../helpers/otherSounds';

const RolesTable = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allPermissions, setAllPermissions] = useState([]);
  const [firms, setFirms] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState('');
  const { user } = useContext(Context);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentRole, setCurrentRole] = useState({});
  const [updatedRole, setUpdatedRole] = useState({ role_name: '', description: '' });
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [createAction, setCreateAction] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [offcanvasMessage, setOffcanvasMessage] = useState('');
  const [offcanvasType, setOffcanvasType] = useState('success'); // success or error
  const [saving, setSaving] = useState(false); // Loader state for saving
  const [showConfirmDelete, setShowConfirmDelete] = useState(false); // State to manage confirm delete modal
  const [roleToDelete, setRoleToDelete] = useState(null); // Role ID to delete

  useEffect(() => {
    if (showOffcanvas) {
      setTimeout(() => {
        setShowOffcanvas(false);
      }, 3000);
    }
  }, [showOffcanvas]);

  useEffect(() => {
    fetchRoles();
  }, [user.user_type, user.firm_id]);

  useEffect(() => {
    if (!showModal) {
      setCreateAction(false);
    }
  }, [showModal]);

  useEffect(() => {
    if (createAction) {
      setSelectedPermissions([]);
      if (user.user_type === 'superAdmin') {
        fetchFirms();
      }
    }
  }, [createAction]);

  useEffect(() => {
    if (updatedRole.role_name === '' && updatedRole.description === '' && createAction && !selectedPermissions.length) {
      setShowModal(true);
    }
  }, [updatedRole]);

  useEffect(() => {
    if (createAction && !selectedPermissions.length) {
      setValidationError('');
    }
  }, [selectedPermissions]);

  useEffect(() => {
    if (updatedRole.role_name === '' && updatedRole.description === '' && createAction && !selectedPermissions.length) {
      setShowModal(true);
    }
  }, [updatedRole]);

  const fetchRoles = async () => {
    try {
      setLoading(true);
      const [rolesResponse, permissionsResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/ums/roles/list?type=${user.user_type}&firm_id=${user.firm_id}`),
        axios.get(`${process.env.REACT_APP_API_URL}/ums/permissions/readByFirm/${user.firm_id}`)
      ]);
      setRoles(rolesResponse.data);
      setAllPermissions(permissionsResponse.data.result);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchFirms = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
      if (response.data.success) {
        setFirms(response.data.results);
      }
    } catch (err) {
      setError(`Failed to fetch firms: ${err.message}`);
    }
  };

  const handleEdit = (role) => {
   if (user.permissionTypes.includes("viewRoles")) {
    console.log("role is",role,"\n firms are", firms)
    setCurrentRole(role);
    setUpdatedRole({ role_name: role.role_name, description: role.description });
    setSelectedPermissions(role.permissions_ids || []);
    setSelectedFirm(role.firm_id); // Default firm for non-superAdmins
    setShowModal(true);
   }
  };

  const handleDelete = async(roleId) => {
   try {
    const {data: {success}} = await axios.post(`${process.env.REACT_APP_API_URL}/ums/canDelete/roles`,{deleted_roles: [roleId]});
    if (!success) {
      playSound('ERROR');
      setOffcanvasMessage("This role is currently being used. Deleting this role will unassign it from all of the current assigned users.");
      setOffcanvasType("warning");
      setShowOffcanvas(true);
    }
  } catch(e) {
    console.log(e);
   } finally {
    setRoleToDelete(roleId);
    setShowConfirmDelete(true);
   }
    
  };

  const deleteRole = async (roleId) => {
    const hasPermission = await user.permissionTypes.includes("deleteRoles");
    if ( hasPermission) {
    try {
      setSaving(true); // Show loader for delete operation
      await axios.post(`${process.env.REACT_APP_API_URL}/ums/roles/delete/${ roleId }`,{});
      playSound('SUCCESS');
      setOffcanvasMessage('Role deleted successfully!');
      setOffcanvasType('success');
      setShowOffcanvas(true);
      fetchRoles();
    } catch (err) {
      playSound('ERROR');
      setOffcanvasMessage(`Failed to delete role: ${err.message}`);
      setOffcanvasType('danger');
      setShowOffcanvas(true);
    } finally {
      setSaving(false); // Hide loader
      setShowConfirmDelete(false);
    }}
  };

  const handleCreateNew = async() => {
    const hasPermission = await user.permissionTypes.includes("createRoles");
    if ( hasPermission) {
    setCreateAction(true);
    setUpdatedRole({ role_name: '', description: '' }); // Reset role fields
    setSelectedPermissions([]);
    setSelectedFirm(''); // Reset firm selection for superAdmin
    setShowModal(true); // Ensure modal is shown
    }
  };

  const handleClose = () => {
    setShowModal(false);
    fetchRoles();
  };

  const handleSaveChanges = async () => {
    const hasPermission = await user.permissionTypes.includes("createRoles");
    if ( hasPermission) {
    // Validate input fields
    if (updatedRole.role_name.trim() === '' || updatedRole.description.trim() === '') {
      playSound('ERROR');
      setValidationError('Role name and description cannot be empty.');
      return;}
    }
  
    // Validate firm selection if superAdmin
    if (user.user_type === 'superAdmin' && !selectedFirm) {
      playSound('ERROR');
      setValidationError('You must select a firm.');
      return;
    }
  
    try {
      setValidationError(''); // Clear any previous validation errors
      setSaving(true); // Show loader
  
      const payload = {
        ...updatedRole,
        permissions_ids: selectedPermissions,
        firm_id: user.user_type === 'superAdmin' ? selectedFirm : user.firm_id,
        ...(createAction && { created_by: user._id }), // Include created_by only for new roles
        ...( !createAction && { updated_by: { updated_by_id: user._id } }) // Include updated_by only for existing roles
      };
  
      const RoleURL = createAction 
        ? `${process.env.REACT_APP_API_URL}/ums/roles/create`
        : `${process.env.REACT_APP_API_URL}/ums/roles/update/${currentRole._id}`;
      await axios.post(RoleURL, payload);
  
      setOffcanvasMessage('Role saved successfully!');
      playSound('SUCCESS');
      setOffcanvasType('success');
      setShowOffcanvas(true);
      setShowModal(false);
      fetchRoles();
    } catch (err) {
      playSound('ERROR');
      setOffcanvasMessage(`Failed to save role: ${err.message}`);
      setOffcanvasType('danger');
      setShowOffcanvas(true);
    } finally {
      setSaving(false); // Hide loader
    }
  };
  
  const handleChange = (e) => {
    setUpdatedRole({
      ...updatedRole,
      [e.target.name]: e.target.value
    });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center loader-container">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-4">
        <Alert variant="danger">Error: {error}</Alert>
      </div>
    );
  }

  return (
    <div className="roles_page p-3">
       {/* ========= Header - STARTS here ========== */}
       <div class="container-fluid">
          <div class="row">
              <div class="col-sm-8">
                  <div class="page-header-container">
                      <h2 class="page-main-header">Roles</h2>

                      {user.permissionTypes.includes("createRoles") &&
                          <Button variant="primary" className=" add-form-btn" onClick={handleCreateNew}>
                            <span class="icon-placeholder">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" 
                              fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5">
                                </path>
                              </svg>
                            </span> 
                            New Role
                          </Button>
                      } 
                  </div>
              </div>
              <div class="col-sm-4"> &nbsp; </div>
          </div>
          <div class="row">
              <div class="col-sm-12"> <p class="mb-0">&nbsp;</p> </div>
          </div>
      </div>
      
      <Table bordered responsive className="roles-table">
        <thead>
          <tr>
            <th>Role Name</th>
            {user.user_type === 'superAdmin' && <th>Firm</th>}
            <th>Description</th>
            {(user.permissionTypes.includes('editRoles') || user.permissionTypes.includes('deleteRoles')) &&
              <th>Actions</th> 
            }
          </tr>
        </thead>
        <tbody>
          {roles.map(({ role, firm }) => (
            <tr key={role._id}>
              <td>{role.role_name}</td>
              {user.user_type === 'superAdmin' && <td>{firm.firm_name}</td>}
              <td>{role.description}</td>
              <td>
               {
                user.permissionTypes.includes("viewRoles") &&
                <Button
                variant="secondary"
                onClick={() => handleEdit(role)}
                className=" btn-viewandupdate-item " >
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                  </svg> View & Edit
              </Button>
               }

                {
                  user.permissionTypes.includes("deleteRoles") && 
                  <Button variant="danger"
                  onClick={() => handleDelete(role._id)}
                  className="action-btn btn-delete-item"  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                  </svg> Delete
                </Button>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleClose} className=' update-model-container '>
        <Modal.Header closeButton>
          <Modal.Title className=' '>
            {createAction ? "Create Role" : "Update Role"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formRoleName">
              <Form.Label className=' element-header'>Role Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter role name"
                name="role_name"
                value={updatedRole.role_name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formDescription" className="mt-3">
              <Form.Label className=' element-header'>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter description"
                name="description"
                value={updatedRole.description}
                onChange={handleChange}
              />
            </Form.Group>

            {user.user_type === 'superAdmin' && (
              <Form.Group controlId="formFirm" className="mt-3">
                <Form.Label className='label-select-firm'>Select Firm</Form.Label>
               {createAction &&
                  <Dropdown className=' select-firm-options '>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {selectedFirm ? firms.find(firm => firm._id === selectedFirm).firm_name : 'Select Firm'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {firms.map((firm) => (
                        <Dropdown.Item
                          key={firm._id}
                          onClick={() => setSelectedFirm(firm._id)}
                        >
                          {firm.firm_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
               }
              </Form.Group>
            )}

            {validationError && (
              <Alert variant="danger" className="mt-3">
                {validationError}
              </Alert>
            )}

            <PermissionsTable 
              all_permissions={allPermissions}
              selectedPermissions={selectedPermissions}
              setSelectedPermissions={setSelectedPermissions}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className=' btn-close-window'>
            Cancel
          </Button>
          {createAction && 
                      <Button
                      variant="primary"
                      className=' btn-update'
                      onClick={handleSaveChanges}
                      disabled={
                        saving || 
                        (!updatedRole.role_name || !updatedRole.description || (user.user_type === 'superAdmin' && !selectedFirm))
                      }
                    >
                      {saving ? <Spinner animation="border" size="sm" /> : "Create Role" }
                    </Button>
          }
          {!createAction  && user.permissionTypes.includes("editRoles")&& 
                      <Button
                      variant="primary"
                      className='  btn-update'
                      onClick={handleSaveChanges}
                      disabled={
                        saving || 
                        (!updatedRole.role_name || !updatedRole.description || (user.user_type === 'superAdmin' && !selectedFirm))
                      }
                    >
                      {saving ? <Spinner animation="border" size="sm" /> : "save changes" }
                    </Button>
          }
          
        </Modal.Footer>
      </Modal>

      <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} className="d-inline-flex rolesTableOffCanvas" placement="end">
        <Offcanvas.Body className='p-0'>
          <Alert variant={offcanvasType} className='m-0 my-0'>
            {offcanvasMessage}
          </Alert>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Confirmation Modal for Deletion */}
      <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title className='text-secondary'>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this role?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmDelete(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => deleteRole(roleToDelete)}>
            {saving ? <Spinner animation="border" size="sm" /> : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RolesTable;
