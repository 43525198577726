import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './learningOutcomes.css'; // Import CSS file
import LearningOutcomeForm from '../AddLearningOutcomeForm/addLearningOutcomeForm'; // Import the LearningOutcomeForm component
import Context from '../../user_details';
import SoundButton from '../../helpers/soundButton';

export default function LearningOutcomes() {
  const {user} = useContext(Context);
  const [learningOutcomes, setLearningOutcomes] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showForm, setShowForm] = useState(false); // State variable to manage form visibility

  useEffect(() => {
    fetchLearningOutcomes();
  }, [page]); // Fetch learning outcomes whenever page changes

  const fetchLearningOutcomes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/learning-outcomes/${user.firm_id}?page=${page}`);
      const { learningOutcomes, totalPages } = response.data;
      setLearningOutcomes(learningOutcomes);
      setTotalPages(totalPages);
    } catch (error) {
      console.error('Error fetching learning outcomes:', error);
    }
  };

  const handleLearningOutcomeAdded = () => {
    // Close the form and refresh the learning outcome list
    setShowForm(false);
    fetchLearningOutcomes();
  };

  return (
    <div className="learning-outcomes-container">
      {/* <h2>Learning Outcomes</h2>
      <button onClick={() => setShowForm(!showForm)}>Add Learning Outcome</button>
      {showForm && <LearningOutcomeForm onAddLearningOutcome={handleLearningOutcomeAdded} />} */}

      {/* =============================================== */}
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-8">
            <div class="page-header-container">
              <h2 class="page-main-header">Learning Outcomes</h2>
              { user.permissionTypes.includes("createLearningOutcomes") &&<button onClick={() => setShowForm(!showForm)} class="add-form-btn">
                <span class="icon-placeholder">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-plus" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5"/>
                    <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
                  </svg>
                </span>
                Add Learning Outcomes
              </button>}
            </div>
          </div>
          <div class="col-sm-4"> &nbsp; </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p class="mb-0">&nbsp;</p>
            {showForm && <LearningOutcomeForm onAddLearningOutcome={handleLearningOutcomeAdded} />}
          </div>
        </div>
      </div>
      {/* ================================================ */}

      {/* <ul className="learning-outcome-list">
        {learningOutcomes.map((learningOutcome, index) => (
          <li key={index} className="learning-outcome-item">
            <span className="lo-name">{learningOutcome.lo_name}</span>
            <span className="lo-description">{learningOutcome.lo_description}</span><br />
            {learningOutcome.course_id && <span ><b>Course:</b> {learningOutcome.course_id.course_name}</span>} <br />
            {learningOutcome.subject_id && <span ><b>Subject:</b> {learningOutcome.subject_id.subject_name}</span>}<br />
            {learningOutcome.topic_id && <span ><b>Topic:</b> {learningOutcome.topic_id.topic_name}</span>}
          </li>
        ))}
      </ul> */}

      {/* ================================================ */}
      <ul className="learning-outcome-list row row-cols-1 row-cols-md-3 g-4">
      {learningOutcomes.map((learningOutcome, index) => (
          <li key={index} className="learning-outcome-item page-added-item col">
            <div class="added-items-list card h-100">
              <div class="card-body">
                <h5 class="card-title min-ht-25">{learningOutcome.lo_name}</h5>
                <p class="card-text">
                  <strong>Description</strong><br />
                  {learningOutcome.lo_description}
                </p>
                <p class="card-text small-ht-block">
                  <strong>Course:</strong><br />
                  {learningOutcome.course_id.course_name}
                </p>
                <p class="card-text small-ht-block">
                  <strong>Subject:</strong><br />
                  {learningOutcome.subject_id.subject_name}
                </p>
                <p class="card-text small-ht-block">
                  <strong>Topic:</strong><br />
                  {learningOutcome.topic_id.topic_name}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {/* ================================================ */}


      <nav>
        <ul className="pagination">
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <li key={pageNumber} className={`page-item ${page === pageNumber + 1 ? 'active' : ''}`}>
              <SoundButton className="page-link" onClick={() => setPage(pageNumber + 1)}>
                {pageNumber + 1}
              </SoundButton>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
