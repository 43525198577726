// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.allPermissionsTable tr {
  border: 1px solid black;
}
.allPermissionsTable td, .allPermissionsTable th {
  border: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/components/AllPermissionsTable/allPermissionsTable.scss"],"names":[],"mappings":"AACI;EACI,uBAAA;AAAR;AAEI;EACI,uBAAA;AAAR","sourcesContent":[".allPermissionsTable {\n    tr {\n        border: 1px solid black;\n    }\n    td,th {\n        border: 1px solid black\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
