import React, { useState, useEffect, useContext } from 'react';
import './sidebar.css'; // Import CSS file
import { Accordion, Card } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Context from '../../user_details';
import axios from 'axios';
import { allSideBarInfo, SideNavSVGS } from '../../helpers/sidebarHelper';
import SpinnerLoader from '../../helpers/spinner-loader';


export default function Sidebar(props) {

  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState("dashboards");
  const [activeParentMenu, setActiveParentMenu] = useState("Home");
  const {user} = useContext(Context);
  const {isSMTPAvailable} = props;
  const [loader, setLoader] = useState(true); 
  const [sideBar, setSideBar] = useState(undefined);
  const [isSideNavLoaded, setSideNavLoaded] = useState(false);
  
  useEffect(() => {
    if (isSMTPAvailable !== undefined) {
      // console.log("smtp is dedfinedd", isSMTPAvailable)
    }
  }, [isSMTPAvailable]);

  useEffect(() => {
    if(!isSideNavLoaded) {
      generateSideBar();
    }
    console.log("loading done...", isSideNavLoaded)
  }, [isSideNavLoaded])

  useEffect(() => {
    getActiveMenuKey();
    // console.log("here is active menu", activeMenu)
  }, [activeMenu])

  useEffect(() => {
    if (sideBar !== undefined) {
      setSideNavLoaded(true);
    }
    console.log(sideBar, "sidebar\n\n")
  }, [sideBar])

  useEffect(() => {
    // Split the path to get the active menu
    const pathSegments = location.pathname.split('/').filter(segment => segment); // Remove empty segments
    const activeSegment = pathSegments[0];
    setActiveMenu(activeSegment || "dashboards");
  }, [location.pathname]);

  const navigate = useNavigate();
  
  const NavigateFunction = async(event) => {
    if (isSMTPAvailable === true) {
      const value = event.currentTarget.getAttribute("value");
      setActiveMenu(value || "dashboards");
      navigate(value)
    }
  }

  const getActiveMenuKey = () => {
    var defaultkey = "Home";
    switch(activeMenu) {
      case "dashboards": {
        defaultkey = "Home";
        break;
      };
      case "courses":
      case "subjects":
      case "topics":
      case "questions":
      case "generateQuestions":
      case "outcomes": {
        defaultkey = "Questions";
        break;
      };
      case "examPatterns":
      case "exams": {
        defaultkey = "Exams";
        break;
      };
      case "users":
      case "roles":
      case "groups": {
        defaultkey = "Users";
        break;
      }
      case "SMTP-Settings":
      case "Email-Templates": {
        defaultkey = "Settings";
        break;
      }
    }
    // console.log("returning", defaultkey)
    setActiveParentMenu(defaultkey);
  }

  const generateSideBar = async() => {
    var filteredSideBar = await filterSideBarInfo(allSideBarInfo, user.permissions);   
    setSideBar(filteredSideBar);
  }

  const filterSideBarInfo = (items, userPermissions) => {
    return items.reduce((acc, item) => {
        if (item.children && item.children.length > 0) {
            const filteredChildren = filterSideBarInfo(item.children, userPermissions);
            if (filteredChildren.length > 0) {
                acc.push({
                    ...item,
                    children: filteredChildren
                });
            }
        } else {
            if (item.requiredPermissions.every(permission => userPermissions.includes(permission))) {
                acc.push(item);
            }
        }
        return acc;
    }, []);
  };


const AccordionItem = ({ item, eventKey }) => {
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
        {item.headerTitle}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>
          {item.children.length > 0 ? (
            <SideBarAccordion sideBarItems={item.children} />
          ) : (
            <p>{item.headerTitle}</p>
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

const SideBarAccordion = ({ sideBarItems }) => {
  return (
    <Accordion defaultActiveKey="Home">
      {sideBarItems.map((item, index) => (
        <AccordionItem key={item.key} item={item} eventKey={`${item.key}`} />
      ))}
    </Accordion>
  );
};

const AccordionComponent = ({sideBarItem}) => {
  if(sideBarItem.children.length) {
    return (
      <Accordion.Item eventKey={sideBarItem.key} key={sideBarItem.key}>
        <Accordion.Header className='left-menu-icon'>
          <span className='left-menu-icon'>
            {SideNavSVGS[sideBarItem.svgKey]}
          </span>
          {sideBarItem.headerTitle}
        </Accordion.Header>
        {sideBarItem.children.map(childItem => (
          <AccordionComponent sideBarItem={childItem}/>
        ))}
      </Accordion.Item>
    )
  } else {
    return (
      <Accordion.Body 
        onClick={NavigateFunction} 
        value={sideBarItem.value}
        className={activeMenu === `${sideBarItem.activeMenu}` && "menu-active"}
        >
        <span className='left-menu-icon'>
          {SideNavSVGS[sideBarItem.svgKey]}
        </span>
        {sideBarItem.headerTitle}
      </Accordion.Body>
    )
  }
};
/*--------------------------*/

  return (
  <aside className="sidebar area">
    {
      isSideNavLoaded?
        sideBar.length? 
          <Accordion defaultActiveKey={String(activeParentMenu)}>
            {
              sideBar.map(sideBarItem => {
                return (
                  <AccordionComponent sideBarItem={sideBarItem}/>
                )
              })
            }
          </Accordion>  
        : <p>No Items</p>:
      // <div>Loading....</div>
      <div className="h-80 w-100 d-flex justify-content-center align-items-center">
        <SpinnerLoader />
      </div>
    }
    

    <ul className="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </aside>)


}

