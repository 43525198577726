// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-table {
  padding: 0px 10px 0px 10px;
}
.pdf-table-page-heading {
  background: rgb(235, 241, 251);
  color: rgb(58, 79, 150);
}
.pdf-table-head {
  color: rgb(34, 97, 127);
  font-weight: bold;
  height: 60px;
}
.pdf-table-row {
  box-sizing: content-box;
  width: "190mm";
}
.pdf-table-column-element {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
  height: 100%;
  border: solid 1px #e8e6ea;
}
.pdf-table-column-element-dark {
  background-color: rgb(250, 249, 252);
}

.dynamic-width {
  width: var(--dynamic-width, 100%);
}`, "",{"version":3,"sources":["webpack://./src/helpers/pdfHelper.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;AACJ;AACI;EACI,8BAAA;EACA,uBAAA;AACR;AAEI;EACG,uBAAA;EACA,iBAAA;EACA,YAAA;AAAP;AAGI;EAEI,uBAAA;EACA,cAAA;AAFR;AAUI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;AARR;AAUQ;EACI,oCAAA;AARZ;;AAaA;EACI,iCAAA;AAVJ","sourcesContent":[".pdf-table {\n    padding: 0px 10px 0px 10px;\n\n    &-page-heading {\n        background: rgb(235, 241, 251);\n        color : rgb(58, 79, 150);\n    }\n    \n    &-head {\n       color: rgb(34, 97, 127);\n       font-weight: bold;\n       height: 60px;\n    }\n    \n    &-row {\n        // border: solid 1px #e8e6ea;\n        box-sizing: content-box;\n        width: '190mm'\n        \n       \n       \n    }\n\n   \n\n    &-column-element {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        text-align: center;\n        padding: 5px;\n        height: 100%;\n        border: solid 1px #e8e6ea;\n\n        &-dark {\n            background-color: rgb(250, 249, 252);\n        }\n    }\n}\n\n.dynamic-width {\n    width: var(--dynamic-width, 100%); // Default to 100% if not set\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
