import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import './examComponent.css';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer
} from "recharts";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Modal from 'react-bootstrap/Modal';
import StudentsList from './studentList';
import DistributionOfMarksChart from './distributionOfMarksChart';
import OverAllSectionWisePerformance from './sectionWisePerformanceChart';
import StudentSectionWisePerformance from './studentSectionWisePerformance';
import DetailedExamData from './detailedExamData';
import { saveAs } from 'file-saver';
import {mergePDFs} from '../../helpers/pdfHelper';
import SpinnerLoader from '../../helpers/spinner-loader';


export default function ExamComponent() {
  const [showGraphs, setShowGraphs] = useState(false);
  const [studentsDetails, setStudentsDetails] = useState([]);
  const [analysisData, setAnalysisData] = useState({});
  const [detailedExamData, setDetailedExamData] = useState(null);
  const [downloadloader, setDownloadLoader] = useState(false);

  const navigate = useNavigate();
  const url = useParams();

  const examNavigator = (examRoute) => {
    navigate(`/${examRoute}`);
  }

  const studentListAndMarksByExamId = async (examId) => {
    try {
      let exam = await axios.get(`${process.env.REACT_APP_API_URL}/exam/studentListAndMarksByExamId/${examId}`);
      const { exam_name, exam_negative_marks, highest_score, lowest_score, average_score, median_score, standard_deviation, studentMarks, top_performers, low_performers, pass_count, fail_count, sectionWisePerformance } = exam.data;

      // Calculate score counts dynamically
      const scoreCounts = {};
      studentMarks.forEach(student => {
        const score = student.marks;
        if (scoreCounts[score]) {
          scoreCounts[score]++;
        } else {
          scoreCounts[score] = 1;
        }
      });


      // Transform scoreCounts into the format required by the bar chart
      let barChartData = Object.keys(scoreCounts).map(score => ({
        score: parseInt(score), // Convert score to number
        count: scoreCounts[score]
      }));
      barChartData.sort((a, b) => b.score - a.score);

      // Calculate the count of students who passed and failed
      const passCount = pass_count || 0;
      const failCount = fail_count || 0;

      setAnalysisData({ exam_name, exam_negative_marks: exam_negative_marks.marks, highest_score, lowest_score, average_score, median_score, standard_deviation, top_performers, low_performers, pass_count, fail_count, barChartData, sectionWisePerformance, passCount, failCount });
      setStudentsDetails(studentMarks);
    } catch (error) {
      console.error('Error fetching student details:', error);
    }
  };


  useEffect(() => {
    studentListAndMarksByExamId(url.id);
  }, [url.id]);

  const fetchDetailedExamData = async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/exam/attended-exams-details/${userId}/${url.id}`);
      const data = response.data;

      const qComplexityLabel = { 1: 'Easy', 2: 'Medium', 3: 'Hard' };

      // Calculate questionComplexityTimeSpent
      const questionComplexityTimeSpent = data.questionDetails.reduce((acc, question) => {
        const complexity = question.complexity;
        const timeSpent = question.timeSpent;

        if (!acc[complexity]) {
          acc[complexity] = 0;
        }
        acc[complexity] += timeSpent;
        return acc;
      }, {});

      // Transform the object to the desired format
      const formattedQuestionComplexityTimeSpent = Object.keys(questionComplexityTimeSpent).map(complexity => ({
        question_complexity: qComplexityLabel[complexity],
        timeSpent: questionComplexityTimeSpent[complexity] / 60
      }));

      // Add the calculated data to the detailedExamData
      const detailedExamData = {
        ...data,
        questionComplexityTimeSpent: formattedQuestionComplexityTimeSpent
      };

      setDetailedExamData(detailedExamData);
      setShowGraphs(true);
    } catch (error) {
      console.error('Error fetching detailed exam data:', error);
    }
  };

  const ShowGraphs = (student_id) => {
    fetchDetailedExamData(student_id);
  }

  const downloadExcel = async () => {
    if (studentsDetails.length > 0) {
      try {
        setDownloadLoader(true);
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/exam/getExcelReport/${url.id}`)
        if (result.data.success && result.data.studentExamResult.length > 0) {
          // Create a new workbook
          const workbook = XLSX.utils.book_new();

          // Prepare data for the sheet
          const data = result.data.studentExamResult.map(exam => ({
            "Student Name": `${exam.StudentDetails.first_name} ${exam.StudentDetails.last_name}`,
            "Email": `${exam.StudentDetails.email}`,
            "Scored/Total": `${exam.scoredMarks - exam.negative_marks.negative_marks} / ${exam.totalMarks}`,
            "Negative Marks": exam.negative_marks.negative_marks,
            "#Correct Answers": exam.correctAnswersCount,
            "#Incorrect Answers": exam.incorrectAnswersCount,
            "#Skipped Answers": exam.skippedAnswersCount,
            "#Violations": exam.no_of_violations,
            "Exam Attended Time": exam.exam_initiated_time ? `${exam.exam_initiated_time.split('T')[0]}  ${exam.exam_initiated_time.split('T')[1].split('.')[0]} ` : 'N/A',
            "Exam Submitted Time": `${exam.attendedDate.split('T')[0]}  ${exam.attendedDate.split('T')[1].split('.')[0]} `

          }));

          // Convert the data to a worksheet
          const worksheet = XLSX.utils.json_to_sheet(data);

          // Append the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Exam Results');

          // Generate the Excel file and download it
          XLSX.writeFile(workbook, 'Exam_Results.xlsx');
          setDownloadLoader(false);
        } else {
          alert("Unable to download file")
          setDownloadLoader(false);
        }

      }
      catch (e) {
        setDownloadLoader(false);
        alert("Something went wrong!")
        console.log(e);
      }
    }
    else {
      alert("no attended examinees for this exam")
    }
  }


  const downloadPDF = () => {
    const input1 = document.getElementById('reportMarks');
    const input2 = document.getElementById('reportContent');

    // Capture the first input
    html2canvas(input1)
      .then(canvas1 => {
        const imgData1 = canvas1.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        // Add the first image
        pdf.addImage(imgData1, 'PNG', 0, 0, pdfWidth, pdfHeight);

        // Capture the second input
        html2canvas(input2)
          .then(canvas2 => {
            const imgData2 = canvas2.toDataURL('image/png');

            // If the second canvas fits on the same page
            if (canvas2.height <= pdfHeight - canvas1.height) {
              pdf.addImage(imgData2, 'PNG', 0, canvas1.height, pdfWidth, pdfHeight - canvas1.height);
            } else {
              // Add a new page for the second image if it doesn't fit
              pdf.addPage();
              pdf.addImage(imgData2, 'PNG', 0, 0, pdfWidth, pdfHeight);
            }

            // Save the PDF
            pdf.save(analysisData.exam_name + ".pdf");
          });
      });
  }

  const StudentSpecificReport = () => (
    <div id="reportContent">


      <DetailedExamData detailedExamData={detailedExamData} />

      <div className='d-flex flex-wrap' id="reportContent">

        <StudentSectionWisePerformance sectionDetails={detailedExamData.sectionDetails} />

        <div className='report-container'>
          <h3>Difficulty Level <small>Vs</small> Time Spent <small>(in Minutes)</small></h3>
          <div style={{ height: '350px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie data={detailedExamData.questionComplexityTimeSpent} dataKey="timeSpent" nameKey="question_complexity" cx="50%" cy="50%" outerRadius={60} label>
                  {detailedExamData.questionComplexityTimeSpent.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={index % 2 === 0 ? "#8884d8" : "#82ca9d"} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className='report-container exam-performers all-questions-details w-100'>
        <h3>Question Details</h3>
        <br />
        <table className='table'>
          <thead>
            <tr>
              <th>Question</th>
              <th>Marks</th>
              <th>Time Spent</th>
              <th>Correct</th>
            </tr>
          </thead>
          <tbody>
            {detailedExamData.questionDetails.map((question, index) => (
              <tr key={index}>
                <td><span dangerouslySetInnerHTML={{ __html: question.question }} /></td>
                <td>{question.marks}</td>
                <td>{question.timeSpent}</td>
                <td>{question.isCorrect ? <span class="tickmark">&#10004;</span> : <span class="crossmark">&#10008;</span>}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  )

  const studentExamAnswersDetailsTable = (detailsExamData) => (
    <div className='report-container exam-performers all-questions-details w-100'>
    <h3>Question Details</h3>
    <br />
    <table className='table'>
      <thead>
        <tr>
          <th>Question</th>
          <th>Marks</th>
          <th>Time Spent</th>
          <th>Correct</th>
        </tr>
      </thead>
      <tbody>
        {detailedExamData.questionDetails.map((question, index) => (
          <tr key={index}>
            <td><span dangerouslySetInnerHTML={{ __html: question.question }} /></td>
            <td>{question.marks}</td>
            <td>{question.timeSpent}</td>
            <td>{question.isCorrect ? <span class="tickmark">&#10004;</span> : <span class="crossmark">&#10008;</span>}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )

  const downloadSpecificStudentReport = async() => {
    setDownloadLoader(true);
    try {
      console.log("details 123", detailedExamData)
      await Promise.all(
        [
          getPDFBriefTableData(['reportContent', "specific-student-performance-analytics"]),
          getQuestionsDetailsTableData()
        ]
      )
      .then(async(data) => {
          // merge pdf
          const mergedPdfBytes = await mergePDFs(data);
          // Save the merged PDF
          saveAs(new Blob([mergedPdfBytes]), `${detailedExamData.examName}.pdf`);
      })
    } catch(e) {
      console.error(e);
    } finally {
      setDownloadLoader(false);
    }
  }

  const getQuestionsDetailsTableData = async() => {
    console.log("get Questions calledd")
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate-pdf/${detailedExamData.StudentDetails._id}/${url.id}`,{},
     {
       responseType: 'blob', // Set response type to blob for binary data
     });
  
    const tableBufferData = await response.data.arrayBuffer();
    return tableBufferData;
  }

  const getPDFBriefTableData = async (elements) => {
    console.log("get breif calledd")
    const pdf = new jsPDF('p', 'mm', 'a4');
    let heightLeft = 295, position = 10;
    const pdfContents = await Promise.all(
      elements.map(async element_id => {
        const input = document.getElementById(element_id);
  
        if (input) {
          // Capture the content as canvas
          const canvas = await html2canvas(input, { scale: 2 }); // Increase scale for better quality
          const imgData = canvas.toDataURL('image/png');
  
          // Create a new PDF
         
          const imgWidth = 190; // A4 width in mm (210)
          const pageHeight = 295; // A4 height in mm (295)
          var imgHeight;
          if (element_id == 'specific-student-performance-analytics') {
            imgHeight = 90;
          } else {
            imgHeight = canvas.height * imgWidth / canvas.width;
          }
          
          heightLeft = pageHeight - imgHeight - 10;
          // let position = parseInt((pageHeight-imgHeight)/2);
  
          // Add image to PDF
          pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
          
          position += imgHeight + 10;
  
          
  
          // Save the PDF
          return pdf.output('arraybuffer');
          
        } else {
          console.error('Element not found');
          return new Uint8Array();
        }
      })
    )
    return pdfContents[pdfContents.length - 1];
  }

  return (
    <div className='from-exams-list exam-component-container d-flex flex-column'>
      <Modal show={downloadloader}>
        <Modal.Body>
          <div className="h-100 w-100 d-flex justify-content-start align-items-center gap-3 fw-bolder fs-6 text-muted">
            <SpinnerLoader/>
            <p className='m-0'> Generating report.... Please Wait....</p>
          </div>
        </Modal.Body>
      </Modal>
      <div className='exam-component-page-header d-flex text-dark text-center px-3 py-2 gap-2'>
        <a href="/exams">Exams</a>
        <p className='mb-0'> / </p>
        <a href={"/exams/" + url.id}>{analysisData.exam_name}</a>
      </div>
      <div className='row exam-component-content h-75'>

        <StudentsList analysisData={analysisData} studentsDetails={studentsDetails} ShowGraphs={ShowGraphs} />

        <div className='col-md-8 '>
          {showGraphs && detailedExamData ?
            <div className='h-100 gap-5 d-flex flex-column'>
              <div className=''>

                <button onClick={downloadSpecificStudentReport} className="btn btn-primary download-report mt-3">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-arrow-down-fill" viewBox="0 0 16 16">
                      <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5" />
                    </svg>
                  </span>
                  Download Report
                </button>
                {/* <StudentSpecificReport/> */}
                {/* () => downLoadStudentSpecificReport(detailedExamData.examName, ['reportContent', "specific-student-performance-analytics", "specific-student-answers-table"]) */}
                <div id="reportContent">
                  <DetailedExamData detailedExamData={detailedExamData} />
                </div>
                <div className='d-flex flex-wrap reportContent' id="specific-student-performance-analytics">

                  <StudentSectionWisePerformance sectionDetails={detailedExamData.sectionDetails} />

                  <div className='report-container'>
                    <h3>Difficulty Level <small>Vs</small> Time Spent <small>(in Minutes)</small></h3>
                    <div style={{ height: '350px' }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie data={detailedExamData.questionComplexityTimeSpent} dataKey="timeSpent" nameKey="question_complexity" cx="50%" cy="50%" outerRadius={60} label>
                            {detailedExamData.questionComplexityTimeSpent.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={index % 2 === 0 ? "#8884d8" : "#82ca9d"} />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
                <div className='reportContent' id="specific-student-answers-table">
                  <div className='report-container exam-performers all-questions-details w-100 '   >
                    <h3>Question Details</h3>
                    <br />
                    <table className='table'>
                      <thead>
                        <tr>
                          <th>Question</th>
                          <th>Marks</th>
                          <th>Time Spent</th>
                          <th>Correct</th>
                        </tr>
                      </thead>
                      <tbody>
                        {detailedExamData.questionDetails.map((question, index) => (
                          <tr key={index}>
                            <td><span dangerouslySetInnerHTML={{ __html: question.question }} /></td>
                            <td>{question.marks}</td>
                            <td>{question.timeSpent}</td>
                            <td>{question.isCorrect ? <span class="tickmark">&#10004;</span> : <span class="crossmark">&#10008;</span>}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                
              </div>
            </div>
            :
            <div className='d-flex flex-column justify-content-start h-100 p-3 align-items-start gap-3'>
              <button onClick={downloadExcel} className="btn btn-primary download-report mt-3">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-arrow-down-fill" viewBox="0 0 16 16">
                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5" />
                  </svg>
                </span>
                Download Excel Report
              </button>
              <div id="reportContent" className="d-flex flex-wrap">
                <div className="report-container">
                  <h3>Exam Analysis</h3>
                  <br />
                  <table className='table exam-analysis-table'>
                    <tbody>
                      <tr><th>Highest Score</th><td>{analysisData.highest_score}</td></tr>
                      <tr><th>Lowest Score</th><td>{analysisData.lowest_score}</td></tr>
                      <tr><th>Average Score</th><td>{analysisData.average_score}</td></tr>
                      <tr><th>Median Score</th><td>{analysisData.median_score}</td></tr>
                      <tr><th>Standard Deviation</th><td>{analysisData.standard_deviation}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div className=" report-container">
                  <DistributionOfMarksChart barChartData={analysisData.barChartData} />
                </div>
                <div className=" report-container">
                  <OverAllSectionWisePerformance sectionWisePerformanceData={analysisData.sectionWisePerformance} />
                </div>
                <div className=" report-container">
                  <h3>Pass / Fail Information</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie data={[{ name: 'Pass', value: analysisData.pass_count }, { name: 'Fail', value: analysisData.fail_count }]} dataKey="value" cx="50%" cy="50%" outerRadius={60} label>
                        <Cell key="pass" fill="#82ca9d" className="pie-cell-3d pie-cell-3d-gradient" />
                        <Cell key="fail" fill="#ff0000" className="pie-cell-3d pie-cell-3d-gradient" />
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className='report-container exam-performers'>
                  <h3>Top 5 Performers</h3>
                  <br />
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Marks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {analysisData.top_performers && analysisData.top_performers.map((performer, index) => (
                        <tr key={index}>
                          <td>{performer.name}</td>
                          <td>{performer.marks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className='report-container exam-performers'>
                  <h3>Least 5 Performers</h3>
                  <br />
                  <table className='table'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Marks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {analysisData.low_performers && analysisData.low_performers.map((performer, index) => (
                        <tr key={index}>
                          <td>{performer.name}</td>
                          <td>{performer.marks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/*<button onClick={downloadPDF} className="btn btn-primary download-report mt-3">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-arrow-down-fill" viewBox="0 0 16 16">
                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5"/>
                  </svg>
                </span>
                Download Report
              </button>*/}

            </div>

          }

        </div>
      </div>
    </div>
  )
}
