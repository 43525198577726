import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReactDOMServer from 'react-dom/server';
import { PDFDocument } from 'pdf-lib';

import './pdfHelper.scss';

export const downLoadStudentSpecificReport = async (elements) => {
  const pdf = new jsPDF('p', 'mm', 'a4');
  let heightLeft = 295, position = 10;
  const pdfContents = await Promise.all(
    elements.map(async element_id => {
      const input = document.getElementById(element_id);

      if (input) {
        // Capture the content as canvas
        const canvas = await html2canvas(input, { scale: 2 }); // Increase scale for better quality
        const imgData = canvas.toDataURL('image/png');

        // Create a new PDF
       
        const imgWidth = 190; // A4 width in mm (210)
        const pageHeight = 295; // A4 height in mm (295)
        var imgHeight;
        if (element_id == 'specific-student-performance-analytics') {
          imgHeight = 90;
        } else {
          imgHeight = canvas.height * imgWidth / canvas.width;
        }
        
        heightLeft = pageHeight - imgHeight - 10;
        // let position = parseInt((pageHeight-imgHeight)/2);

        // Add image to PDF
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        
        position += imgHeight + 10;

        

        // Save the PDF
        // pdf.save('ou')
        return pdf.output('arraybuffer');
        
      } else {
        console.error('Element not found');
        return new Uint8Array();
      }
    })
  )

  // const generatedTablePDF = await generateAutoTablePDF(tableData);
  
  // console.log("result array", pdfContents)
  // const mergedPdfBytes = await mergePDFs([pdfContents[pdfContents.length - 1], generatedTablePDF]);

  
  // const mergedPdfBytes = await mergePDFs([pdfContents[pdfContents.length - 1]]);

  // // // Save the merged PDF
  // saveAs(new Blob([mergedPdfBytes]), `${exam_name}.pdf`);
  // setDownloadLoader(false);
};

export const mergePDFs = async (pdfArrays) => {
  const mergedPdf = await PDFDocument.create();

  for (const pdfArray of pdfArrays) {
    const pdfDoc = await PDFDocument.load(pdfArray);
    const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
    pages.forEach((page) => mergedPdf.addPage(page));
  }

  const pdfBytes = await mergedPdf.save();
  return pdfBytes;
};

export const generateAutoTablePDF = async (detailedExamData) => {
  // console.log("inside table generation", detailedExamData)
 return new Promise (async(res,rej) => {
  try {
  var tableData = await Promise.all(
    detailedExamData.questionDetails.map((question, index) => {
      return (
        <div className={`d-flex align-items-stretch mx-3`}>
          <div dangerouslySetInnerHTML={{ __html: question.question }} className={`pdf-question-element pdf-table-column-element w-75 h-100 p-auto ${index % 2 != 0 && 'pdf-table-column-element-dark'}`}></div>
          <div className={`pdf-table-column-element  ${index % 2 != 0 && 'pdf-table-column-element-dark'}`} style={{ width: "10%" }}>{question.marks ? question.marks : "--"}</div>
          <div className={`pdf-table-column-element  ${index % 2 != 0 && 'pdf-table-column-element-dark'}`} style={{ width: "7.5%" }}>{question.timeSpent ? question.timeSpent : "--"}</div>
          <div className={`pdf-table-column-element  ${index % 2 != 0 && 'pdf-table-column-element-dark'}`} style={{ width: "7.5%" }}>{question.isCorrect ? <span class="">&#10004;</span> : <span className="text-danger">&#10008;</span>}</div>
        </div>
      )
    })
  );
  const pdf = new jsPDF('p', 'mm', 'a4');
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  let currentPageHeight = 10;

  const pageHeading = (
    <div className="w-100 p-2 pdf-table-page-heading fw-bolder fs-5 m-2">
      Question Details
    </div>
  )
  const tableHeader = (
    <div className={`d-flex align-items-stretch justify-content-start pdf-table-head bg-secondary bg-opacity-25 text-center mx-3`}>
      <div className={`pdf-table-column-element w-75 h-100 p-auto`}>Question</div>
      <div className={`pdf-table-column-element h-100 `} style={{ width: "10%" }}>Marks</div>
      <div className={`pdf-table-column-element `} style={{ width: "7.5%" }}>Time Spent</div>
      <div className={`pdf-table-column-element `} style={{ width: "7.5%" }}>Correct</div>
    </div>
  )

  await Promise.all(
    [pageHeading, tableHeader].map(async content => {
      const htmlString = ReactDOMServer.renderToString(content);

      // Create a temporary container to hold the HTML content
      const container = document.createElement('div');
      container.style.position = 'absolute';
      container.style.width = `${pageWidth}mm`;
      container.style.top = '-9999px'; // Hide the container off-screen
      container.innerHTML = htmlString;
    
      document.body.appendChild(container);
    
      // Measure the actual height of the container
      const actualHeight = container.scrollHeight;
    
      // Convert the HTML content to a canvas
      const canvas = await html2canvas(container, {
        scale: 2, // Set scale to 2 for better resolution
    
      });
    
      const tableImgData = canvas.toDataURL('image/png');
      const tableImgWidth = pageWidth;
      const tableImgHeight = (canvas.height * tableImgWidth) / canvas.width; // Maintain aspect ratio
    
      pdf.addImage(tableImgData, 'PNG', 0, currentPageHeight, tableImgWidth, tableImgHeight);
      currentPageHeight += tableImgHeight; // Update the height used on the current page
    
      // Clean up
      document.body.removeChild(container);
    })
  )

  for (const element of tableData) {
    // Convert React element to HTML string
    const htmlString = ReactDOMServer.renderToString(element);

    // Create a temporary container to hold the HTML content
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.width = `${pageWidth}mm`;
    container.style.top = '-9999px'; // Hide the container off-screen
    container.innerHTML = htmlString;
    // container.style.display = 'flex';
    // container.style.alignItems = 'stretch';
    document.body.appendChild(container);

   
    const questionColumns = container.querySelectorAll('.pdf-question-element');
    const imgElements = questionColumns[0].querySelectorAll('img');
    console.log(imgElements)
    imgElements.forEach(img => {
      img.setAttribute('height', '80mm'); 
     
    });
    const columns = container.querySelectorAll('.pdf-table-column-element');
    var maxColumnHeight = 0;
    Array.from(columns).forEach(column => {
      if(column.offsetHeight > maxColumnHeight) {
        maxColumnHeight = column.offsetHeight;
      }
    })
    Array.from(columns).forEach(column => {      
      column.style.height = `${maxColumnHeight}px`;
    })
    // Measure the actual height of the container
    const actualHeight = container.scrollHeight;

    // Convert the HTML content to a canvas
    const canvas = await html2canvas(container, {
      scale: 2, // Set scale to 2 for better resolution

    });

    const imgData = canvas.toDataURL('image/png');
    const imgWidth = pageWidth;
    const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

    // Add image to the PDF
    if (currentPageHeight + imgHeight > pageHeight) {
      // Add a new page if needed
      pdf.addPage();
      currentPageHeight = 10; // Reset height for the new page
      const htmlString = ReactDOMServer.renderToString(tableHeader);

      // Create a temporary container to hold the HTML content
      const container = document.createElement('div');
      container.style.position = 'absolute';
      container.style.width = `${pageWidth}mm`;
      container.style.top = '-9999px'; // Hide the container off-screen
      container.innerHTML = htmlString;
    
      document.body.appendChild(container);
    
      // Measure the actual height of the container
      const actualHeight = container.scrollHeight;
    
      // Convert the HTML content to a canvas
      const canvas = await html2canvas(container, {
        scale: 2, // Set scale to 2 for better resolution
    
      });
    
      const tableImgData = canvas.toDataURL('image/png');
      const tableImgWidth = pageWidth;
      const tableImgHeight = (canvas.height * tableImgWidth) / canvas.width; // Maintain aspect ratio
    
      pdf.addImage(tableImgData, 'PNG', 0, currentPageHeight, tableImgWidth, tableImgHeight);
      currentPageHeight += tableImgHeight; // Update the height used on the current page
    
      // Clean up
      document.body.removeChild(container);
    }

    pdf.addImage(imgData, 'PNG', 0, currentPageHeight, imgWidth, imgHeight);
    currentPageHeight += imgHeight; // Update the height used on the current page

    // Clean up
    document.body.removeChild(container);
  }

  // Save the PDF
  res(pdf.output('arraybuffer'));
  } catch (e) {
    console.error(e)
    res( new Uint8Array());
  }
 })
}


// export const generatePDF = (HtmlContent, fileName) => {
//   const pdf = new jsPDF('p', 'mm', 'a4');
//   console.log(HtmlContent, "before");
//   HtmlContent = ReactDOMServer.renderToString(<HtmlContent />);
//   console.log(HtmlContent," after");
//   // Add HTML content to the PDF
//   pdf.html(HtmlContent, {
//     callback: (pdf) => {
//       pdf.save(fileName);
//     },
//     x: 10,
//     y: 10,
//   });
// };