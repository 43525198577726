import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line, Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import './default.css'; // Import the CSS file
import ExamsOverTimeChart from './examsOverTime';
import QuestionAnalysisChart from './questionAnalysis';
import ExamsAttendanceChart from './examsAttendance';
import ToppersListChart from './toppersList';


const HomeContent = () => {
  
  return (
    <div className='main-dashboard-page-container'>
      <h2 className='dashboard-page-header text-center'>Welcome to your Dashboard!</h2>

      {/* <div className="chart-row">
        <div className="chart-container">
          <ExamsOverTimeChart />
        </div>
        <div className="chart-container">
          <ExamsAttendanceChart />
        </div>
      </div>
      <div className="chart-row">
        <div className="chart-container">
          <QuestionAnalysisChart />
        </div>
        <div className="chart-container">
          <ToppersListChart />
        </div>
      </div> */}

      <div className="chart-row">
        <div className=' row'>
          <div className='col-xxl-6 col-md-6'>
            <div className="chart-container">
              <ExamsOverTimeChart />
            </div>
          </div>
          <div className='col-xxl-6 col-md-6'>
            <div className="chart-container">
              <ToppersListChart />
            </div>
          </div>
          <div className='col-xxl-6 col-md-6'>
            <div className="chart-container">
              <QuestionAnalysisChart />
            </div>
          </div>
          <div className='col-xxl-6 col-md-6'>
            <div className="chart-container mb-0">
              <ExamsAttendanceChart />
            </div>
          </div>
          
          
          
          
        </div>
      </div>


    </div>
  );
};

export default HomeContent;
