import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal'; // Assuming you're using Bootstrap

const StudentsList = ({ analysisData, studentsDetails, ShowGraphs, examId }) => {
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
  const [showModal, setShowModal] = useState(false);
  const [proctoredImages, setProctoredImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Sort data
  const sortData = (data, config) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (a[config.key] < b[config.key]) {
        return config.direction === 'ascending' ? -1 : 1;
      }
      if (a[config.key] > b[config.key]) {
        return config.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  };

  const sortedStudentsDetails = sortData(studentsDetails, sortConfig);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return (
        <span>
          {sortConfig.direction === 'ascending' ? '\u2191' : '\u2193'}
        </span>
      );
    }
    return '';
  };

  // Function to fetch proctored images
  const fetchVideoProctoredImages = async (student_id) => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/exam/fetchVideoProctoredImages/${examId}/${student_id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch proctored images');
      }
      const data = await response.json();
      setProctoredImages(data); // Store fetched images
      setShowModal(true); // Show modal after fetching images
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='col-md-4 exam-component-students-list rounded'>
      <div className='h-100 students-list-table' id="reportMarks">
        <div className='row g-0 text-center students-list-headers'>
          <div className={analysisData.exam_negative_marks > 0 ? 'col-md-6 students-list-header-info' : 'col-md-9 students-list-header-info'} onClick={() => requestSort('name')}>
            <span title='Sort By Name'>Name {getArrow('name')}</span>
          </div>
          <div className='col-md-3 students-list-header-info' onClick={() => requestSort('marks')}>
            <span title='Sort By Marks'>Scored Marks {getArrow('marks')}</span>
          </div>
          {analysisData.exam_negative_marks > 0 && (
            <div className='col-md-3 students-list-header-info' onClick={() => requestSort('negative_marks')}>
              <span title='Sort By Negative Marks'>(-)Ve Marks {getArrow('negative_marks')}</span>
            </div>
          )}
        </div>
        <div className='students-exam-info custom-scrollbar-styles' style={{ overflowY: 'auto' }}>
          {sortedStudentsDetails.map((detail, index) => (
            <div key={index} className='row g-0 text-center students-exam-info-container'>
              <div className={analysisData.exam_negative_marks > 0 ? 'col-md-6 students-list-exam-info' : 'col-md-9 students-list-exam-info'}>
                <span onClick={() => { ShowGraphs(detail.student_id) }} className='studentName'>{detail.name}</span>
                {analysisData.video_proctoring === true && (
                  <span 
                    className='float-end studentName' 
                    title='AI Proctored Images' 
                    onClick={() => fetchVideoProctoredImages(detail.student_id)}
                    style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera2" viewBox="0 0 16 16">
                      <path d="M5 8c0-1.657 2.343-3 4-3V4a4 4 0 0 0-4 4"/>
                      <path d="M12.318 3h2.015C15.253 3 16 3.746 16 4.667v6.666c0 .92-.746 1.667-1.667 1.667h-2.015A5.97 5.97 0 0 1 9 14a5.97 5.97 0 0 1-3.318-1H1.667C.747 13 0 12.254 0 11.333V4.667C0 3.747.746 3 1.667 3H2a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1h.682A5.97 5.97 0 0 1 9 2c1.227 0 2.367.368 3.318 1M2 4.5a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0M14 8A5 5 0 1 0 4 8a5 5 0 0 0 10 0"/>
                    </svg>
                  </span>
                )}
              </div>
              <div className='col-md-3 students-list-exam-info'>{detail.marks}</div>
              {analysisData.exam_negative_marks > 0 && (
                <div className='col-md-3 students-list-header-info'>{detail.negative_marks}</div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Modal for displaying proctored images */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>AI Captured Events While Proctoring</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : proctoredImages.length > 0 ? (
            <ul className="proctoredImages course-list row row-cols-1 row-cols-md-4 g-4">
              {proctoredImages.map((image, idx) => (
                <li className='page-added-item col'>
                  <div key={idx} className="added-items-list card h-100">
                    <div className='card-body'>
                      <img src={`${process.env.REACT_APP_EXAMS_BE}${image.image_path}`} alt="Proctored" className="img-fluid" />
                      <h5 className='modal-title min-ht-25 mt-3'>{image.reason}</h5>
                      
                      
                    </div>
                    <div className='card-footer'>
                    {new Date(image.date).toLocaleString()}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div>No proctored images available</div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StudentsList;
