import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Context from '../../user_details';
import { playSound } from '../../helpers/otherSounds';

export default function LearningOutcomeForm({ onAddLearningOutcome }) {
  const {user} = useContext(Context);
  const [loName, setLoName] = useState('');
  const [loDescription, setLoDescription] = useState('');
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    async function fetchCourses() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/list/${user.firm_id}`);
        setCourses(response.data.result.courses);
      } catch (error) {
        console.error('Error fetching courses:', error);
        setErrorMessage('Failed to fetch courses. Please try again.');
      }
    }

    fetchCourses();
  }, []);

  useEffect(() => {
    async function fetchSubjects() {
      try {
        if (selectedCourse) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/subjects/list/${selectedCourse}`);
          setSubjects(response.data);
        }
      } catch (error) {
        console.error('Error fetching subjects:', error);
        setErrorMessage('Failed to fetch subjects. Please try again.');
      }
    }

    fetchSubjects();
  }, [selectedCourse]);

  useEffect(() => {
    async function fetchTopics() {
      try {
        if (selectedSubject) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/topics/list/${selectedSubject}`);
          setTopics(response.data.result);
        }
      } catch (error) {
        console.error('Error fetching topics:', error);
        setErrorMessage('Failed to fetch topics. Please try again.');
      }
    }

    fetchTopics();
  }, [selectedSubject]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/learning-outcomes`, {
        lo_name: loName,
        lo_description: loDescription,
        course_id: selectedCourse,
        subject_id: selectedSubject,
        topic_id: selectedTopic
      });
      console.log('New learning outcome added:', response.data);
      setLoName('');
      setLoDescription('');
      setErrorMessage('');
      setMessage('Learning Outcome added successfully!');
      setMessageType('success');
      playSound('SUCCESS');
      setTimeout(()=>{onAddLearningOutcome()}, 1000);
    } catch (error) {
      console.error('Error adding learning outcome:', error);
      setMessage('Failed to add learning outcome. Please try again.');
      setMessageType('error');
      playSound('ERROR');
    }
  };

  return (
    <div className="learning-outcome-form general-add-form-container general-add-forms2">
      <h3>Add Learning Outcome</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Course:</label>
          <select class="form-select" value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)}>
            <option value="">Select Course</option>
            {courses.map(course => (
              <option key={course._id} value={course._id}>{course.course_name}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Subject:</label>
          <select class="form-select" value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)}>
            <option value="">Select Subject</option>
            {subjects.map(subject => (
              <option key={subject._id} value={subject._id}>{subject.subject_name}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Topic:</label>
          <select class="form-select" value={selectedTopic} onChange={(e) => setSelectedTopic(e.target.value)}>
            <option value="">Select Topic</option>
            {topics.map(topic => (
              <option key={topic._id} value={topic._id}>{topic.topic_name}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Learning Outcome Name:</label>
          <input
            type="text"
            value={loName}
            onChange={(e) => setLoName(e.target.value)}
            required
          />
        </div>
        <div class="d-flex align-items-center">
          <label>Description:</label>
          <textarea class="add-forms-textarea"
            value={loDescription}
            onChange={(e) => setLoDescription(e.target.value)}
            required
          ></textarea>
        </div>
        {/* <button type="submit">Add Learning Outcome</button> */}

        <div>
          <button type="submit" class="add-form-btn">
            <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-patch-plus" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5"/>
                <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
              </svg>
            </span>
            Add Now
          </button>
          <button type="submit" class="cancel-form-btn">
            <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
              </svg>
            </span>
            Cancel
          </button>
        </div>
      </form>
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
      {message && (
        <div className={`message ${messageType}`}>
          {message}
        </div>
      )}
    </div>
  );
}
