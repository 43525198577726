import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Context from '../../user_details';
import { playSound } from '../../helpers/otherSounds';

export default function AddCourseForm({ onAddCourse, selectedcourses, cancelBtnHandler }) {
  const { user } = useContext(Context);
  const [courseObj, setCourseObj] = useState({
    courseName: "",
    selectedClient: ""
  });
  const [clients, setClients] = useState([]);
  const [formType, setFormType] = useState('Add');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    if (selectedcourses) {
      setCourseObj({
        ...selectedcourses,
        courseName: selectedcourses.course_name,
        selectedClient: user.firm_id,
      });
      setFormType('Update');
    }
  }, [selectedcourses]);

  const handleClientChange = (e) => {
    setCourseObj((prevState) => ({
      ...prevState,
      selectedClient: user.firm_id,
      selectedcourses: "",
    }));
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/courses`, {
        course_name: courseObj.courseName,
        client_id: user.firm_id,
      });
      setMessage('Course added successfully!');
      setMessageType('success');
      playSound('SUCCESS'); // Play success sound
      onAddCourse();
    } catch (error) {
      setMessage('Error adding course.');
      setMessageType('error');
      playSound('ERROR'); // Play error sound
      console.error('Error adding course:', error);
    }
  };

  const EdithandleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/courses/${courseObj._id}`, {
        course_name: courseObj.courseName,
        client_id: user.firm_id,
      });
      setMessage('Course updated successfully!');
      setMessageType('success');
      playSound('SUCCESS'); // Play success sound
      onAddCourse();
    } catch (error) {
      setMessage('Error updating course.');
      setMessageType('error');
      playSound('ERROR'); // Play error sound
      console.error('Error updating course:', error);
    }
  };

  return (
    <div className="add-course-form general-add-form-container">
      <h3>{formType} Course</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Course name:</label>
          <input 
            type="text" 
            value={courseObj.courseName}
            onChange={(e) => setCourseObj((prevState) => ({
              ...prevState,
              courseName: e.target.value,
            }))}
            required 
          />
        </div>

        {formType === 'Add' ? (
          <button type="submit" className="add-form-btn">
            <span className="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5"/>
                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
              </svg>
            </span>
            {formType} Now
          </button>
        ) : (
          user.permissionTypes.includes("editCourses") && (
            <button onClick={EdithandleSubmit} className="add-form-btn">
              {formType} Now
            </button>
          )
        )}

        <button type="button" className="cancel-form-btn" onClick={cancelBtnHandler}>
          <span className="icon-placeholder">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
            </svg>
          </span>
          Cancel
        </button>
      </form>

      {message && (
        <div className={`message ${messageType}`}>
          {message}
        </div>
      )}
    </div>
  );
}
